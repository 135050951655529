import { Button, Col, Flex, Row, Typography, Skeleton } from "antd";

//Components
import { Container } from "@/components/Layout";
import Chat from "@/pages/chat/Chat";
import AsideContainer from "@/components/Layout/AsideContainer";
import QueryHistory from "../../components/QueryHistory";
import QuerySource from "../../components/QuerySource";
// import { useGetAllAnzDocumentsQuery } from "@/store/apis/anzApi";
// import { useGetDocumentsByEntityQuery } from '@/store/apis/anzApi';
import { fetchDocumentsByEntity } from "../../../../api";

import DocumentList from "../../components/DocumentList";
import { FC, useState, useEffect } from "react";

const { Title } = Typography;

// CBA.tsx
// Important things to change for new bank
// 0. Remember to add to menu.ts and lender/routes/index.tsx
// 1. Change name of function
// 2. Change name of bankEntity
// 3. Change the export function name in the last line

const BOS = () => {
    let bankEntity = "BOS";
    // const { data: anzDocuments, isLoading: anzDocumentsLoading, isSuccess: anzDocumentsSuccess, isError: anzDocumentsError } = useGetAllAnzDocumentsQuery();
    const [documents, setDocuments] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [selectAll, setSelectAll] = useState(true);

    useEffect(() => {
        fetchDocumentsByEntity(bankEntity)
            .then(response => {
                if (response.success) {
                    setDocuments(response.data);
                    setIsSuccess(true);
                } else {
                    throw new Error("Failed to fetch documents");
                }
            })
            .catch(error => {
                console.error(error);
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const [selectedDocs, setSelectedDocs] = useState<string[]>([]);
    const [viewDocUrl, setViewDocUrl] = useState<string | null>(null);

    const handleDocumentSelect = (selectedDocuments: string[]) => {
        console.log("INDEX", selectedDocs);
        setSelectedDocs(selectedDocuments);
    };
    const handleDocumentView = (url: string) => {
        console.log("VIEW", url);
        setViewDocUrl(url);
    };
    const handleSelectAll = (selectedAll: boolean) => {
        console.log("SELECTALL", selectedAll);
        setSelectAll(selectedAll);
    };
    return (
        // <AsideContainer sideContent={<QueryHistory />}>
        <Container fullWidth={true} align="left" style={{ padding: "0 2rem" }}>
            <Row gutter={32}>
                <Col xs={24} md={24}>
                    <Row gutter={32}>
                        <Col xs={24} md={4}>
                            <Title level={5} style={{ marginTop: 0 }}>
                                Documents
                            </Title>
                            {documents && isSuccess ? (
                                <DocumentList
                                    documents={documents}
                                    onDocumentSelect={handleDocumentSelect}
                                    viewDocument={handleDocumentView}
                                    selectedAll={handleSelectAll}
                                />
                            ) : (
                                <Skeleton />
                            )}
                        </Col>
                        <Col xs={24} md={20}>
                            <Row>
                                <Col xs={24} md={24}>
                                    <Flex gap={24} justify="space-between" style={{ marginBottom: "2rem" }}>
                                        {/* <Title level={4} style={{ margin: 0 }}>
                                                Get precise answers by asking specific questions found within the documents!
                                            </Title>
                                            <Button type="primary">Attach to Opportunity</Button> */}
                                        <p style={{ margin: 0, fontWeight: "bold" }}>
                                            Get precise answers by asking specific questions found within the documents!
                                        </p>
                                    </Flex>
                                    <Chat filterEntity={bankEntity} filterData={selectedDocs} docUrl={viewDocUrl} searchAll={selectAll} />
                                </Col>
                            </Row>
                            {/* <Chat /> */}
                        </Col>
                    </Row>
                </Col>
                {/* <Col xs={24} md={10}>
                        <QuerySource />
                    </Col> */}
            </Row>
        </Container>
        // </AsideContainer>
    );
};

export default BOS;
