import { FC, RefObject, useRef } from "react";
// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SelectField from "@/components/Form/SelectField";
import { RadioGroupField } from "@/components/Form";
import { Button } from "antd";


const preferredLoanFormSchema = yup.object().shape({
    rateType: yup.string().required("Rate Type is required"),
    repaymentType: yup.string().required("Repayment Type is required"),
    repaymentFrequency: yup.string().required("Repayment Frequency is required"),
    offset: yup.string().required("Offset is required")
});
export type PreferredLoanFormType = yup.InferType<typeof preferredLoanFormSchema>;

interface PreferredLoanFormProps {
    handleFormSubmit: (data: PreferredLoanFormType) => void;
    submitRef: RefObject<HTMLButtonElement>;
}
const PreferredLoanForm:FC<PreferredLoanFormProps> = ({handleFormSubmit, submitRef}) => {
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(preferredLoanFormSchema),
        defaultValues: {
            rateType:"",
            repaymentType:"",
            repaymentFrequency: "",
            offset: ""
        }
    });

    const onSubmit: SubmitHandler<PreferredLoanFormType> = data => {
        handleFormSubmit(data);
    };

    

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SelectField
                        name="rateType"
                        control={control}
                        label="Priority 1 (Rate Type)"
                        placeholder="Rate Type"
                        error={errors.rateType?.message}
                        formDirection="row"
                        options={[
                            { label: "Fixed Rate", value: "Fixed Rate" },
                            { label: "Variable Rate", value: "Variable Rate" },
                            { label: "Fixed & Variable Rate", value: "Fixed & Variable Rate" },
                        ]}
                    />
                <SelectField
                        name="repaymentType"
                        control={control}
                        label="Priority 2 (Repayment Type)"
                        placeholder="Repayment Type"
                        error={errors.repaymentType?.message}
                        formDirection="row"
                        options={[
                            { label: "Interest Only", value: "Interest Only" },
                            { label: "P&l Repayments", value: "P&l Repayments" }
                        ]}
                    />  
                    <SelectField
                        name="repaymentFrequency"
                        control={control}
                        label="Priority 3 (Repayment Frequency)"
                        placeholder="Repayment Frequency"
                        error={errors.repaymentFrequency?.message}
                        formDirection="row"
                        options={[
                            { label: "Monthly", value: "Monthly" },
                            { label: "Fortnightly", value: "Fortnightly" },
                            { label: "Weekly", value: "Weekly" }
                        ]}
                    />  
                    <RadioGroupField
                        name="offset"
                        control={control}
                        label="Offset"
                        error={errors.offset?.message}
                        formDirection="row"
                        options={[
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" }
                        ]}
                    />
                    
                    <Button type="primary" style={{ display: "none" }} htmlType="submit" ref={submitRef}>
                        Form Submit
                    </Button>
            </form>

            
        </div>
    );
};

export default PreferredLoanForm;
