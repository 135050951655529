import { InputField, RadioGroupField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import { Col, Row } from "antd";
import { FC } from "react";
import SelectField from "@/components/Form/SelectField";
import { LENDER_OPTIONS } from "../../../constant";
import { loanPurpose } from "@/devFrontData/formOptions";

const FamilyDetailsForm: FC<FormPropsType> = ({ control, errors }) => {
    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <SelectField
                    name="pricing"
                    control={control}
                    label="Pricing"
                    placeholder="Pricing"
                    error={errors.pricing?.message}
                    options={[
                        { label: "Yes, applicable", value: "Yes, applicable" },
                        { label: "Not yet requested", value: "Not yet requested" },
                        { label: "N/A", value: "N/A" }
                    ]}
                />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Interest rate" name="interestRate" control={control} error={errors.interestRate?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="valuation"
                    control={control}
                    label="Valuation"
                    placeholder="valuation"
                    error={errors.valuation?.message}
                    options={[
                        { label: "N/A, AIP", value: "N/A, AIP" },
                        { label: "Completed", value: "Completed" },
                        { label: "Ordered", value: "Ordered" },
                        { label: "To Be Ordered", value: "To Be Ordered" }
                    ]}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Living Status" name="livingStatus" control={control} error={errors.livingStatus?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Mortgage" name="mortgage" control={control} error={errors.rentalExpense?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Rental Expense" name="rentalExpense" control={control} error={errors.mortgage?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <SelectField
                    name="maritalStatus"
                    control={control}
                    label="Marital Status"
                    placeholder="Currency"
                    error={errors.maritalStatus?.message}
                    options={[
                        { label: "Married", value: "married" },
                        { label: "Single", value: "single" }
                    ]}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Kids" name="kids" control={control} error={errors.kids?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Kids Age" name="kidsAge" control={control} error={errors.kidsAge?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <RadioGroupField
                    name="privateEducation"
                    control={control}
                    label="Private Education"
                    error={errors.privateEducation?.message}
                    options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" }
                    ]}
                />
            </Col>
            <Col xs={24} md={12}>
                <SelectField name="purpose" control={control} label="Purpose" placeholder="Currency" error={errors.purpose?.message} options={loanPurpose} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Purpose Equity" name="purposeEquity" control={control} error={errors.purposeEquity?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="LVR" name="lvr" suffix="%" control={control} error={errors.lvr?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Security" name="security" suffix="%" control={control} error={errors.security?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="AIP Rental Yiel" name="aipRentalyiel" suffix="%" control={control} error={errors.aipRentalyiel?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="AIP Rental" name="apiRent" prefix="$" control={control} error={errors.apiRent?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Reduce Limit" name="reduceLimit" control={control} error={errors.reduceLimit?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <SelectField name="lender" control={control} label="Lender" placeholder="lender" error={errors.lender?.message} options={LENDER_OPTIONS} />
            </Col>
            <Col xs={24} md={12}>
                <RadioGroupField
                    name="existingCustomer"
                    control={control}
                    label="Existing Customer"
                    error={errors.existingCustomer?.message}
                    options={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" }
                    ]}
                />
            </Col>
        </Row>
    );
};

export default FamilyDetailsForm;
