import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton, Modal, Button } from "antd";
//AgGrid
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

//Store
import { useDispatch } from "react-redux";
import { useDeleteOpportunityServiceMutation, useGetOpportunityServiceHistoryQuery } from "@/store/apis/opportunityApi";
import { setLoading } from "@/store/slices/common/commonSlice";

import { formWebsiteMapper } from "@/devFrontData/formWebsiteMapper";
import ActionButtonsRenderer from "../loanRequest/components/ActionButtonsRenderer";
import { displayError, displaySuccess } from "@/utils/common.utils";

interface OpportunityListProps {
    haldeSelectedDeal: (selectedId: number) => void;
}
const OpportunityList: FC<OpportunityListProps> = ({ haldeSelectedDeal }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const gridRef = useRef<any>(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const handleAction = (data: any) => {
      
        haldeSelectedDeal(data.id);
    };

    const [deleteOpportunity, {isLoading: deleteOpportunityLoading, isSuccess: deleteOppotunitySuccess}] =useDeleteOpportunityServiceMutation();
    const {
        data: opportunityList,
        isLoading,
        isSuccess,
        isError,
        refetch
    } = useGetOpportunityServiceHistoryQuery(undefined, {
        refetchOnMountOrArgChange: true
    });

    const handleConfirmDeleteDeal = (data:any) => {
        dispatch(setLoading(deleteOpportunityLoading))
        deleteOpportunity(data.id)
        .then((response:any) => {
            displaySuccess(response.message)
            refetch()
        })
        .catch(err => {
            displayError(err)
        }).finally(() => {
            dispatch(setLoading(deleteOpportunityLoading))
        })
    };

    const handleDeleteDeal = (data: any) => {
        console.log(data);
        Modal.confirm({
            title: `Are you sure you want to delete "${data.name}"?`,
            onOk: () => handleConfirmDeleteDeal(data),
            okType: "danger",
            okText: "Yes Confirm",
           
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn  />
              </>
            ),
          });

        
    };
    const [colDefs, setColDefs] = useState<ColDef[]>(() => {

        const columns: ColDef[] =  [
            {
                headerName: "Deal Name",
                field: "name",
                headerTooltip: "Deal Name",
                filter: true,
                editable: true
            },
             {
                headerName: "Deal Type",
                field: "type",
                headerTooltip: "Deal Type",
                filter: true,
                editable: true
            },
            {
                headerName: "Contact",
                field: "primary_contact.email",
                headerTooltip: "Primary Contact",
                filter: true,
                editable: true
            },
            {
                headerName: "Secondary Contact",
                field: "secondary_contact.email",
                headerTooltip: "Secondary Contact",
                filter: true,
                editable: true
            },
            {
                headerName: "Actions",
                cellRenderer: ActionButtonsRenderer,
                cellRendererParams: {
                    buttons:['select', 'delete'],
                    tooltipTitle: "Open Deal",
                    onAction: handleAction, // Passing the callback function to the renderer
                    handleDelete: handleDeleteDeal
                },
                width: 150,
                suppressMenu: true,
                pinned: "right"
            }
        ];
    
        return columns;
    });

    

    useEffect(() => {
        if(isError){
            displayError("Error fetching deals");
        }
    },[isError])

    return (
        <div>
            {isLoading ? (
                <Skeleton />
            ) : (
                <div className="ag-theme-quartz" style={{ height: "calc(100vh - 280px)" }}>
                    <AgGridReact tooltipInteraction={true} ref={gridRef} rowData={opportunityList} columnDefs={colDefs} sideBar={"columns"} />
                </div>
            )}
            
        </div>
    );
};

export default OpportunityList;
