import { FC } from "react";
import { Col, Row } from "antd";
import { InputField, SelectField } from "@/components/Form";
import { FormPropsType } from "../../types";

const CoApplicantForm: FC<FormPropsType> = ({ control, errors }) => {
    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <InputField label="Full name" name="fullName_2" control={control} error={errors.fullName_2?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Phone" name="phone_2" control={control} error={errors.phone_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Citizenship" name="citizenship_2" control={control} error={errors.citizenship_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Residency" name="residency_2" control={control} error={errors.residency_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Occupation" name="occupation_2" control={control} error={errors.occupation_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Employment" name="employment_2" control={control} error={errors.employment_2?.message} />
            </Col>
            {/* <Col xs={24} md={12}>
                <InputField
                    label="Employer"
                    name="coApplicantData.coApplicantEmployer"
                    control={control}
                    error={errors.coApplicantData?.coApplicantEmployer?.message}
                />
            </Col> */}
            <Col xs={24} md={12}>
                <InputField label="Start Date" name="since_2" control={control} error={errors.since_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="currencySalary_2"
                    control={control}
                    label="Currency"
                    placeholder="Currency"
                    error={errors.currencySalary_2?.message}
                    options={[
                        { label: "AUD", value: "aud" },
                        { label: "USD", value: "usd" }
                    ]}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField variant="filled" name="fx_2" control={control} label="FX" error={errors.fx_2?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="calMonthSalary_2" control={control} label="Salary" error={errors.calMonthSalary_2?.message} />
            </Col>
            {/* <Col xs={24} md={12}>
                <InputField
                    prefix="$"
                    name="coApplicantData.coApplicantDeduction"
                    control={control}
                    label="Deduction"
                    error={errors.coApplicantData?.coApplicantDeduction?.message}
                />
            </Col> */}
            {/* <Col xs={24} md={12}>
                <InputField
                    prefix="$"
                    name="coApplicantData.coApplicantAllowances"
                    control={control}
                    label="Allowances"
                    error={errors.coApplicantData?.coApplicantAllowances?.message}
                />
            </Col> */}
        </Row>
    );
};

export default CoApplicantForm;
