import { ReactNode, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Flex, Typography, Button } from "antd";
import { Scrollbars } from "rc-scrollbars";

// Redux state
import { RootState } from "@/store";
import { useFetchPromptDataMutation } from "@/store/apis/chatGptApi";
import { useGetBrokerServiceHistoryAllQuery } from "@/store/apis/brokerNoteApi";
import { setSystemPrompt } from "@/store/slices/brokerNote/systemPromptSlice";

// Components
import BrokerPDF from "@/pages/opportunity/brokerNotes/components/BrokerPDF";
import { Container } from "@/components/Layout";
import BrokerNoteForm from "@/pages/opportunity/brokerNotes/components/BrokerNoteForm";
import AsideContainer from "@/components/Layout/AsideContainer";
import OpportunitySideContent from "@/pages/opportunity/components/OpportunitySideContent";

import { ContentNameType } from "@/pages/opportunity/brokerNotes/types";
import HistorySidebar from "../../components/HistorySidebar";

const { Title } = Typography;

const HeaderRow = ({ children }: { children: ReactNode }) => {
    return (
        <Flex style={{ marginBottom: "2rem" }} gap={10} justify="space-between">
            {children}
        </Flex>
    );
};
const BrokerNotes = () => {
    const dispatch = useDispatch();
    const formData = useSelector((state: RootState) => state.brokerNoteForm);
    const reportTemplateRef = useRef<HTMLDivElement>(null);
    const [openPdfDrawer, setOpenPdfDrawer] = useState(false);
    const [activeContent, setActiveContent] = useState<ContentNameType | null>(null);

    const submitRef = useRef<HTMLButtonElement>(null);
    const handleFormSubmit = () => {};

    const [loanPurposeData, setLoanPurposeData] = useState("");
    const [applicationOverviewData, setApplicationOverviewData] = useState("");
    const [livingCDData, setLivingCDData] = useState("");
    const [empIncomeData, setEmpIncomeData] = useState("");
    const [commitData, setCommitData] = useState("");
    const [otherData, setOtherData] = useState("");
    const [mitiData, setMitiData] = useState("");

    const [fetchLoanPurpose, { isLoading: loanPurposeLoading, isError: loanPurposeError }] = useFetchPromptDataMutation();
    const [fetchApplicationOverviewData, { isLoading: applicationOverviewDataLoading, isError: applicationOverviewDataError }] = useFetchPromptDataMutation();
    const [fetchLivingCDData, { isLoading: livingCDDataLoading, isError: livingCDDataError }] = useFetchPromptDataMutation();
    const [fetchEmploymentData, { isLoading: empIncomeDataLoading, isError: empIncomeDataError }] = useFetchPromptDataMutation();
    const [fetchCommitData, { isLoading: commitDataLoading, isError: commitDataError }] = useFetchPromptDataMutation();
    const [fetchOtherData, { isLoading: otherDataLoading, isError: otherDataError }] = useFetchPromptDataMutation();
    const [fetchMitiData, { isLoading: mitiDataLoading, isError: mitiDataError }] = useFetchPromptDataMutation();

    const handleEditOutputPrompt = (id: ContentNameType) => {
        setActiveContent(id);
        setOpenPdfDrawer(true);
    };

    return (
        <AsideContainer sideContent={<HistorySidebar />}>
            <Container fullWidth={true} align="left" style={{ padding: "0 2rem" }}>
                <Row>
                    <Col xs={24} md={24}>
                        <Flex gap={24} justify="flex-end" style={{ marginBottom: "2rem" }}>
                            <Button type="primary">Save</Button>
                            <Button type="primary">Publish to HubSpot</Button>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={32}>
                    <Col xs={24} md={12}>
                        <HeaderRow>
                            <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
                                Opportunity Inputs
                            </Title>
                            <Flex gap={10}>
                                <Button type="primary">New Opportunity</Button>
                                <Button type="primary"> Open Opportunity</Button>
                            </Flex>
                        </HeaderRow>
                        <BrokerNoteForm  handleFormSubmit={handleFormSubmit} submitRef={submitRef} />
                    </Col>
                    <Col xs={24} md={12}>
                        <HeaderRow>
                            <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
                                Broker Notes
                            </Title>
                            <Flex gap={10}>
                                <Button type="primary">Print</Button>
                            </Flex>
                        </HeaderRow>
                        <Scrollbars style={{ height: "calc(100vh - 240px)" }}>
                            <div style={{ paddingLeft: "30px", paddingRight: "20px" }} ref={reportTemplateRef}>
                                <BrokerPDF
                                    allValues={formData}
                                    loanPurposeData={loanPurposeData ?? ""}
                                    loanPurposeDataLoading={loanPurposeLoading}
                                    applicationOverviewData={applicationOverviewData ?? ""}
                                    applicationOverviewDataLoading={applicationOverviewDataLoading}
                                    livingCDData={livingCDData ?? ""}
                                    livingCDDataLoading={livingCDDataLoading}
                                    empIncomeData={empIncomeData ?? ""}
                                    empIncomeDataLoading={empIncomeDataLoading}
                                    commitData={commitData ?? ""}
                                    commitDataLoading={commitDataLoading}
                                    otherData={otherData ?? ""}
                                    otherDataLoading={otherDataLoading}
                                    mitiData={""}
                                    mitiDataLoading={false}
                                    handleEditMode={handleEditOutputPrompt}
                                />
                            </div>
                        </Scrollbars>
                    </Col>
                </Row>
            </Container>
        </AsideContainer>
    );
};

export default BrokerNotes;
