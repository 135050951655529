import { FC, ReactNode } from "react";
import styles from "./AsideContainer.module.scss";

interface AsideContainerProps {
    children?: ReactNode;
    sideContent: ReactNode;
}
const AsideContainer: FC<AsideContainerProps> = ({ children, sideContent }) => {
    return (
        <div className={styles.asideContainer}>
            {children}
            <div className={styles.rightSidebar}>{sideContent}</div>
        </div>
    );
};

export default AsideContainer;
