export const RANGE_DATA = {
    lowerRange: {
        0: "",
        1: "350000",
        2: "500000",
        3: "700000",
        4: "1000000",
        5: "1500000",
        6: "2000000",
        7: "3000000"
    },
    upperRange: {
        0: "350000",
        1: "499999",
        2: "699999",
        3: "999999",
        4: "1499999",
        5: "1999999",
        6: "2999999",
        7: ""
    },
    rentalYield: {
        0: "6",
        1: "5.5",
        2: "5",
        3: "4.5",
        4: "4",
        5: "3.5",
        6: "3",
        7: "2.5"
    }
};
