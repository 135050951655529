import { Card, Typography } from "antd";
import styles from "./Example.module.scss";

const { Text } = Typography;

interface Props {
    text: string;
    value: string;
    onClick: (value: string) => void;
}

export const Example = ({ text, value, onClick }: Props) => {
    return (
        <Card hoverable styles={{ body: { padding: "12px" } }} onClick={() => onClick(value)} style={{ height: "100%", cursor: "pointer" }}>
            {/* <p className={styles.exampleText}></p> */}
            <Text style={{ fontSize: "12px" }}>{text}</Text>
        </Card>
    );
};
