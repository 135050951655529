import { FC, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";
import axios from "axios";

import { getCountryCode, getCountryData } from "countries-list";

// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// AntD
import { Row, Col, Collapse, Button } from "antd";
import { BrokerContactType, CommonFormDataTypes, ContactType, DealInfoType } from "../../types";
import { commonFormSchema } from "../../utils";
import { complianceFormSchema } from "@/pages/noteGeneration/validations";
// components
import CheckboxField from "@/components/Form/CheckBoxField";

import OpportunityInfoForm from "./forms/OpportunityInfoForm";
import BasicDetailForm from "./forms/BasicDetailForm";
import OccupationForm from "./forms/OccupationForm";
import CoApplicantForm from "./forms/CoApplicantForm";
import FamilyDetailsForm from "./forms/FamilyDetailsForm";
import ProcessorForm from "./forms/ProcessorForm";
import LoanDetailForm from "./forms/LoanDetailForm";
import CardDetailForm from "./forms/CardDetailForm";

// utils
import { convertToNumber } from "@/utils/common.utils";
import { calculateMonthlyAmount } from "../../utils";
import { RANGE_DATA } from "../../utils";

// redux
import { FormActionType } from "@/types";
import CoApplicantOccupation from "./forms/CoApplicantOccupation";
import { useGetAllDealContactsQuery } from "@/store/apis/opportunityApi";
import { WebsiteDataMapperType } from "@/devFrontData/websiteDataMapper";
import { SalaryFrequency } from "../../brokerNotes/types";

export type FormFieldDataType = WebsiteDataMapperType & CommonFormDataTypes;
interface SharedFormProps {
    handleFormSubmit: ({formData}:{formData:CommonFormDataTypes}) => void;
    submitRef: React.RefObject<HTMLButtonElement>;
    formFieldsData?: FormFieldDataType | null;
    initialValues: CommonFormDataTypes ;
    setFormData?: (data: CommonFormDataTypes) => void;
    dealData?: DealInfoType | null;
    contactData?: ContactType | null; 
    secondaryContactData?: ContactType | null;
    brokerContact?: BrokerContactType | null;
    formActionType?: FormActionType | null;
    getAllValueFromForm?: (data: CommonFormDataTypes) => void;
    componentName?: 'Deal' | 'Compliance'
}
export interface SharedFormMethods {
    resetForm: () => void;
    getAllUpdatedValues: () => void;
    triggerValidation: () => Promise<boolean>;
}

const SharedForm = forwardRef<SharedFormMethods,SharedFormProps >((
    {
        handleFormSubmit,
        formFieldsData,
        initialValues,
        setFormData,
        dealData,
        brokerContact,
        formActionType = "create",
        submitRef,
        contactData,
        secondaryContactData,
        getAllValueFromForm,
        componentName = 'Deal'
    }
    , ref) =>  {
    
    const { data: contacts, isLoading: contactsLoading, isError: contactsError, isSuccess: contactsSuccess, refetch } = useGetAllDealContactsQuery();
    const selectedSchema = componentName === 'Deal'? commonFormSchema: complianceFormSchema;

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        trigger,
        formState: { errors },
        reset,
        setFocus
    } = useForm({
        resolver: yupResolver(commonFormSchema),
        defaultValues: initialValues
    });
    useImperativeHandle(ref, () => ({
        resetForm(){
            reset();
        },
        getAllUpdatedValues: () => {
            getAllUpdatedValues();
        },
        triggerValidation: async () => {
            const result = await trigger();
            return result;
        }
    }));
    /**
     * When error is encountered it scrolls to the error field
     */
    useEffect(() => {
        const firstErrorField = Object.keys(errors)[0];
        if (firstErrorField) {
            setFocus(firstErrorField as keyof CommonFormDataTypes);
        }
    }, [Object.keys(errors).length]);

    
    useEffect(() => {
        if(dealData){
            setValue("dealName", dealData?.dealName);
            setValue("dealType", dealData?.dealType);
        }   
    },[dealData]);

    useEffect(() => {
        if(brokerContact){
            setValue("brokerPrimaryContact", brokerContact?.primaryContact)
            setValue("brokerSecondaryContact", brokerContact?.secondaryContact)
        }
    },[brokerContact]);

    useEffect(() => {
        if(contactData) {
            setValue("contactFullName", contactData?.name ?? "") ;
            setValue("contactEmail", contactData?.email);
            setValue("contactPhone", contactData?.phone ?? "");
            setValue("contactCitizenship", contactData?.citizenship)
            setValue("contactResidency", getCountryCode(contactData?.residency ?? "") as string)
        }
    },[contactData]);
    
    useEffect(() => {
        if(secondaryContactData) {
            setValue("secondaryContactFullName", secondaryContactData?.name ?? "") ;
            setValue("secondaryContactEmail", secondaryContactData?.email);
            setValue("secondaryContactPhone", secondaryContactData?.phone ?? "");
            setValue("secondaryContactCitizenship", secondaryContactData?.citizenship)
            setValue("secondaryContactResidency", getCountryCode(secondaryContactData?.residency ?? "") as string)
            setValue("coApplicationStatus", true);
        }

    },[secondaryContactData]);

    /**
     * Get data from props and set values to the field
     */
    useEffect(() => {
        if (formFieldsData) {
            const userData = formFieldsData;
            console.log('userData', userData)
            setValue("websiteTrackingId", userData.websiteTrackingId);
            setValue("formId", userData.formId);
            

            setValue("propertyValue", userData?.propertyValue);
            setValue("loanAmount", userData?.loanAmount);
            setValue("loanTerm", userData?.loanTerm);
            setValue("product", userData?.product);

            setValue("purpose", userData?.purpose)     
            setValue("allowances", userData?.allowance)       
            setValue("allowanceFreq", userData?.allowanceFreq)
            setValue("employer", userData?.employer ?? "")

            if (userData?.allowanceFreq && userData?.allowanceFreq !== '' && userData?.allowances && userData?.allowances !== '') {
                setValue("calcAllowance", calculateMonthlyAmount(userData["allowanceFreq"] as SalaryFrequency, userData["allowances"] ?? "0"));
            } else {
                setValue('calcAllowance', '0')
            }

            setValue("commission", userData?.commission);
            setValue("commFreq", userData?.commFreq);

            if(userData?.commFreq && userData?.commFreq !=="" && userData?.commission && userData?.commission !== "") {
                setValue("calcCommission", calculateMonthlyAmount(userData["commFreq"] as SalaryFrequency, userData["commission"] ?? "0"));
            } else {
                setValue('calcCommission', '0')
            }

            
            if(secondaryContactData){

                setValue("coApplicationStatus", true);
            }
            if (secondaryContactData) {
                setValue("occupation_2", userData["occupation_2"]);
                setValue("employment_2", userData["employment_2"]);
                setValue("allowances_2", userData?.allowance_2);
                setValue("allowanceFreq_2", userData?.allowanceFreq_2);

                if (userData?.allowanceFreq_2 && userData?.allowanceFreq_2 !== '' && userData?.allowances_2 && userData?.allowances_2 !== '') {
                    setValue("calcAllowance2", calculateMonthlyAmount(userData["allowanceFreq_2"] as SalaryFrequency, userData["allowances_2"] ?? "0"));
                } else {
                    setValue('calcAllowance2', '0')
                }
                

                setValue("commission_2", userData?.commission_2);
                setValue("commFreq_2", userData?.commFreq_2);

                if(userData?.commFreq_2 && userData?.commFreq_2 !=="" && userData?.commission_2 && userData?.commission_2 !== "") {
                    setValue("calcCommission2", calculateMonthlyAmount(userData["commFreq_2"] as SalaryFrequency, userData["commission_2"] ?? "0"));
                } else {
                    setValue('calcCommission2', '0')
                }


                if(userData?.since_2 && userData?.since_2 !== "") {
                    setValue("since_2", `${userData["jobStartMonth_2"]} ${userData["jobStartYear_2"]}`);
                } else {
                    setValue('since_2', userData?.since_2)
                }
                
                setValue("currencySalary_2", userData["currencySalary_2"]);

                getForexData("fx_2", dayjs(userData["date"]).format("YYYY-MM-DD"), userData["currencySalary_2"] ?? "");

                setValue("salaryFreq_2", userData["salaryFreq_2"] ?? "");
                setValue("salary_2", userData["salary_2"] ?? "0");
                if ("salaryFreq_2" in userData && "salary_2" in userData) {
                    setValue("calMonthSalary_2", calculateMonthlyAmount(userData["salaryFreq_2"] as SalaryFrequency, userData["salary_2"] ?? ""));
                }

                setValue("bonusThis_2", userData["bonusThis_2"]);
                setValue("bonusLast_2", userData["bonusLast_2"] === "" ? "0" : userData["bonusLast_2"]);

                // setValue("coApplicantData.coApplicantSalary", userData[468] === "Annually" ? Number(userData[467].replace(/,/g, "")) / 12 : userData[467]);
                setValue("ccLimit_2", userData["ccLimit_2"]);
            }
           
           

            if (userData["date"]) {
                // @ts-ignore
                setValue("date", dayjs(userData["date"]));
            }

            setValue("ccLimit1", userData["ccLimit1"]);

            getForexData("fx", dayjs(userData["date"]).format("YYYY-MM-DD"), userData["currency"] ?? "");

            setValue("citizenship", userData["citizenship"]);
            setValue("employment", userData["employment"]);
            setValue("occupation", userData["occupation"]);
            if(userData?.since && userData?.since !== ""){
                setValue("since", `${userData["jobStartMonth"] ?? ""} ${userData["jobStartYear"] ?? ""}`);
            } else {
                setValue("since", userData?.since);
            }
            
            setValue("currency", userData["currency"]);

            setValue("salary", userData["salary"] ?? "0");

            setValue("salaryFreq", userData["salaryFreq"] ?? "");
            if ("salaryFreq" in userData && "salary" in userData) {
                setValue("calMonthSalary", calculateMonthlyAmount(userData["salaryFreq"] as SalaryFrequency, userData["salary"] ?? ""));
            }
            

            setValue("bonusThis", userData["bonusThis"]);
            setValue("bonusLast", userData["bonusLast"] === "" ? "0" : userData["bonusLast"]);
            
            let anyLiabilitiesValue = "";

            /**
             * TODO: Full Logic not completed
             */
            if (userData.osProperties && userData.anyOtherLoan && userData?.anyOtherLoan_2) {
                anyLiabilitiesValue =
                    userData?.osProperties.toLowerCase() === "no" &&
                    userData?.anyOtherLoan.toLowerCase() === "no" &&
                    userData?.anyOtherLoan_2.toLowerCase() === "no"
                        ? "no"
                        : "yes";
            }

            setValue("anyLiabilities", anyLiabilitiesValue);

            setValue("livingStatus", userData["livingStatus"]);
            setValue("maritalStatus", userData["maritalStatus"]);
            setValue("privateEducation", userData["privateEducation"]);

            setValue("kids", userData["kids"]);
            if(userData.kidsAge){
                setValue("kidsAge", userData["kidsAge"])
            } else {
                setValue(
                    "kidsAge",
                    `${userData["kidsAge_1"] ?? ''}, ${userData["kidsAge_2"] ?? ''}, ${userData["kidsAge_3"] ?? ''}, ${userData["kidsAge_4"] ?? ''}, ${userData["kidsAge_5"] ?? ''}`
                );
            }
           


            let aipRentalyielCalc = "";
            if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[7])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[7];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[6])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[6];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[5])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[5];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[4])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[4];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[3])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[3];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[2])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[2];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[1])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[1];
            } else {
                aipRentalyielCalc = RANGE_DATA.rentalYield[0]; // Default if none of the conditions are met
            }
            setValue("aipRentalyiel", Number(aipRentalyielCalc));
            let weeklyRent = (Number(aipRentalyielCalc) / 100 / 52) * Number(userData["maxPurchasePrice"]);
            let apiRentCalc = (weeklyRent * 52) / 12;
            if (isNaN(apiRentCalc)) {
                apiRentCalc = 0;
            }
            
            setValue("apiRent", apiRentCalc);

            // setValue("coApplicationStatus", userData["coApplicationStatus"].toLowerCase() === "yes" ? true : false);
            
            

            // Get the updated values from the form and deep copy cause getValue give readonly values
            const updatedValues = JSON.parse(JSON.stringify(getValues()));

            // Dispatch the updated form values to the Redux store
            if(setFormData){
                setFormData(updatedValues);
            }
            
        } 
    }, [formFieldsData]);

    
    /**
     * TODO: update the backed url and fix date validation
     */
    const getForexData = (fiedName: any, requestDate: string, requestCurrency: string, type = "raw") => {
        axios
            .get(`https://odin-forex.vercel.app/api/convert?from=AUD&to=${requestCurrency}&date=${requestDate}&amount=1`)
            .then(res => {
                console.log("currency", res.data);
                if (type === "raw") {
                    setValue(fiedName, `AUD/${requestCurrency} = ${res.data.result}`);
                } else {
                    setValue(fiedName, res.data.result);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const watchContactEmail = watch("contactEmail");
    const watchSecondaryContactEmail = watch("secondaryContactEmail");

    const hasCoApplicant = watch("coApplicationStatus");

    const watchSalary = watch("salary");
    const watchSalaryFreq = watch("salaryFreq");
    const watchSalary2 = watch("salary_2");
    const watchSalaryFreq2 = watch("salaryFreq_2");

    const watchAllowance = watch("allowances");
    const watchAllowanceFreq = watch("allowanceFreq");
    const watchAllowance2 = watch("allowances_2");
    const watchAllowanceFreq2 = watch("allowanceFreq_2");

    const watchCommission = watch("commission");
    const watchCommissionFreq = watch("commFreq");
    const watchCommission2 = watch("commission_2");
    const watchCommissionFreq2 = watch("commFreq_2");

    const ccLimit1 = watch("ccLimit1");
    const cardFXConversion1 = watch("cardFXConversion1");
    const cardFXConversion2 = watch("cardFXConversion2");

    const ccLimit2 = watch("ccLimit2");

    const curentCCLimit1 = watch("curentCCLimit1");
    const curentCCLimit2 = watch("curentCCLimit2");

    const cardCurrency1 = watch("cardCurrency1");

    const cardCurrency2 = watch("cardCurrency2");

    useEffect(() => {
        if(watchContactEmail){
            const selectedContactEmail = getValues('contactEmail');
            
            const selectedContactData = contacts?.find(contact => contact.email === selectedContactEmail)
            setValue('contactFullName', selectedContactData?.name);
            setValue('contactPhone', selectedContactData?.phone);
            setValue('contactCitizenship', selectedContactData?.citizenship)
            setValue('contactResidency', getCountryCode(selectedContactData?.residency ?? '') as string)
        }
        
    },[watchContactEmail]);

    useEffect(() => {
        if(watchSalary && watchSalaryFreq) {
           const baseSalary =  calculateMonthlyAmount(watchSalaryFreq as SalaryFrequency, watchSalary);
           setValue('calMonthSalary', baseSalary)
        }
    },[watchSalary, watchSalaryFreq]);

    useEffect(() => {
        if(watchSalary2 && watchSalaryFreq2) {
           const baseSalary =  calculateMonthlyAmount(watchSalaryFreq2 as SalaryFrequency, watchSalary2);
           setValue('calMonthSalary_2', baseSalary)
        }
    },[watchSalary2, watchSalaryFreq2]);

    useEffect(() => {
        if(watchAllowance && watchAllowanceFreq) {
            const baseAllowance =  calculateMonthlyAmount(watchAllowanceFreq as SalaryFrequency, watchAllowance);
            setValue('calcAllowance', baseAllowance)
         }
    },[watchAllowance, watchAllowanceFreq]);

    useEffect(() => {
        if(watchAllowance2 && watchAllowanceFreq2) {
            const baseAllowance =  calculateMonthlyAmount(watchAllowanceFreq2 as SalaryFrequency, watchAllowance2);
            setValue('calcAllowance2', baseAllowance)
         }
    },[watchAllowance2, watchAllowanceFreq2]);

    useEffect(() => {
        if(watchCommission && watchCommissionFreq) {
            const baseCommission =  calculateMonthlyAmount(watchCommissionFreq as SalaryFrequency, watchCommission);
            setValue("calcCommission", baseCommission)
         }
    },[watchCommission, watchCommissionFreq]);

    useEffect(() => {
        if(watchCommission2 && watchCommissionFreq2) {
            const baseCommission =  calculateMonthlyAmount(watchCommissionFreq2 as SalaryFrequency, watchCommission2);
            setValue("calcCommission2", baseCommission)
         }
    },[watchCommission2, watchCommissionFreq2]);

    useEffect(() => {
        if(watchSecondaryContactEmail){
            const selectedContactEmail = getValues('secondaryContactEmail');
            
            const selectedContactData = contacts?.find(contact => contact.email === selectedContactEmail)
            setValue('secondaryContactFullName', selectedContactData?.name);
            setValue('secondaryContactPhone', selectedContactData?.phone);
            setValue('secondaryContactCitizenship', selectedContactData?.citizenship)
            setValue('secondaryContactResidency', getCountryCode(selectedContactData?.residency ?? '') as string)
        }
        
    },[watchSecondaryContactEmail])

    useEffect(() => {
        if (cardCurrency1) {
            if (cardCurrency1 === "AUD") {
                setValue("cardFXConversion1", 1);
            } else {
                getForexData("cardFXConversion1", dayjs().format("YYYY-MM-DD"), cardCurrency1 as string, "default");
            }
        }
    }, [cardCurrency1]);

    useEffect(() => {
        if (cardCurrency1 && cardCurrency2) {
            if (cardCurrency1 === "AUD") {
                setValue("cardFXConversion2", 1);
            } else {
                getForexData("cardFXConversion2", dayjs().format("YYYY-MM-DD"), cardCurrency2 as string, "default");
            }
        }
    }, [cardCurrency2]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // Create a deep copy of the form data
            const updatedData = JSON.parse(JSON.stringify(value));

            // Dispatch the form values to the Redux store
            if(setFormData) {
                setFormData(updatedData);
            }
            
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (cardFXConversion1) {
            const calcValue = convertToNumber(ccLimit1 ?? "");
            setValue("curentCCLimit1", (calcValue / cardFXConversion1).toFixed(2));
        }
    }, [cardFXConversion1]);

    useEffect(() => {
        if (cardFXConversion2) {
            const calcValue = convertToNumber(ccLimit2 ?? "");
            setValue("curentCCLimit2", (calcValue / cardFXConversion2).toFixed(2));
        }
    }, [cardFXConversion2]);

    useEffect(() => {
        if (curentCCLimit1 && curentCCLimit2) {
            setValue("ccTotalLimit", convertToNumber(curentCCLimit1) + convertToNumber(curentCCLimit2));
        }
    }, [curentCCLimit1, curentCCLimit2]);

    const onSubmit: SubmitHandler<CommonFormDataTypes> = data => {
        console.log("form submit", data);
        // Create a deep copy of the form data
        const updatedData = JSON.parse(JSON.stringify(data)) as CommonFormDataTypes;

        
        
        updatedData.contactResidency = getCountryData( updatedData?.contactResidency ?? "" as any)?.name as any;
        updatedData.secondaryContactResidency = getCountryData( updatedData?.secondaryContactResidency ?? "" as any)?.name as any;
        if(setFormData) {
            setFormData(updatedData);
        }
        handleFormSubmit({formData:updatedData});
    };

    const getAllUpdatedValues = () => {
        if(getAllValueFromForm) {
            const updatedValues = JSON.parse(JSON.stringify(getValues()));
            updatedValues.contactResidency = getCountryData( updatedValues?.contactResidency ?? "" as any)?.name as any;
            updatedValues.secondaryContactResidency = getCountryData( updatedValues?.secondaryContactResidency ?? "" as any)?.name as any;
             getAllValueFromForm(updatedValues);
        }
    }
    
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
               {formActionType === "update" && <div style={{marginBottom: "1rem"}}>
                    <Collapse
                        defaultActiveKey={["0.1"]}
                        items={[{ key: "0.1", label: "Deal Info", children: <OpportunityInfoForm control={control} errors={errors} /> }]}
                    />
                </div>}
                {formActionType === "update" && <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["1"]}
                        items={[{ key: "1", label: "Broker Detail", children: <ProcessorForm control={control} errors={errors} /> }]}
                    />
                </div>}
                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["1"]}
                        items={[{ key: "1", label: "Contact Detail", children: <BasicDetailForm contacts={contacts} contactsLoading={contactsLoading} control={control} errors={errors} /> }]}
                    />
                </div>
                
                <Row>
                    <Col xs={24} md={24}>
                        <div className="form-control">
                            <CheckboxField name="coApplicationStatus" control={control} label="Has Co-Applicant" error={errors.coApplicationStatus?.message} />
                        </div>
                    </Col>
                </Row>

                {hasCoApplicant && (
                    <div style={{ marginBottom: "1rem" }}>
                        <Collapse
                            defaultActiveKey={["2"]}
                            items={[{ key: "2", label: "Co Applicant Detail", children: <CoApplicantForm contacts={contacts} contactsLoading={contactsLoading} control={control} errors={errors} /> }]}
                        />
                    </div>
                )}

                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["11"]}
                        items={[{ key: "11", label: "Loan Detail", children: <LoanDetailForm control={control} errors={errors} /> }]}
                    />
                </div>

                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["3"]}
                        items={[{ key: "3", label: "Applicant Occupation", children: <OccupationForm control={control} errors={errors} /> }]}
                    />
                </div>
               
                {hasCoApplicant && <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["3"]}
                        items={[{ key: "3", label: "Co Applicant Occupation", children: <CoApplicantOccupation control={control} errors={errors} /> }]}
                    />
                </div>}

                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["4"]}
                        items={[{ key: "4", label: "Family Details", children: <FamilyDetailsForm control={control} errors={errors} /> }]}
                    />
                </div>

                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["5"]}
                        items={[
                            {
                                key: "5",
                                label: "Card Details",
                                children: <CardDetailForm hasCoApplicant={hasCoApplicant} control={control} errors={errors} />
                            }
                        ]}
                    />
                </div>

                <Row>
                    <Col xs={24} md={24}>
                        <Button type="primary" style={{ display: "none" }} htmlType="submit" ref={submitRef}>
                            Generate
                        </Button>
                    </Col>
                </Row>
            </form>
        </>
    );
});

export default SharedForm;
