import { Stack, Pivot, PivotItem } from "@fluentui/react";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { ChatAppResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { ThoughtProcess } from "./ThoughtProcess";
import { MarkdownViewer } from "../MarkdownViewer";
import { useMsal } from "@azure/msal-react";
import { getHeaders } from "../../api";
import { useLogin, getToken } from "../../authConfig";
import { useState, useEffect } from "react";
import { fieldClassNames } from "@fluentui/react-components";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: ChatAppResponse | null;
    docUrl?:string | null;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged, docUrl }: Props) => {
    const isDisabledThoughtProcessTab: boolean = !answer?.context?.thoughts ?? false;;
    const isDisabledSupportingContentTab: boolean = !answer?.context?.thoughts ?? false;;
    const isDisabledCitationTab: boolean = !activeCitation;
    const [citation, setCitation] = useState("");

    const client = useLogin ? useMsal().instance : undefined;

    const fetchCitation = async () => {
        const token = client ? await getToken(client) : undefined;
        if (activeCitation) {
            console.log("activeCitation", activeCitation)
            // Get hash from the URL as it may contain #page=N
            // which helps browser PDF renderer jump to correct page N
            const originalHash = activeCitation.indexOf("#") ? activeCitation.split("#")[1] : "";
            const response = await fetch(activeCitation, {
                method: "GET",
                headers: getHeaders(token)
            });
            const citationContent = await response.blob();
            let citationObjectUrl = URL.createObjectURL(citationContent);
            // Add hash back to the new blob URL
            if (originalHash) {
                citationObjectUrl += "#" + originalHash;
            }
            setCitation(citationObjectUrl);
        }
    };
    useEffect(() => {
        fetchCitation();
    }, []);

    // const renderFileViewer = () => {
    //     if (!activeCitation) {
    //         return null;
    //     }
    //     console.log("AnalysisPanel renderFileViewer", activeCitation)

    //     const fileExtension = activeCitation.split(".").pop()?.toLowerCase();
    //     const checkAzureExtension = fileExtension?.substring(0,3)
    //     if (checkAzureExtension === 'pdf'){
    //         return  <iframe title="Citation" src={activeCitation} width="100%" height={citationHeight} />;
    //     }
    //     switch (fileExtension) {
    //         case "png":
    //             return <img src={citation} className={styles.citationImg} alt="Citation Image" />;
    //         case "md":
    //             return <MarkdownViewer src={activeCitation} />;
    //         default:
    //             return <iframe title="Citation" src={citation} width="100%" height={citationHeight} />;
    //     }
    // };
    const renderFileViewer = () => {
        if (!activeCitation) {
            return null;
        }
        // Extract the base URL and parameters
        const [baseUrl, params] = activeCitation.split('?');
        const fileExtension = baseUrl.split(".").pop()?.toLowerCase();
        const searchParam = params ? decodeURIComponent(new URLSearchParams(params).get('search') || '') : '';
    
        let newSrc = baseUrl;
        if (fileExtension === 'pdf' && params) {
            newSrc = `${baseUrl}#${params.replace('page=', 'page=')}`;
        } else if (fileExtension === 'txt' && baseUrl.endsWith('Brokercopilot.txt') && searchParam) {
            // Handle txt files that end with 'Brokercopilot.txt' and contain a search parameter
            newSrc = `${baseUrl}#search=${decodeURIComponent(searchParam)}`;
            newSrc = activeCitation;
        }
        // Render iframe based on the file type
        if (fileExtension === 'pdf') {
            return <iframe title="Citation" src={newSrc} width="100%" height="citationHeight" style={{ border: 'none' }} />;
        }

        switch (fileExtension) {
            case "png":
                return <img src={baseUrl} className={styles.citationImg} alt="Citation Image" />;
            case "md":
                return <MarkdownViewer src={baseUrl} />;
            case "txt":
                console.log("renderFileViewer, searchParam", searchParam)
                if (!searchParam) {
                    return <iframe title="Citation" src={baseUrl} width="100%" height={citationHeight} style={{ border: 'none' }} />;
                }
                // Fetch the .txt content, highlight the searchParam, and create a dynamic HTML for iframe
                fetch(baseUrl).then(response => response.text()).then(text => {
                    const highlightedText = text.replace(new RegExp(searchParam, 'gi'), `<mark>${searchParam}</mark>`);
                    const htmlContent = `<html><head><style>mark { background-color: yellow; }</style></head><body><pre>${highlightedText}</pre></body></html>`;
                    const iframe = document.getElementById('txtFrame') as HTMLIFrameElement; // Cast to HTMLIFrameElement
                    if (iframe) {
                        iframe.srcdoc = htmlContent;
                    }
                });
                return <iframe id="txtFrame" title="Citation" width="100%" height={citationHeight} style={{ border: 'none' }} />;
                // Assume a simple txt viewer iframe that can potentially handle search highlighting via URL fragment
                // return <iframe title="Citation" src={newSrc} width="100%" height="citationHeight" style={{ border: 'none' }} />;
            default:
                return <iframe title="Citation" src={baseUrl} width="100%" height="citationHeight" style={{ border: 'none' }} />;
        }
    };

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            {answer && <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                <ThoughtProcess thoughts={answer.context.thoughts || []} />
            </PivotItem>}
            {answer && <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Supporting content"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                <SupportingContent supportingContent={answer.context.data_points} />
            </PivotItem>}
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citation"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >
                {renderFileViewer()}
            </PivotItem>
        </Pivot>
    );
};
