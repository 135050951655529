import { FC, forwardRef, useEffect, useImperativeHandle, useState } from "react";
// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// components
import { InputField } from "@/components/Form";
import { Button, Col, Row } from "antd";
import SelectField from "@/components/Form/SelectField";

// data
import { countries, getEmojiFlag, TCountryCode } from "countries-list";
import { CountryOptionType } from "@/pages/opportunity/brokerNotes/types";

const newContactSchema = yup.object().shape({
    email: yup.string().required("Email is required"),
    name: yup.string(),
    phone: yup.string(),
    residence: yup.string(),
    citizenship: yup.string()
});
export type NewContactType = yup.InferType<typeof newContactSchema>;

export interface NewContactFormMethods {
    resetNewContact: () => void;
}
interface NewContactFormProps {
    createContact: ({newContactData}:{newContactData:NewContactType}) => void;
    isLoading: boolean;
    formBtnText: string;
};
const NewContactForm = forwardRef<NewContactFormMethods, NewContactFormProps> (({createContact, isLoading, formBtnText }, ref) => {
    const [countryOptions, setCountryOptions] = useState<CountryOptionType[]>([]);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(newContactSchema),
        defaultValues: {
            email:"",
            name: "",
            phone: "",
            residence: "",
            citizenship: ""
        }
    });

    useImperativeHandle(ref, () => ({
        resetNewContact(){
            reset();
        }
    }));

    useEffect(() => {
        let autoCompleteOptions = [];
        for (let country in countries) {
            autoCompleteOptions.push({
                value: country,
                label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{getEmojiFlag(country as TCountryCode)}</span> &nbsp; {countries[country as TCountryCode]?.name}
                    </div>
                )
            });
        }
        setCountryOptions(autoCompleteOptions);
    }, []);

   

    const onSubmit: SubmitHandler<NewContactType> =  async (data) => {
        createContact({newContactData:data});
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={12}>
                    <Col xs={24} md={24}>
                        <InputField name="email" label="Email" control={control} error={errors.email?.message ?? ""} />
                    </Col>
                    <Col xs={24} md={24}>
                        <InputField name="name" label="Name" control={control} error={errors.name?.message ?? ""} />
                    </Col>
                    <Col xs={24} md={24}>
                        <InputField name="phone" label="Phone" control={control} error={errors.phone?.message ?? ""} />
                    </Col>
                    <Col xs={24} md={24}>
                         <SelectField options={countryOptions} label="Residence" name="residence" control={control} error={errors.residence?.message ?? ""} />
                    </Col>
                    <Col xs={24} md={24}>
                        <Button loading={isLoading} type="primary" htmlType="submit" style={{ width: "100%"}}>
                            {formBtnText}
                        </Button>
                    </Col>
                </Row>
            </form>        
        </>
    )
});

export default NewContactForm;