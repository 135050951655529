import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CommonState{
    isLoading: boolean;
    errorMessage: string | null;
}

const initialState: CommonState = {
    isLoading: false,
    errorMessage: null,
};
const commonSlice = createSlice({
    name:"common",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setErrorMessage(state, action: PayloadAction<string | null>) {
            state.errorMessage = action.payload;
        }, 
    }
});

export const { setLoading, setErrorMessage } = commonSlice.actions;

export default commonSlice.reducer;

