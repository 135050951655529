import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserDataType {
    email: string;
    name: string;
}

interface AuthState {
    token: string | null;
    refreshToken: string | null;
    isAuthenticated: boolean;
    userData: UserDataType | null;
}

// Helper function to get data from sessionStorage
const getSessionData = <T>(key: string): T | null => {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
};

const initialState: AuthState = {
    token: getSessionData<string>("broker_copilot_token"),
    refreshToken: getSessionData<string>("broker_copilot_refreshToken"),
    isAuthenticated: !!getSessionData<string>("broker_copilot_token"),
    userData: getSessionData<UserDataType>("broker_copilot_userData")
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthToken: (state, action: PayloadAction<{ token: string; refreshToken: string }>) => {
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshToken;
            state.isAuthenticated = true;
            sessionStorage.setItem("broker_copilot_token", JSON.stringify(action.payload.token));
            sessionStorage.setItem("broker_copilot_refreshToken", JSON.stringify(action.payload.refreshToken));
        },
        clearAuthToken: state => {
            state.token = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
            sessionStorage.removeItem("broker_copilot_token");
            sessionStorage.removeItem("broker_copilot_refreshToken");
            sessionStorage.removeItem("broker_copilot_userData");
        },
        setUserDetails: (state, action: PayloadAction<UserDataType>) => {
            state.userData = action.payload;
            sessionStorage.setItem("broker_copilot_userData", JSON.stringify(action.payload));
        }
    }
});

export const { setAuthToken, clearAuthToken, setUserDetails } = authSlice.actions;
export default authSlice.reducer;
