import { useMsal, useAccount } from "@azure/msal-react";
import { Flex, Dropdown, Space, Avatar } from "antd";
import { ContainerOutlined, DownOutlined, LogoutOutlined, SettingOutlined, SmileOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useEffect, useState } from "react";
import { storageSession } from "@/utils/storage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { clearAuthToken } from "@/store/slices/auth/authSlice";

const iconStyle = { fontSize: "16px" };
const items: MenuProps["items"] = [
    {
        key: "1",
        icon: <ContainerOutlined style={iconStyle} />,
        label: "My Plan"
    },
    {
        key: "2",
        label: "Settings",
        icon: <SettingOutlined style={iconStyle} />
    },
    {
        type: "divider"
    },
    {
        key: "3",
        icon: <LogoutOutlined style={iconStyle} />,
        label: "Log Out"
    }
];

const ProfileMenu = () => {
    const authState = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    const { accounts, instance } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (authState && authState.userData?.name) {
            const nameParts = authState.userData?.name.split(" ");
            setName(nameParts[0][0] + nameParts[nameParts.length - 1][0]);
        } else {
            setName("");
        }
    }, [authState]);

    const handleMenuClick: MenuProps["onClick"] = e => {
        if (e.key === "3") {
            dispatch(clearAuthToken());
            navigate("/auth");
        }
    };
    return (
        <>
            <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={["click"]} overlayStyle={{ width: "300px" }} overlayClassName="test">
                <a onClick={e => e.preventDefault()}>
                    <Avatar style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }} gap={4}>
                        {name}
                    </Avatar>
                </a>
            </Dropdown>
        </>
    );
};

export default ProfileMenu;
