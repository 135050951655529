import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton, Modal, Button, Row, Flex, Drawer } from "antd";
//AgGrid
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
//Store
import { useDispatch } from "react-redux";
import { useGetAllDealContactsQuery, usePostContactMutation } from "@/store/apis/opportunityApi";
import { setLoading } from "@/store/slices/common/commonSlice";
//utils
import { displayError, displaySuccess } from "@/utils/common.utils";
import ActionButtonsRenderer from "@/pages/opportunity/loanRequest/components/ActionButtonsRenderer";
import { PlusOutlined } from "@ant-design/icons";
import NewContactForm, { NewContactFormMethods, NewContactType } from "../components/NewContactForm";
import { ContactType } from "@/pages/opportunity/types";



export const ContactPage = () => {
    const dispatch = useDispatch();
    const gridRef = useRef<any>(null);
    const newContactFormRef = useRef<NewContactFormMethods>(null);
    const [showNewContactModal, setShowNewContactModal] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState("New Contact");
    const [formBtnText, setFormBtnText] = useState("Create Contact");

    const handleEditContact = (data:ContactType) => {
        console.log(data);
        setDrawerTitle("Edit Contact");
    }

    const [colDefs, setColDefs] = useState<ColDef[]>(() => {
        const columns: ColDef[] = [
            {
                headerName: "Name",
                field: "name",
                filter: true,
                editable: true,
                flex: 1,
            },
            {
                headerName: "Email",
                field: "email",
                filter: true,
                editable: true,
                flex: 1
            },
            {
                headerName: "Phone",
                field: "phone",
                filter: true,
                editable: true,
                flex: 1
            },
            {
                headerName: "Residency",
                field: "residency",
                filter: true,
                editable: true,
                flex: 1
            }
        ]

        columns.push({
            headerName: "Actions",
            cellRenderer: ActionButtonsRenderer,
            cellRendererParams: {
                buttons:['select'],
                selectTooltip: "Open Contact",
                onAction: handleEditContact, // Passing the callback function to the renderer
            },
            width: 80,
            suppressMenu: true,
            pinned: "right"
        });
        return columns;
    })

   
    const {data: contacts, isLoading: contactsLoading, isError: contactsError, isSuccess: contactsSuccess, refetch} = useGetAllDealContactsQuery(undefined, {
        refetchOnMountOrArgChange: true
    });
    const [postNewContact, {isLoading: newConactLoading}] = usePostContactMutation();
    const handleCloseContactModal = () => {
        newContactFormRef.current?.resetNewContact();
        setShowNewContactModal(false);
    }
    const handleCreateContact = ({newContactData}:{newContactData:NewContactType}) => {
        postNewContact({
            email: newContactData.email,
            name: newContactData.name ?? "",
            phone: newContactData.phone ?? "",
            residency: newContactData.residence ?? "",
            countryCode: newContactData.residence ?? "",
        })
        .unwrap()
        .then(response => {
            newContactFormRef.current?.resetNewContact();
            displaySuccess("Successfully created new contact");
            setShowNewContactModal(false);
            refetch();
        })
        .catch(error => {
            displayError(error);
        })
    }

    const opeAddContact = () => {
        setDrawerTitle("New Contact");
        setShowNewContactModal(true)
    }
    return (
        <div style={{display:"block", width:"100%"}}>
            <Flex style={{marginBottom:"1rem"}} justify="flex-end">
                <Button icon={<PlusOutlined />} type="primary" onClick={opeAddContact}>Add Contact</Button>
            </Flex>
            {contactsLoading ? (
                <Skeleton />
            ) : (
                <div className="ag-theme-quartz" style={{ height: "calc(100vh - 200px)" }}>
                    <AgGridReact tooltipInteraction={true} ref={gridRef} rowData={contacts} columnDefs={colDefs} sideBar={"columns"} />
                </div>
            )}   
            <Drawer
                title={drawerTitle}
                placement="right"
                size="large"
                onClose={handleCloseContactModal}
                open={showNewContactModal}>
                    <NewContactForm createContact={handleCreateContact} isLoading={newConactLoading}  ref={newContactFormRef} formBtnText={formBtnText}/>
            </Drawer>         
        </div>
    );
};
