import { SettingsRegular } from "@fluentui/react-icons";
import { Button } from "antd";

interface Props {
    className?: string;
    onClick: () => void;
}

export const SettingsButton = ({ onClick }: Props) => {
    return (
        <Button type="text" icon={<SettingsRegular />} onClick={onClick}>
            {"Developer settings"}
        </Button>
    );
};
