import { Col, Row, theme } from "antd";
import { FC } from "react";

import { BrokerNoteFormData } from "../../types";
import AsideNoteheading from "./AsideNoteHeading";
import { TableData } from "@/components/UI";
const { useToken } = theme;

interface FundsDetailsProps {
    allValues: Partial<BrokerNoteFormData>;
}
const FundsDetail: FC<FundsDetailsProps> = ({ allValues }) => {
    const { token } = useToken();
    return (
        <Row gutter={10} style={{ marginBottom: "10px" }}>
            <Col xs={24} md={6}>
                <AsideNoteheading title="Funds to Complete" />
            </Col>
            <Col xs={24} md={18}>
                <Row gutter={20}>
                    <Col xs={12} md={12}>
                        <div>
                            <div style={{ background: token.colorFillSecondary }}>
                                <Row>
                                    <Col xs={24} md={24}>
                                        <TableData type="title" title="Funds to Complete" />
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title="Purchase Price:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title={allValues.propertyValue ?? ""} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title="Less: Loan Amount:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title={allValues.loanAmount ?? ""} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title="Less: Cash Out Amount:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title="(1)" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="Add: Stamp, Duty, mics:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title="(50000)" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="Funds Required:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title={allValues.propertyValue ?? ""} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={12}>
                        <div>
                            <div style={{ background: token.colorFillSecondary }}>
                                <Row>
                                    <Col xs={24} md={24}>
                                        <TableData type="title" title="Funds Available" />
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="BANK #001:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title="0" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="BANK #002:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title="0" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="BANK #003:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title="0" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="SHARES #001:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title="0" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="Cash, gift, parents:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title="0" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title="Total:" />
                                    </Col>
                                    <Col xs={16} md={16}>
                                        <TableData title="0" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default FundsDetail;
