import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SystemPromptType } from "@/pages/opportunity/brokerNotes/components/promptModal";
import {
    LOAN_PURPOSE_SYSTEM_CONTENT,
    APPLICANT_OVERVIEW_SYSTEM_CONTENT,
    LIVING_CONDITION_SYSTEM_CONTENT,
    EMPLOYMENT_INCOME_SYSTEM_CONTENT,
    COMMITMENT_SYSTEM_CONTENT,
    OTHERS_SYSTEM_CONTENT,
    MITIGANTS_SYSTEM_CONTENT
} from "@/pages/opportunity/brokerNotes/constant/systemContent";

const initialState: SystemPromptType = {
    loanPurpose: LOAN_PURPOSE_SYSTEM_CONTENT,
    applicationOverview: APPLICANT_OVERVIEW_SYSTEM_CONTENT,
    livingCondition: LIVING_CONDITION_SYSTEM_CONTENT,
    employmentIncome: EMPLOYMENT_INCOME_SYSTEM_CONTENT,
    commitments: COMMITMENT_SYSTEM_CONTENT,
    others: OTHERS_SYSTEM_CONTENT,
    mitigant: MITIGANTS_SYSTEM_CONTENT
};

const systemPromptSlice = createSlice({
    name: "brokerNoteSystemPrompt",
    initialState,
    reducers: {
        setSystemPrompt: (state, action: PayloadAction<SystemPromptType>) => {
            return { ...state, ...action.payload };
        }
    }
});

export const { setSystemPrompt } = systemPromptSlice.actions;
export default systemPromptSlice.reducer;
