import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd";
//AgGrid
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

//Store
import { useGetAllOpportunityServiceHistoryQuery } from "@/store/apis/opportunityApi";
import { formWebsiteMapper } from "@/devFrontData/formWebsiteMapper";
import ActionButtonsRenderer from "../loanRequest/components/ActionButtonsRenderer";
import { displayError } from "@/utils/common.utils";

interface OpportunityListProps {
    haldeSelectedDeal: (selectedId: number) => void;
}
const AllOpportunityList: FC<OpportunityListProps> = ({ haldeSelectedDeal }) => {
    const navigate = useNavigate();
    const gridRef = useRef<any>(null);

    const handleAction = (data: any) => {
        navigate(`/deals/${data.id}?source=deals`, { replace: true });
        haldeSelectedDeal(data.id);
    };
    const [colDefs, setColDefs] = useState<ColDef[]>(() => {

        const columns: ColDef[] =  [
            {
                headerName: "Deal Name",
                field: "name",
                headerTooltip: "Deal Name",
                filter: true,
                editable: true
            },
             {
                headerName: "Deal Type",
                field: "type",
                headerTooltip: "Deal Type",
                filter: true,
                editable: true
            },
            {
                headerName: "Contact",
                field: "primary_contact.email",
                headerTooltip: "Primary Contact",
                filter: true,
                editable: true
            },
            {
                headerName: "Secondary Contact",
                field: "secondary_contact.email",
                headerTooltip: "Secondary Contact",
                filter: true,
                editable: true
            },
            {
                headerName: "Actions",
                cellRenderer: ActionButtonsRenderer,
                cellRendererParams: {
                    buttons:['select', 'delete'],
                    tooltipTitle: "Open Deal",
                    onAction: handleAction, // Passing the callback function to the renderer
                },
                width: 150,
                suppressMenu: true,
                pinned: "right"
            }
        ];
    
        return columns;
    });

    const {
        data: opportunityList,
        isLoading,
        isSuccess,
        isError,
        refetch
    } = useGetAllOpportunityServiceHistoryQuery(undefined, {
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        if(isError){
            displayError("Error fetching deals");
        }
    },[isError])

    return (
        <div>
            {isLoading ? (
                <Skeleton />
            ) : (
                <div className="ag-theme-quartz" style={{ height: "calc(100vh - 280px)" }}>
                    <AgGridReact tooltipInteraction={true} ref={gridRef} rowData={opportunityList} columnDefs={colDefs} sideBar={"columns"} />
                </div>
            )}
        </div>
    );
};

export default AllOpportunityList;
