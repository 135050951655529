import { FC, RefObject, useRef } from "react";
import { Card, Col, Collapse, Row } from "antd";

import { CommonFormDataTypes } from "@/pages/opportunity/types";
import NeedAnalysisForm, { NeedAnalysisFormType } from "./NeedAnalysisForm";
import AIContent from "../AIContent";
import { ComplianceFieldTypes } from "../../types";
import PreferredLoanForm, { PreferredLoanFormType } from "./PreferredLoanForm";
import LoanScenarioForm, { LoanScenarioFormType } from "./LoanScenarioForm";

interface ComplianceNotePDFProps {
    allValues: Partial<CommonFormDataTypes>;
    generateAIContent: (id:ComplianceFieldTypes) => void;

    loanObjectivesLoading: boolean;
    loanObjectiveData: any;
    
    loanRequirementsLoading: boolean;
    loanRequirementsData: any;

    loanCircumstancesLoading: boolean;
    loanCircumstancesData: any;

    loanFinancialAwarenessLoading: boolean;
    loanFinancialAwarenessData: any;
    
    submitNeedAnalysisRef: RefObject<HTMLButtonElement>;
    handleNeedAnalysisForm: (data: NeedAnalysisFormType) => void;
    setNeedAnalysisFormData?: (data: NeedAnalysisFormType) => void;

    submitLoanPrioritiseRef: RefObject<HTMLButtonElement>;
    handlePreferredLoanForm: (data: PreferredLoanFormType) => void;

    loanPrioritisedLoading: boolean;
    loanPrioritisedData: any;

    submitLoanScenarioRef: RefObject<HTMLButtonElement>;
    handleLoanScenarioForm: (data: LoanScenarioFormType) => void;
    setLoanScenarioFormData?: (data: LoanScenarioFormType) => void;

    lenderLoanLoading: boolean;
    lenderLoanData: any;

    loanStructureLoading: boolean;
    loanStructureData: any;
}

const ComplianceNotePDF:FC<ComplianceNotePDFProps> = ({ 
    loanObjectivesLoading, 
    loanObjectiveData, 
    generateAIContent, 
    loanRequirementsLoading, 
    loanRequirementsData ,

    loanCircumstancesLoading,
    loanCircumstancesData,

    loanFinancialAwarenessLoading,
    loanFinancialAwarenessData,
    
    submitNeedAnalysisRef,
    handleNeedAnalysisForm,
    setNeedAnalysisFormData,

    submitLoanPrioritiseRef,
    handlePreferredLoanForm,

    loanPrioritisedLoading,
    loanPrioritisedData,

    submitLoanScenarioRef,
    handleLoanScenarioForm,
    setLoanScenarioFormData,

    lenderLoanLoading,
    lenderLoanData,

    loanStructureLoading,
    loanStructureData
 }) => {
  
    
    return (
        <div>
             <Row>
                <Col xs={24} md={24}>
                  <div style={{ marginBottom: "1rem" }}>
                     <Collapse
                           defaultActiveKey={["1"]}
                           items={[{ key: "1", label: "Needs Analysis", children: <>
                              <NeedAnalysisForm  handleFormSubmit={handleNeedAnalysisForm} submitRef={submitNeedAnalysisRef} setFormData={setNeedAnalysisFormData}/>
                        
                              <AIContent 
                                 title="Loan Objectives" 
                                 paragraph="Provide details of your client conversation in relation to their loan objective" 
                                 id="loan_objectives"
                                 isLoading={loanObjectivesLoading}
                                 aiData={loanObjectiveData}
                                 generateAIContent={generateAIContent}
                              />

                              <AIContent 
                                 title="Loan Requirements" 
                                 paragraph="Provide details of you client conversation in relation to their loan requirements" 
                                 id="loan_requirements"
                                 isLoading={loanRequirementsLoading}
                                 aiData={loanRequirementsData}
                                 generateAIContent={generateAIContent}
                              />
                           </> }]}
                     />
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                     <Collapse
                           defaultActiveKey={["2"]}
                           items={[{ key: "2", label: "Loans, Securities & Commentary", children: <>
                             <AIContent
                                 id="loan_circumstances"
                                 title="Circumstances, Objectives and Priorities"
                                 isLoading={loanCircumstancesLoading}
                                 aiData={loanCircumstancesData}
                                 generateAIContent={generateAIContent}
                                 />

                              <AIContent
                                 id="loan_financial_awareness"
                                 title="Financial Awareness & Practices"
                                 isLoading={loanFinancialAwarenessLoading}
                                 aiData={loanFinancialAwarenessData}
                                 generateAIContent={generateAIContent}
                                 />

                              <AIContent
                                 id="loan_structure"
                                 title="Loan Structure"
                                 isLoading={loanStructureLoading}
                                 aiData={loanStructureData}
                                 generateAIContent={generateAIContent}
                                 />
                           </> }]}
                     />
                  </div>

                  <div style={{ marginBottom: "1rem" }}>
                     <Collapse
                           defaultActiveKey={["3"]}
                           items={[{ key: "3", label: "Preferred Loan Features / Scenarios" , children: <>
                              <PreferredLoanForm handleFormSubmit={handlePreferredLoanForm} submitRef={submitLoanPrioritiseRef}/>

                              <AIContent
                                 id="loan_prioritised"
                                 title="Prioritised Loan Features"
                                 paragraph="Provide details of your client conversation in relation to their prioritised loan features"
                                 isLoading={loanPrioritisedLoading}
                                 aiData={loanPrioritisedData}
                                 generateAIContent={generateAIContent}
                                 />
                              <LoanScenarioForm
                                 submitRef={submitLoanScenarioRef}
                                 handleFormSubmit={handleLoanScenarioForm}
                                 setFormData={setLoanScenarioFormData}
                              />
                           </> }]}
                     />
                  </div>
                     <div style={{ marginBottom: "1rem" }}>
                        <Collapse
                           defaultActiveKey={["4"]}
                           items={[{ key: "4", label: "Recommendation" , children: <>
                           

                              <AIContent
                                 id="lender_loan"
                                 title="Lender, Loan Amount & Interest Rate"
                                 isLoading={lenderLoanLoading}
                                 aiData={lenderLoanData}
                                 generateAIContent={generateAIContent}
                              />

                              <AIContent
                                 id="loan_structure"
                                 title="Loan Structure"
                                 isLoading={loanStructureLoading}
                                 aiData={loanStructureData}
                                 generateAIContent={generateAIContent}
                              />
                           </> 
                           }]}
                     />
                  </div>
                </Col>
            </Row>
        </div>
    )
}

export default ComplianceNotePDF;