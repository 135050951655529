import { DefaultButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { Button, Tooltip, Grid } from "antd";

import { getRedirectUri, loginRequest } from "../../authConfig";
import { appServicesToken, appServicesLogout } from "../../authConfig";
import { useTheme } from "@/contexts/themeContext";
import { PersonArrowRightRegular } from "@fluentui/react-icons";

import styles from "./LoginButton.module.css";
import { FC } from "react";

const { useBreakpoint } = Grid;

interface LoginButtonProps {
    size?: "large" | "small" | "middle";
    width?: string;
}

export const LoginButton: FC<LoginButtonProps> = ({ size, width }) => {
    const { sidebarCollapseState } = useTheme();
    const { md } = useBreakpoint();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const isLoggedIn = (activeAccount || appServicesToken) != null;

    const handleLoginPopup = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
         */
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: getRedirectUri()
            })
            .catch(error => console.log(error));
    };
    const handleLogoutPopup = () => {
        if (activeAccount) {
            instance
                .logoutPopup({
                    mainWindowRedirectUri: "/", // redirects the top level app after logout
                    account: instance.getActiveAccount()
                })
                .catch(error => console.log(error));
        } else {
            appServicesLogout();
        }
    };
    const logoutText = `Logout\n${activeAccount?.username ?? appServicesToken?.user_claims?.preferred_username}`;

    return (
        // <DefaultButton
        //     text={isLoggedIn ? logoutText : "Login"}
        //     className={styles.loginButton}
        //     onClick={isLoggedIn ? handleLogoutPopup : handleLoginPopup}
        // ></DefaultButton>

        sidebarCollapseState ? (
            <Tooltip title="login" placement="right">
                <Button block style={{ fontSize: "20px" }} type="text" icon={<PersonArrowRightRegular />} />
            </Tooltip>
        ) : (
            <Button
                size={size ? size : md ? "large" : "small"}
                style={{ fontWeight: "700", borderRadius: "30px", width: width ? width : md ? "100%" : "auto" }}
                type="primary"
                onClick={isLoggedIn ? handleLogoutPopup : handleLoginPopup}
            >
                {isLoggedIn ? logoutText : "Login"}
            </Button>
        )
    );
};
