import { Grid } from "antd";
import Title from "antd/es/typography/Title";
import COPILOT from "@/assets/copilot.png";

const { useBreakpoint } = Grid;

const BodyHeading = () => {
    const { md } = useBreakpoint();

    return (
        <>
            {md && <img src={COPILOT} alt="" style={{ height: "50px" }} />}
            <Title level={2} style={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
                Navigate Success with Broker Copilot
            </Title>
            {/* {md && (
                <Title type="secondary" level={5} style={{ marginTop: "0" }}>
                    Your Mortgage Partnership Elevated
                </Title>
            )} */}
        </>
    );
};

export default BodyHeading;
