// src/store/apis/authApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { ApiResponse } from "@/types";
import { ComplianceFieldTypes, ComplianceFormType } from "@/pages/noteGeneration/types";

import { getComplianceUserContent } from "@/pages/noteGeneration/utils";


export const complianceNoteApi = createApi({
    reducerPath: "complianceNoteApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        generateComplianceNote: builder.mutation<any, {type: ComplianceFieldTypes, data:ComplianceFormType}>({
            query: ({ type, data }) => {
                const userContent = getComplianceUserContent(data, type);
               

                const postData = {
                    "user_content": userContent,
                    "compliance_field": type
                };

                return {
                    url: "api/comliance-note/generate/",
                    method: "POST",
                    body: postData
                };
            },
            transformResponse: (response: ApiResponse<any>):any => {
                return response.data;
            }
        })
    })
});
export const { useGenerateComplianceNoteMutation } = complianceNoteApi;
