import Card from "antd/es/card/Card";
import styles from "./UserChatMessage.module.css";

interface Props {
    message: string;
}

export const UserChatMessage = ({ message }: Props) => {
    return (
        <div className={styles.container}>
            {/* <div className={styles.message}>{message}</div> */}
            <Card styles={{ body: { padding: "10px" } }}>{message}</Card>
        </div>
    );
};
