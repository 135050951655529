import * as yup from "yup";


export const complianceFormSchema = yup.object().shape({ 
    dealName: yup.string().required("Deal Name is required"),
    dealType: yup.string().required("Deal Type is required"),


    brokerPrimaryContact: yup.string().nullable().notRequired(),
    brokerSecondaryContact: yup.string().nullable().notRequired(),
    
    contactEmail: yup.string().email("Enter a valid email address."),
    contactFullName: yup.string(),
    contactPhone: yup.string(),
    contactCitizenship: yup.string().required("Contact Citizenship is required"),
    contactResidency: yup.string().required("Contact Residency is required"),

    hasCoApplicant: yup.boolean(),
    
    secondaryContactEmail: yup.string().email("Enter a valid email address."),
    secondaryContactFullName: yup.string(),
    secondaryContactPhone: yup.string(),
    secondaryContactCitizenship: yup.string(),
    secondaryContactResidency: yup.string(),    

    websiteTrackingId: yup.string(),
    formId: yup.string(),
    fullName: yup.string(),
    email: yup.string().email("Enter a valid email address."),
    phone: yup.string(),
    citizenship: yup.string(),

    residency: yup.string(),
    countryCode: yup.string(),

    occupation: yup.string().required("Occupation is required"),
    employment: yup.string().required("Employment field is required."),
    employer: yup.string(),
    since: yup.string(),
    currency: yup.string(),
    fx: yup.string(),

    salaryFreq: yup.string(),
    salary: yup.string(),
    calMonthSalary: yup.string(),

    deduction: yup.number(),
    allowances: yup.string(),
    allowances_2: yup.string(),
    allowanceFreq: yup.string(),
    allowanceFreq_2: yup.string(),
    commission: yup.number(),
    bonusThis: yup.string(),
    bonusLast: yup.string(),
    anyLiabilities: yup.string(),
    detailsOfDebt: yup.string(),
    date: yup.string(),

    pricing: yup.string(),
    interestRate: yup.number(),
    valuation: yup.string(),
    livingStatus: yup.string(),
    mortgage: yup.string(),
    rentalExpense: yup.string(),
    maritalStatus: yup.string(),
    kids: yup.string(),
    // kidsAge: yup.mixed().oneOf([yup.number(), yup.string()], "please enter a valid age"),
    kidsAge: yup.string(),
    privateEducation: yup.string(),
    purpose: yup.string().required("Purpose is required"),
    purposeEquity: yup.string(),
    lvr: yup.number().required("Lvr is required"),
    security: yup.string(),
    aipRentalyiel: yup.number(),
    apiRent: yup.number(),
    ccTotalLimit: yup.number(),
    reduceLimit: yup.string(),

    ccNewLimit: yup.number(),
    lender: yup.string(),
    existingCustomer: yup.string(),
    propertyValue: yup.number(),
    loanAmount: yup.number(),
    loanTerm: yup.number(),
    product: yup.string(),

    //card details
    ccLimit1: yup.string(),
    cardCurrency1: yup.string(),
    cardFXConversion1: yup.number(),
    curentCCLimit1: yup.string(),

    //Co Applicant Details
    coApplicationStatus: yup.boolean(),
    fullName_2: yup.string(),
    firstName_2: yup.string(),
    lastName_2: yup.string(),
    phone_2: yup.string(),
    citizenship_2: yup.string(),
    countryResidence_2: yup.string(),
    occupation_2: yup.string(),
    employment_2: yup.string(),
    jobStartMonth_2: yup.string(),
    jobStartYear_2: yup.string(),
    currencySalary_2: yup.string(),
    since_2: yup.string(),
    fx_2: yup.string(),
    fxConversation_2: yup.number(),
    
    salary_2: yup.string(),
    salaryFreq_2: yup.string(),
    calMonthSalary_2: yup.string(),

    ccLimit_2: yup.string(),
    residency_2: yup.string(),
    ccLimit2: yup.string(),
    cardCurrency2: yup.string(),
    cardFXConversion2: yup.number(),
    curentCCLimit2: yup.string(),

    // calculations
    calcAllowance: yup.number(), // *TODO: calculation remaining
});


