// src/store/apis/authApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
export type ApiResponse<T> = {
    success: boolean;
    statusCode: number;
    data: T;
};

export const anzServiceApi = createApi({
    reducerPath: "anzServiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        getAllAnzDocuments: builder.query<ApiResponse<any>, void>({
            query: () => "api/anz/documents/",
            transformResponse: (response: ApiResponse<any>): any => {
                return response;
            }
        })
    })
});
export const { useGetAllAnzDocumentsQuery } = anzServiceApi;
