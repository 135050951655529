import { Col, Row, Select } from "antd";
import { FC, useEffect, useState } from "react";
import { countries, getEmojiFlag, TCountryCode } from "countries-list";
import { InputField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import SelectField from "@/components/Form/SelectField";

export interface CoutriesOptionType {
    value: string;
    label: JSX.Element | React.ReactNode;
}

interface BasicDetailFormProps {
    contacts?: Record<string, any>;
    contactsLoading?: boolean;
}

const BasicDetailForm: FC<FormPropsType & BasicDetailFormProps> = ({ control, errors, contacts, contactsLoading  }) => {
    const [countryOptions, setCountryOptions] = useState<CoutriesOptionType[]>([]);

    useEffect(() => {
        let autoCompleteOptions = [];
        for (let country in countries) {
            autoCompleteOptions.push({
                value: country,
                label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{getEmojiFlag(country as TCountryCode)}</span> &nbsp; {countries[country as TCountryCode]?.name}
                    </div>
                )
            });
        }
        setCountryOptions(autoCompleteOptions);
    }, []);

    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <SelectField
                    name="contactEmail"
                    control={control}
                    label="Email"
                    placeholder="Email"
                    error={errors.contactEmail?.message}
                    options={contacts as any ?? []}
                    disabled={contactsLoading}
                    fieldNames={{label:"email", value:"email"}}
                    showSearch={true}
                />
            </Col>
            
            <Col xs={24} md={12}>
                <InputField label="Full name" name="contactFullName" control={control} error={errors.contactFullName?.message ?? ""} />
            </Col>

            
            <Col xs={24} md={12}>
                <InputField label="Phone" name="contactPhone" control={control} error={errors.contactPhone?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Citizenship" name="contactCitizenship" control={control} error={errors.contactCitizenship?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <SelectField options={countryOptions} label="residency" name="contactResidency" control={control} error={errors.contactResidency?.message ?? ""} />
            </Col>
        </Row>
    );
};

export default BasicDetailForm;
