import { FC, useEffect, useRef } from "react";
import dayjs from "dayjs";
import axios from "axios";

import { getCountryCode } from "countries-list";

// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// AntD
import { Row, Col, Collapse, Button } from "antd";

import { BrokerNoteFormData } from "../types";

// components
import CheckboxField from "@/components/Form/CheckBoxField";
import brokerNoteSchema from "../validations/brokderNoteSchema";
import BasicDetailForm from "./forms/BasicDetailForm";
import OccupationForm from "./forms/OccupationForm";
import CoApplicantForm from "./forms/CoApplicantForm";
import FamilyDetailsForm from "./forms/FamilyDetailsForm";
import ProcessorForm from "./forms/ProcessorForm";
import LoanDetailForm from "./forms/LoanDetailForm";
import CardDetailForm from "./forms/CardDetailForm";

import { RANGE_DATA } from "../constant/calcRangeData";

// utils
import { convertToNumber, debounce } from "@/utils/common.utils";
import { calculateMonthlyAmount } from "../../utils";

// redux
import styles from "./BrokerNote.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { setFormData } from "@/store/slices/brokerNote/formSlice";

interface BrokerNoteFormProps {
    handleFormSubmit: () => void;
    submitRef: React.RefObject<HTMLButtonElement>;
    formFieldsData?: any;
}

const BrokerNoteForm: FC<BrokerNoteFormProps> = ({ handleFormSubmit, submitRef, formFieldsData }) => {
    const dispatch = useDispatch();
    const formData = useSelector((state: RootState) => state.brokerNoteForm);

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(brokerNoteSchema),
        defaultValues: formData
    });

    useEffect(() => {
        if (formFieldsData) {
            const userData = formFieldsData;
            setValue("websiteTrackingId", userData.id);
            setValue("formId", userData.form_id);
            setValue("fullName", `${userData["firstName"]} ${userData["lastName"]}`);
            setValue("email", userData["email"]);
            setValue("phone", userData["phone"]);
            setValue("residency", getCountryCode(userData["residency"]) as string);
            if (userData["date"]) {
                // @ts-ignore
                setValue("date", dayjs(userData["date"]));
            }

            setValue("ccLimit1", userData["ccLimit1"]);
            getForexData("fx", dayjs(userData["date"]).format("YYYY-MM-DD"), userData["currency"]);
            setValue("citizenship", userData["citizenship"]);
            setValue("employment", userData["employment"]);
            setValue("occupation", userData["occupation"]);
            setValue("since", `${userData["jobStartMonth"]} ${userData["jobStartYear"]}`);
            setValue("currency", userData["currency"]);
            setValue("salary", calculateMonthlyAmount(userData["salaryFreq"], userData["salary"]));
            setValue("bonusThis", userData["bonusThis"]);
            setValue("bonusLast", userData["bonusLast"] === "" ? 0 : userData["bonusLast"]);
            const anyLiabilitiesValue =
                userData["osProperties"].toLowerCase() === "no" && userData["anyOtherLoan"].toLowerCase() === "no" && userData[534].toLowerCase() === "no"
                    ? "no"
                    : "yes";

            setValue("anyLiabilities", anyLiabilitiesValue);

            setValue("livingStatus", userData["livingStatus"]);
            setValue("maritalStatus", userData["maritalStatus"]);
            setValue("privateEducation", userData["privateEducation"]);

            setValue("kids", userData["kids"]);

            setValue(
                "kidsAge",
                `${userData["kidsAge_1"]}, ${userData["kidsAge_2"]}, ${userData["kidsAge_3"]}, ${userData["kidsAge_4"]}, ${userData["kidsAge_5"]}`
            );

            let aipRentalyielCalc = "";
            if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[7])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[7];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[6])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[6];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[5])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[5];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[4])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[4];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[3])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[3];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[2])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[2];
            } else if (Number(userData["maxPurchasePrice"]) >= Number(RANGE_DATA.lowerRange[1])) {
                aipRentalyielCalc = RANGE_DATA.rentalYield[1];
            } else {
                aipRentalyielCalc = RANGE_DATA.rentalYield[0]; // Default if none of the conditions are met
            }
            setValue("aipRentalyiel", Number(aipRentalyielCalc));
            let weeklyRent = (Number(aipRentalyielCalc) / 100 / 52) * Number(userData["maxPurchasePrice"]);
            let apiRentCalc = (weeklyRent * 52) / 12;
            setValue("apiRent", apiRentCalc);

            setValue("hasCoApplicant", userData["coApplicationStatus"].toLowerCase() === "yes" ? true : false);
            if (userData["coApplicationStatus"] === "Yes") {
                setValue("fullName_2", `${userData["firstName_2"]} ${userData["lastName_2"]}`);
                setValue("phone_2", userData["phone_2"]);
                setValue("citizenship_2", userData["citizenship_2"]);
                setValue("residency_2", userData["countryResidence_2"] !== "" ? userData["countryResidence_2"] : userData["residency"]);
                setValue("occupation_2", userData["occupation_2"]);
                setValue("employment_2", userData["employment_2"]);
                setValue("since_2", `${userData["jobStartMonth_2"]} ${userData["jobStartYear_2"]}`);
                setValue("currencySalary_2", userData["currencySalary_2"]);

                getForexData("fx_2", dayjs(userData["date"]).format("YYYY-MM-DD"), userData["currencySalary_2"]);

                setValue("calMonthSalary_2", calculateMonthlyAmount(userData["salaryFreq_2"], userData["salary_2"]));
                // setValue("coApplicantData.coApplicantSalary", userData[468] === "Annually" ? Number(userData[467].replace(/,/g, "")) / 12 : userData[467]);
                setValue("ccLimit_2", userData["ccLimit_2"]);
            }

            // Get the updated values from the form and deep copy cause getValue give readonly values
            const updatedValues = JSON.parse(JSON.stringify(getValues()));

            // Dispatch the updated form values to the Redux store
            dispatch(setFormData(updatedValues));
        }
    }, [formFieldsData, dispatch]);

    const debounceUpdate = useRef(
        debounce((values: BrokerNoteFormData) => {
            dispatch(setFormData(values));
        }, 300)
    ).current;

    const getForexData = (fiedName: any, requestDate: string, requestCurrency: string, type = "raw") => {
        axios
            .get(`https://odin-forex.vercel.app/api/convert?from=AUD&to=${requestCurrency}&date=${requestDate}&amount=1`)
            .then(res => {
                console.log("currency", res.data);
                if (type === "raw") {
                    setValue(fiedName, `AUD/${requestCurrency} = ${res.data.result}`);
                } else {
                    setValue(fiedName, res.data.result);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const hasCoApplicant = watch("hasCoApplicant");
    const ccLimit1 = watch("ccLimit1");
    const cardFXConversion1 = watch("cardFXConversion1");
    const cardFXConversion2 = watch("cardFXConversion2");

    const ccLimit2 = watch("ccLimit2");

    const curentCCLimit1 = watch("curentCCLimit1");
    const curentCCLimit2 = watch("curentCCLimit2");

    const cardCurrency1 = watch("cardCurrency1");

    const cardCurrency2 = watch("cardCurrency2");

    useEffect(() => {
        if (cardCurrency1) {
            if (cardCurrency1 === "AUD") {
                setValue("cardFXConversion1", 1);
            } else {
                getForexData("cardFXConversion1", dayjs().format("YYYY-MM-DD"), cardCurrency1 as string, "default");
            }
        }
    }, [cardCurrency1]);

    useEffect(() => {
        if (cardCurrency1 && cardCurrency2) {
            if (cardCurrency1 === "AUD") {
                setValue("cardFXConversion2", 1);
            } else {
                getForexData("cardFXConversion2", dayjs().format("YYYY-MM-DD"), cardCurrency2 as string, "default");
            }
        }
    }, [cardCurrency2]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // Create a deep copy of the form data
            const updatedData = JSON.parse(JSON.stringify(value));

            // Dispatch the form values to the Redux store
            dispatch(setFormData(updatedData));
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (cardFXConversion1) {
            const calcValue = convertToNumber(ccLimit1 ?? "");
            setValue("curentCCLimit1", (calcValue / cardFXConversion1).toFixed(2));
        }
    }, [cardFXConversion1]);

    useEffect(() => {
        if (cardFXConversion2) {
            const calcValue = convertToNumber(ccLimit2 ?? "");
            setValue("curentCCLimit2", (calcValue / cardFXConversion2).toFixed(2));
        }
    }, [cardFXConversion2]);

    useEffect(() => {
        if (curentCCLimit1 && curentCCLimit2) {
            setValue("ccTotalLimit", convertToNumber(curentCCLimit1) + convertToNumber(curentCCLimit2));
        }
    }, [curentCCLimit1, curentCCLimit2]);

    const onSubmit: SubmitHandler<BrokerNoteFormData> = data => {
        console.log("form submit", data);
        // Create a deep copy of the form data
        const updatedData = JSON.parse(JSON.stringify(data));

        // Dispatch the form values to the Redux store
        dispatch(setFormData(updatedData));
        handleFormSubmit();
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["1"]}
                        items={[{ key: "1", label: "Processor Detail", children: <ProcessorForm control={control} errors={errors} /> }]}
                    />
                </div>
                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["1"]}
                        items={[{ key: "1", label: "Applicant Detail", children: <BasicDetailForm control={control} errors={errors} /> }]}
                    />
                </div>
                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["11"]}
                        items={[{ key: "11", label: "Loan Detail", children: <LoanDetailForm control={control} errors={errors} /> }]}
                    />
                </div>
                <Row>
                    <Col xs={24} md={24}>
                        <div className="form-control">
                            <CheckboxField name="hasCoApplicant" control={control} label="Has Co-Applicant" error={errors.hasCoApplicant?.message} />
                        </div>
                    </Col>
                </Row>

                {hasCoApplicant && (
                    <div style={{ marginBottom: "1rem" }}>
                        <Collapse
                            defaultActiveKey={["2"]}
                            items={[{ key: "2", label: "Co Applicant Detail", children: <CoApplicantForm control={control} errors={errors} /> }]}
                        />
                    </div>
                )}

                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["3"]}
                        items={[{ key: "3", label: "Applicant Occupation", children: <OccupationForm control={control} errors={errors} /> }]}
                    />
                </div>

                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["4"]}
                        items={[{ key: "4", label: "Family Details", children: <FamilyDetailsForm control={control} errors={errors} /> }]}
                    />
                </div>

                <div style={{ marginBottom: "1rem" }}>
                    <Collapse
                        defaultActiveKey={["5"]}
                        items={[
                            {
                                key: "5",
                                label: "Card Details",
                                children: <CardDetailForm hasCoApplicant={hasCoApplicant} control={control} errors={errors} />
                            }
                        ]}
                    />
                </div>

                <Row>
                    <Col xs={24} md={24}>
                        <Button type="primary" style={{ display: "none" }} htmlType="submit" ref={submitRef}>
                            Generate
                        </Button>
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default BrokerNoteForm;
