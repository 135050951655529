
export const CASH_OUT_INVOLVED_OPTIONS = [
    { value: "Assist with the property purchase transaction", label: "Assist with the property purchase transaction" },
    { value: "Non-structural renovation of existing properties", label: "Non-structural renovation of existing properties" },
    { value: "To cash buy future Australian property outright", label: "To cash buy future Australian property outright" },
]

export const LOAN_STRUCTURE_OPTIONS = [
    {value:"standalone", label:"standalone"},
    {value:"cross-collateralised", label:"cross-collateralised"},
    {value:"multiple securities", label:"multiple securities"}
]

export const DEAL_TYPE = [
    {value:"purchase", label:"Purchase"},
    {value:"refinance", label:"Refinance"},
]

export const SALARY_FREQUENCY = [
    {value:"annually", label:"Annually"},
    {value:"weekly", label:"Weekly"},
    {value:"fortnightly", label:"Fortnightly"},
]
export const OTHER_LOANS = [
    {value: "no", label: "No"},
    {value: "one", label: "One"},
    {value: "two", label: "Two"},
    {value: "three", label: "Three"},
    {value: "four", label: "Four"},
    {value: "five", label: "Five"}
]