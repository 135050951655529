import * as yup from "yup";
import { SalaryFrequency } from "../types";

export const commonFormSchema = yup.object().shape({ 
    dealName: yup.string().required("Deal Name is required"),
    dealType: yup.string().required("Deal Type is required"),


    brokerPrimaryContact: yup.string().nullable().notRequired(),
    brokerSecondaryContact: yup.string().nullable().notRequired(),
    
    contactEmail: yup.string().email("Enter a valid email address."),
    contactFullName: yup.string(),
    contactPhone: yup.string(),
    contactCitizenship: yup.string(),
    contactResidency: yup.string().notRequired(),

    hasCoApplicant: yup.boolean(),
    
    secondaryContactEmail: yup.string().email("Enter a valid email address."),
    secondaryContactFullName: yup.string(),
    secondaryContactPhone: yup.string(),
    secondaryContactCitizenship: yup.string(),
    secondaryContactResidency: yup.string(),    

    websiteTrackingId: yup.string(),
    formId: yup.string(),
    fullName: yup.string(),
    email: yup.string().email("Enter a valid email address."),
    phone: yup.string(),
    citizenship: yup.string(),

    residency: yup.string(),
    countryCode: yup.string(),

    occupation: yup.string(),
    employment: yup.string(),
    employer: yup.string(),
    since: yup.string(),
    currency: yup.string(),
    fx: yup.string(),

    salaryFreq: yup.string(),
    salary: yup.string(),
    calMonthSalary: yup.string(),

    deduction: yup.number(),
    allowances: yup.string(),
    allowances_2: yup.string(),
    allowanceFreq: yup.string(),
    allowanceFreq_2: yup.string(),
    commission: yup.string(),
    commFreq: yup.string(),
    commission_2: yup.string(),
    commFreq_2: yup.string(),
    bonusThis: yup.string(),
    bonusLast: yup.string(),
    bonusThis_2: yup.string(),
    bonusLast_2: yup.string(),
    anyOtherLoan: yup.string(),
    anyOtherLoan_2: yup.string(),
    osProperties: yup.string(),
    anyLiabilities: yup.string(), //*TODO: full logic not completed 
    detailsOfDebt: yup.string(),
    date: yup.string(),

    pricing: yup.string(),
    interestRate: yup.number(),
    valuation: yup.string(),
    livingStatus: yup.string(),
    mortgage: yup.string(),
    rentalExpense: yup.string(),
    maritalStatus: yup.string(),
    kids: yup.string(),
    // kidsAge: yup.mixed().oneOf([yup.number(), yup.string()], "please enter a valid age"),
    kidsAge: yup.string(),
    privateEducation: yup.string(),
    purpose: yup.string(),
    purposeEquity: yup.string(),
    lvr: yup.number(),
    security: yup.string(),
    aipRentalyiel: yup.number(),
    apiRent: yup.number(),
    ccTotalLimit: yup.number(),
    reduceLimit: yup.string(),

    ccNewLimit: yup.number(),
    lender: yup.string(),
    existingCustomer: yup.string(),
    propertyValue: yup.number(),
    loanAmount: yup.number(),
    loanTerm: yup.number(),
    product: yup.string(),

    //card details
    ccLimit1: yup.string(),
    cardCurrency1: yup.string(),
    cardFXConversion1: yup.number(),
    curentCCLimit1: yup.string(),

    //Co Applicant Details
    coApplicationStatus: yup.boolean(),
    fullName_2: yup.string(),
    firstName_2: yup.string(),
    lastName_2: yup.string(),
    phone_2: yup.string(),
    citizenship_2: yup.string(),
    countryResidence_2: yup.string(),
    occupation_2: yup.string(),
    employment_2: yup.string(),
    jobStartMonth_2: yup.string(),
    jobStartYear_2: yup.string(),
    currencySalary_2: yup.string(),
    since_2: yup.string(),
    fx_2: yup.string(),
    fxConversation_2: yup.number(),
    
    salary_2: yup.string(),
    salaryFreq_2: yup.string(),
    calMonthSalary_2: yup.string(),

    ccLimit_2: yup.string(),
    residency_2: yup.string(),
    ccLimit2: yup.string(),
    cardCurrency2: yup.string(),
    cardFXConversion2: yup.number(),
    curentCCLimit2: yup.string(),

    // calculations
    calcAllowance: yup.string(), 
    calcAllowance2: yup.string(),
    calcCommission: yup.string(),
    calcCommission2: yup.string(),
    
});

export const RANGE_DATA = {
    lowerRange: {
        0: "",
        1: "350000",
        2: "500000",
        3: "700000",
        4: "1000000",
        5: "1500000",
        6: "2000000",
        7: "3000000"
    },
    upperRange: {
        0: "350000",
        1: "499999",
        2: "699999",
        3: "999999",
        4: "1499999",
        5: "1999999",
        6: "2999999",
        7: ""
    },
    rentalYield: {
        0: "6",
        1: "5.5",
        2: "5",
        3: "4.5",
        4: "4",
        5: "3.5",
        6: "3",
        7: "2.5"
    }
};

export const calculateMonthlyAmount = (frequency: SalaryFrequency, amount: string) => {
    let lFrequency = frequency.toLocaleLowerCase();
    let nAmount = parseInt(amount.replace(/,/g, ""));
    let monthlyAmount;

    if (lFrequency === "annually") {
        monthlyAmount = nAmount / 12;
    } else if (lFrequency === "weekly") {
        monthlyAmount = (nAmount * 52) / 12;
    } else if (lFrequency === "fortnightly") {
        monthlyAmount = (nAmount * 26) / 12;
    } else {
        monthlyAmount = nAmount;
    }

    return monthlyAmount.toFixed(2).toString();
};
