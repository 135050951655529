// const BACKEND_URI = import.meta.env.VITE_BACKEND_AI_URL;
// const BACKEND_URI = "https://app-backend-gbcrboqdvs2n4.azurewebsites.net"
const BACKEND_URI = "";

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, Config, Feedback, ApiResponse } from "./models";
import { useLogin, appServicesToken } from "../authConfig";

export function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (useLogin && appServicesToken == null) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }

    return headers;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}
// In progress. NEED CHANGE request format for ChatAppRequest
export async function generateApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/generate`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function configApi(idToken: string | undefined): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    return (await response.json()) as Config;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: { ...getHeaders(idToken), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });
}

// export function getCitationFilePath(citation: string): string {
//     console.log("getCitationFilePath")
//     const encodedCitation = encodeURIComponent(citation);
//     return `${BACKEND_URI}/content/${encodedCitation}`;
//     // return `${BACKEND_URI}/content/${citation}`;
// }

export function getCitationFilePath(citation: string): string {
    // Assuming the page information is always at the end of the citation string in the format "#page=XX"
    let [filePath, page] = citation.split("#page=");

    // Encode the file path
    const encodedFilePath = encodeURIComponent(filePath);
    // console.log("api.ts getCitationFilePath", citation, filePath, page);
    // console.log("api.ts encodedFilePath", encodedFilePath);

    // Construct the URL with the page number as a query parameter (if it exists)
    const urlWithPage = page ? `${BACKEND_URI}/content/${encodedFilePath}?page=${page}` : `${BACKEND_URI}/content/${encodedFilePath}`;
    // console.log("full url with page:");

    // console.log(urlWithPage);

    return urlWithPage;
}

export async function appendFeedbackToSheet(request: Feedback, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/feedback`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
    // Handle the response as needed
}

//     const parsedResponse: ChatAppResponseOrError = await response.json();
//     if (response.status > 299 || !response.ok) {
//         throw Error(parsedResponse.error || "Unknown error");
//     }

//     return parsedResponse as ChatAppResponse;

// Function to fetch documents by entity using POST method
export async function fetchDocumentsByEntity(entity: string): Promise<ApiResponse<any>> {
    // console.log("fetchDocumentsByEntity", BACKEND_URI)
    try {
        const response = await fetch(`${BACKEND_URI}/lender/${entity}`, {
            method: "GET"
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const res = await response.json();

        // Assuming data.documents exists and contains the list of documents
        if (res.success && res.data) {
            res.data = res.data.map((doc: any) => {
                const urlParts = doc.url.split("/");
                const lastSegment = urlParts[urlParts.length - 1];

                // Encode the last segment of the original URL
                // const encodedFilePath = encodeURIComponent(lastSegment);

                // Construct the new URL
                const newUrl = `${BACKEND_URI}/content/${lastSegment}`;

                // // Modify the URL for each document
                // const encodedFilePath = encodeURIComponent(doc.url);
                // const newUrl = `${BACKEND_URI}/content/${encodedFilePath}`;
                return { ...doc, url: newUrl }; // return a new object with the modified URL
            });
        }
        return res;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
        return {
            success: false,
            statusCode: 500,
            data: "Error"
        };
    }
}
