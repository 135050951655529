// Ensure imports are correct
import { RouteConfig } from "@/types";
import lazyLoad from "../utils/lazyload.util";

// Lazy-loaded components
const Chat = lazyLoad(() => import("@/pages/chat/Chat"));
const Ask = lazyLoad(() => import("@/pages/ask/Ask"));
const Discovery = lazyLoad(() => import("@/pages/discovery/Discovery"));
const Opportunity = lazyLoad(() => import("@/pages/opportunity"));
const Pricing = lazyLoad(() => import("@/pages/pricing/Pricing"));
const NoPage = lazyLoad(() => import("@/pages/NoPage"));
const LoginPage = lazyLoad(() => import("@/pages/auth"));

export const routesConfig: RouteConfig[] = [
    {
        path: "/",
        element: "Layout", // This should be a React component, not a string.
        protected: true, //
        children: [
            { path: "", element: Chat, label: "Chat" },
            { path: "qa", element: Ask, label: "Ask" },
            { path: "discovery", element: Discovery, label: "Discovery" },
            {
                path: "deals/:requestId?",
                element: Opportunity,
                label: "Deals"
            },
            { path: "pricing", element: Pricing, label: "Pricing" },
            { path: "*", element: NoPage, label: "404 Not Found" }
        ]
    },
    {
        path: "/auth",
        element: LoginPage,
        label: "Login"
    }
];
