import { Col, Row } from "antd";
import { FC, useMemo, useState } from "react";
import { getEmojiFlag, TCountryCode } from "countries-list";
import { InputField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import SelectField from "@/components/Form/SelectField";
import { DefaultOptionsType } from "@/types";
import axios from "axios";

const CARD_CURRENCY = ["HKD", "SGD", "USD", "GBP", "JPY", "AED", "AUD"];
const generateCardCurrencyOptions = (currencies: string[]): DefaultOptionsType[] => {
    return currencies.map(currency => ({
        label: (
            <div style={{ display: "flex", alignItems: "center" }}>
                <span>{getEmojiFlag(currency as TCountryCode)}</span> &nbsp; {currency}
            </div>
        ),
        value: currency
    }));
};

type CardDetailFormProps = FormPropsType & {
    hasCoApplicant: boolean | undefined;
};

const CardDetailForm: FC<CardDetailFormProps> = ({ control, errors, hasCoApplicant }) => {
    const cardCurrency1Options = useMemo(() => generateCardCurrencyOptions(CARD_CURRENCY), []);

    return (
        <>
            <Row gutter={12}>
                <Col xs={24} md={12}>
                    <InputField label="Credit Card Limit" name="ccLimit1" control={control} error={errors.ccLimit1?.message ?? ""} />
                </Col>

                <Col xs={24} md={12}>
                    <SelectField
                        options={cardCurrency1Options}
                        label="Card Currency"
                        name="cardCurrency1"
                        control={control}
                        error={errors.cardCurrency1?.message ?? ""}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <InputField label="FX Conversion Rate" name="cardFXConversion1" control={control} error={errors.cardFXConversion1?.message ?? ""} />
                </Col>
                <Col xs={24} md={12}>
                    <InputField label="Current Credit Card Limits(AUD)" name="curentCCLimit1" control={control} error={errors.curentCCLimit1?.message ?? ""} />
                </Col>
            </Row>
            {hasCoApplicant && (
                <Row gutter={12}>
                    <Col xs={24} md={12}>
                        <InputField label="Co Applicant Credit Card Limit" name="ccLimit2" control={control} error={errors.ccLimit2?.message ?? ""} />
                    </Col>

                    <Col xs={24} md={12}>
                        <SelectField
                            options={cardCurrency1Options}
                            label="Co Applicant Card Currency"
                            name="cardCurrency2"
                            control={control}
                            error={errors.cardCurrency2?.message ?? ""}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputField
                            label="Co Applicant FX Conversion Rate"
                            name="cardFXConversion2"
                            control={control}
                            error={errors.cardFXConversion2?.message ?? ""}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <InputField
                            label="Co Applicant Current Credit Card Limits(AUD)"
                            name="curentCCLimit2"
                            control={control}
                            error={errors.curentCCLimit2?.message ?? ""}
                        />
                    </Col>
                </Row>
            )}
            <Row gutter={12}>
                <Col xs={24} md={12}>
                    <InputField
                        variant="filled"
                        label="CC total limit"
                        name="ccTotalLimit"
                        prefix="$"
                        control={control}
                        error={errors.ccTotalLimit?.message ?? ""}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <InputField variant="filled" label="CC New Limit" name="ccNewLimit" control={control} error={errors.ccNewLimit?.message ?? ""} />
                </Col>
            </Row>
        </>
    );
};

export default CardDetailForm;
