import { ComplianceLoanCircumstancesType, ComplianceLoanObjectiveType, ComplianceLoanPrioritisedType, ComplianceLoanRequirementsType, ComplianceLoanStructureType } from "../types";

export const generateLoanObjectiveUserContent = ({ purpose, cashOutReason, LVR }: ComplianceLoanObjectiveType): string => {
    return `"<!--start-input-->
- Purpose: ${purpose}
- Cash-out reason: ${cashOutReason}
- LVR: ${LVR}
<!--end-input-->
<!--start-output-->"`;
};

export const generateLoanRequirementUserContent = ({ citizenship, residency, purpose }: ComplianceLoanRequirementsType): string => {
    return `"<!--start-input-->
- Citizenship: ${citizenship}
- Residency: ${residency}
- Purpose: ${purpose}
<!--end-input-->
<!--start-output-->"`;
};

export const generateLoanCircumstancesUserContent = (data:ComplianceLoanCircumstancesType):string => {
    return `"<!--start-input-->
- Nationality1: ${data.nationality1}
- Residency1: ${data.residency1}
- Employment1: ${data.employment1}
- Occupation1: ${data.occupation1}
- Nationality2: ${data.nationality2}
- Residency2: ${data.residency2}
- Employment2: ${data.employment2}
- Occupation2: ${data.occupation2}
- Salary1: ${data.salary1}
- Allowance1: ${data.allowance1}
- Average Commission1: ${data.averageCommission1}
- Bonus This year1: ${data.bonusThisYear1}
- Salary2: ${data.salary2}
- Allowance2: ${data.allowance2}
- Average Commission2: ${data.averageCommission2}
- Bonus This year2: ${data.bonusThisYear2}
- Any Debt outstanding: ${data.anyDebtOutstanding}
- Credit card limit: ${data.creditCardLimit}
- Marital: ${data.maritial}
- Kids Number: ${data.kidsNumber}
- Kids Age: ${data.kidsAge}
- Living Status: ${data.livingStatus}
- Mortgage Owner occupied: ${data.mortgageOwnerOccupied}
- Rental expense: ${data.rentalExpense}
- Purpose: ${data.purpose}
- Purpose equity: ${data.purposeEquity}
<!--end-input-->
<!--start-output-->"`
};

export const generateLoanPrioritisedUserContent = (data: ComplianceLoanPrioritisedType): string => {
    return `"<!--start-input-->
- Rate Type: ${data.rateType}
- Repayment Type: ${data.repaymentType}
- Repayment Frequency: ${data.repaymentFrequency}
- Offset: ${data.offset}
<!--end-input-->
<!--start-output-->"`
};

export const generateLoanStructureUserContent = (data: ComplianceLoanStructureType): string => {
    return `"<!--start-input-->
- Rate Type: ${data.rateType}
- Repayment Type: ${data.repaymentType}
- Repayment Frequency: ${data.repaymentFrequency}
- Loan Structure: ${data.loanStructure}
<!--end-input-->
<!--start-output-->"`
};
