export const LENDER_OPTIONS = [
    { value: "AAA Finance", label: "AAA Finance" },
    { value: "ANZ Bank", label: "ANZ Bank" },
    { value: "boq", label: "BOQ" },
    { value: "CBA", label: "CBA" },
    { value: "Heritage", label: "Heritage" },
    { value: "St. George Bank", label: "St. George Bank" },
    { value: "Macquarie Bank", label: "Macquarie Bank" },
    { value: "HSBC", label: "HSBC" },
    { value: "NAB", label: "NAB" },
    { value: "Suncorp", label: "Suncorp" },
    { value: "Westpac", label: "Westpac" },
    { value: "BC Invest", label: "BC Invest" },
    { value: "Brighten", label: "Brighten" },
    { value: "LaTrobe", label: "LaTrobe" },
    { value: "MEZY", label: "MEZY" },
    { value: "MA Money", label: "MA Money" }
];

export const CURRENCY = [
    {
        value: "HKD",
        label: "Hong Kong Dollar"
    },
    {
        value: "SGD",
        label: "Singapore Dollar"
    },
    {
        value: "USD",
        label: "US Dollar"
    },
    {
        value: "GBP",
        label: "Pound sterling"
    },
    {
        value: "JPY",
        label: "Japanese yen"
    },
    {
        value: "AED",
        label: "United Arab Emirates dirhams "
    },
    {
        value: "AUD",
        label: "Australian Dollar"
    }
];
