import { theme, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";

const { Text } = Typography;
const { useToken } = theme;

const PDFHeading = () => {
    const { token } = useToken();
    return (
        <div style={{ textAlign: "center", position: "relative", marginBottom: "1rem" }}>
            <Title level={3} style={{ color: token.colorPrimary, margin: "0 0 2px" }}>
                BROKER NOTES
            </Title>
            <div>Australian Mortgages for Expats & Overseas Residents</div>
            <div style={{ position: "absolute", right: 0, top: 0 }}>
                <Paragraph style={{ color: token.colorPrimary, margin: 0 }}>{dayjs().format("DD/MM/YYYY")}</Paragraph>
            </div>
        </div>
    );
};

export default PDFHeading;
