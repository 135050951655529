import { message } from 'antd';
export const displayError = (error: any): void => {
  console.log('error', error)
    const errorData = error?.data?.error;
    console.log(errorData)
    if (errorData) {
      const { details, message: errorMessage } = errorData;
  
      if (details) {
        Object.keys(details).forEach(key => {
          message.error(`${key} - ${details[key].join(', ')}`);
        });
      } else if (errorMessage) {
        message.error(errorMessage);
      } else {
        message.error(errorData);
      }
    } else {
      message.error('An unexpected error occurred');
    }
  };

  export const displaySuccess = (successMsg: any = "Successfull"): void => {
    message.success(successMsg)
  }

  export const displayWarning = (warningMsg: any = "Warning"): void => {
    message.warning(warningMsg)
  };
  export const displayInfo = (infoMsg: any = "Info"): void => {
    message.info(infoMsg)
  }

export const dateFormat = "YYYY-MM-DD";

export const splitName = (fullName: string) => {
    const parts = fullName.trim().split(/\s+/);
    if (parts.length === 1) {
        return { firstName: parts[0], lastName: "" };
    }
    const firstName = parts.shift();
    const lastName = parts.join(" ");

    return { firstName, lastName };
};

export const isObject = (variable: any) => {
    return variable !== null && typeof variable === "object";
};

export const deepCopy = <T>(obj: T): T => {
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    const newObj: any = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
        newObj[key] = deepCopy(obj[key]);
    }

    return newObj;
};

// src/utils/debounce.ts
export const debounce = <T extends (...args: any[]) => void>(func: T, delay: number): ((...args: Parameters<T>) => void) => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    return (...args: Parameters<T>) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const convertToNumber = (value:string) => {
    return parseFloat(value.replace(/,/g, ''));
}