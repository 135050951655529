import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { Col, ConfigProvider, Row, theme } from "antd";
import * as sanitizeHtml from "sanitize-html";
// components
import { NoteHeading } from "@/components/UI";
import PDFHeading from "./pdf/PDFHeading";
import ProcessorContactDetail from "./pdf/ProcessorContactDetail";
import ApplicantDetail from "./pdf/ApplicantDetail";
import LoanDetail from "./pdf/LoanDetail";
import AIBrokerNote from "./pdf/AIBrokerNote";
import FundsDetail from "./pdf/FundsDetail";

import { BrokerNoteFormData, ContentNameType } from "../types";

const { useToken } = theme;

interface BrokerPDFProps {
    allValues: Partial<BrokerNoteFormData>;

    loanPurposeData: string;
    loanPurposeDataLoading: boolean;

    applicationOverviewData: string;
    applicationOverviewDataLoading: boolean;

    livingCDData: string;
    livingCDDataLoading: boolean;

    empIncomeData: string;
    empIncomeDataLoading: boolean;

    commitData: string;
    commitDataLoading: boolean;

    otherData: string;
    otherDataLoading: boolean;

    mitiData: string;
    mitiDataLoading: boolean;

    handleEditMode: (id: ContentNameType) => void;
}
const BrokerPDF2: FC<BrokerPDFProps> = ({
    allValues,
    loanPurposeData,
    loanPurposeDataLoading,
    applicationOverviewData,
    applicationOverviewDataLoading,
    livingCDData,
    livingCDDataLoading,
    empIncomeData,
    empIncomeDataLoading,
    commitData,
    commitDataLoading,
    otherData,
    otherDataLoading,
    mitiData,
    mitiDataLoading,
    handleEditMode
}) => {
    // const safeHtml = sanitizeHtml(loanPurposeData, {
    //     allowedTags: ["b", "i", "em", "strong", "p", "br"],
    //     allowedAttributes: {}
    // });
    const { token } = useToken();

    return (
        <div style={{ height: "100%", fontFamily: "Poppins" }}>
            <Row>
                <Col xs={24} md={24}>
                    <PDFHeading />
                    <ProcessorContactDetail allValues={allValues} />
                    <ApplicantDetail allValues={allValues} />
                    <LoanDetail allValues={allValues} />

                    <div>
                        <NoteHeading title="Broker Notes" />
                        <AIBrokerNote
                            handleEditMode={handleEditMode}
                            title="Overview / Loan Purpose"
                            loading={loanPurposeDataLoading}
                            aiData={loanPurposeData}
                            id="loanPurpose"
                        />
                        <AIBrokerNote
                            handleEditMode={handleEditMode}
                            title="Applicant Overview"
                            loading={applicationOverviewDataLoading}
                            aiData={applicationOverviewData}
                            id="applicationOverview"
                        />
                        <AIBrokerNote
                            handleEditMode={handleEditMode}
                            title="Living Conditions"
                            loading={livingCDDataLoading}
                            aiData={livingCDData}
                            id="livingCondition"
                        />
                        <AIBrokerNote
                            handleEditMode={handleEditMode}
                            title="Employment / Income"
                            loading={empIncomeDataLoading}
                            aiData={empIncomeData}
                            id="employmentIncome"
                        />
                        <AIBrokerNote handleEditMode={handleEditMode} title="Commitments" loading={commitDataLoading} aiData={commitData} id="commitments" />
                        <FundsDetail allValues={allValues} />
                        <AIBrokerNote handleEditMode={handleEditMode} title="Other" loading={otherDataLoading} aiData={otherData} id="others" />
                        <AIBrokerNote handleEditMode={handleEditMode} title="Mitigants" loading={mitiDataLoading} aiData={mitiData} id="mitigant" />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default BrokerPDF2;
