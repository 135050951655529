import { Col, Row } from "antd";
import { FC } from "react";
import { InputField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import SelectField from "@/components/Form/SelectField";

export interface CoutriesOptionType {
    value: string;
    label: JSX.Element | React.ReactNode;
}

const OpportunityInfoForm: FC<FormPropsType> = ({ control, errors }) => {

    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <InputField  label="Deal Name" name="dealName" control={control} error={errors.dealName?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
               
                <SelectField
                    name="dealType"
                    control={control}
                    label="Deal Type"
                    placeholder="Deal Type"
                    error={errors.dealType?.message}
                    options={[
                        { label: "purchase", value: "purchase" },
                        { label: "refinance", value: "refinance" }
                    ]}
                />
            </Col>
            
        </Row>
    );
};

export default OpportunityInfoForm;
