import { Navigate, Route, Routes } from "react-router-dom";

import ANZ from "./anz";
import CBA from "./cba";
import Macquarie from "./macquarie";
import BankWest from "./bankwest";
import Heritage from "./heritage";
import BOQ from "./boq";
import HSBC from "./hsbc";
import MAMoney from "./mamoney";
import NAB from "./nab";
import SGB from "./sgb";
import Adelaide from "./adelaide";
import Advantedge from "./advantedge";
import AFG from "./afg";
import AMP from "./amp";
import BankAu from "./bankau";
import Bluestone from "./bluestone";
import BOS from "./bos";
import Firstmac from "./firstmac";
import Gateway from "./gateway";
import ING from "./ing";
import LaTrobe from "./latrobe";
import Liberty from "./liberty";
import MEBank from "./mebank";
import MyState from "./mystate";
import Pepper from "./pepper";
import Resimac from "./resimac";
import Suncorp from "./suncorp";
import Thinktank from "./thinktank";
import TMB from "./tmb";
import UBank from "./ubank";
import Virgin from "./virgin";
import Westpac from "./westpac";

export const LenderRoutes = () => {
    return (
        <Routes>
            <Route path="anz" element={<ANZ />} />
            <Route path="cba" element={<CBA />} />
            <Route path="macquarie" element={<Macquarie />} />
            <Route path="heritage" element={<Heritage />} />
            <Route path="bankwest" element={<BankWest />} />
            <Route path="boq" element={<BOQ />} />
            <Route path="hsbc" element={<HSBC />} />
            <Route path="mamoney" element={<MAMoney />} />
            <Route path="nab" element={<NAB />} />
            <Route path="sgb" element={<SGB />} />
            <Route path="ing" element={<ING />} />
            <Route path="westpac" element={<Westpac />} />
            <Route path="adelaide" element={<Adelaide />} />
            <Route path="advantedge" element={<Advantedge />} />
            <Route path="afg" element={<AFG />} />
            <Route path="amp" element={<AMP />} />
            <Route path="bankau" element={<BankAu />} />
            <Route path="bluestone" element={<Bluestone />} />
            <Route path="bos" element={<BOS />} />
            <Route path="firstmac" element={<Firstmac />} />
            <Route path="gateway" element={<Gateway />} />
            <Route path="latrobe" element={<LaTrobe />} />
            <Route path="liberty" element={<Liberty />} />
            <Route path="mebank" element={<MEBank />} />
            <Route path="mystate" element={<MyState />} />
            <Route path="pepper" element={<Pepper />} />
            <Route path="resimac" element={<Resimac />} />
            <Route path="suncorp" element={<Suncorp />} />
            <Route path="thinktank" element={<Thinktank />} />
            <Route path="tmb" element={<TMB />} />
            <Route path="ubank" element={<UBank />} />
            <Route path="virgin" element={<Virgin />} />
            <Route path="*" element={<Navigate to="." />} />
        </Routes>
    );
};
