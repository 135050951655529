// src/store/slices/formSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as dayjs from "dayjs";
import { BrokerNoteFormData } from "@/pages/opportunity/brokerNotes/types";

const initialState: BrokerNoteFormData = {
    processorContactDetails: {
        processorName: "",
        processorEmail: "",
        processorPhone: ""
    },
    brokerContactDetails: {
        brokerName: "",
        brokerEmail:"",
        brokerPhone: ""
    },
    websiteTrackingId: "",
    formId: "",
    fullName: "",
    email: "",
    phone: "",
    citizenship: "",
    residency: "",
    occupation: "",
    employment: "",
    employer: "",
    since: "",
    currency: "",
    fx: "",
    salary: "",
    deduction: 0,
    allowances: 0,
    commission: 0,
    bonusThis: "",
    bonusLast: "",
    anyLiabilities: "",
    //@ts-ignore
    date: "",
    pricing: "Not yet requested",
    interestRate: 0,
    valuation: "N/A, AIP",
    livingStatus: "",
    mortgage: "",
    rentalExpense: "",
    maritalStatus: "",
    kids: "",
    kidsAge: "",
    privateEducation: "",
    purpose: "Investment Property Purchase",
    purposeEquity: "renovation",
    lvr: 80,
    security: "TBA",
    aipRentalyiel: 0,
    apiRent: 0,
    ccTotalLimit: 0,
    reduceLimit: "maybe",
    ccNewLimit: 0,
    lender: "BOQ",
    existingCustomer: "no",
    propertyValue: 1000000,
    loanAmount: 800000,
    loanTerm: 30,
    product: "SVR Variable Package",
    ccLimit1: "",
    cardCurrency1: "",
    cardFXConversion1: 0,
    curentCCLimit1: "",
    hasCoApplicant: false
};

const formSlice = createSlice({
    name: "brokerNoteFormSlice",
    initialState,
    reducers: {
        setFormData: (state, action: PayloadAction<Partial<BrokerNoteFormData>>) => {
            return { ...state, ...action.payload };
        }
    }
});

export const { setFormData } = formSlice.actions;

export default formSlice.reducer;
