import { Col, Drawer, Row } from "antd";
import { FC } from "react";
import MenuList from "../MenuList";
import { LOGO } from "@/components/UI";
import styles from "./MobileMenu.module.scss";
import { LoginButton } from "@/components/LoginButton";

interface MobileMenuProps {
    open: boolean;
    onClose: () => void;
}

const MobileMenu: FC<MobileMenuProps> = ({ open, onClose }) => {
    return (
        <Drawer placement="left" closable={false} onClose={onClose} open={open} width="80%">
            <div>
                <Row style={{ marginBottom: "1rem" }} gutter={24}>
                    <Col xs={12}>
                        <LOGO />
                    </Col>
                </Row>
                <MenuList onSelect={onClose} />
            </div>

            <div className={styles.authSection}>
                <LoginButton size="large" width="100%" />
            </div>
        </Drawer>
    );
};

export default MobileMenu;
