import { FC, useRef, RefObject, useEffect } from "react";
// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SelectField from "@/components/Form/SelectField";
import { InputField } from "@/components/Form";
import { Button } from "antd";


const needAnalysisFormSchema = yup.object().shape({
    documentIdentificationMethod: yup.string().required("Method of documenent identification is required"),
    clientInterviewMethod: yup.string().required("Method of client interview is required"),
    dateCreditGuide: yup.string(),
    dateInterview: yup.string(),
    estimatedSettlementDate: yup.string(),
    facilityAmount: yup.string(),
});
export type NeedAnalysisFormType = yup.InferType<typeof needAnalysisFormSchema>;

interface NeedAnalysisFormProps {
    handleFormSubmit: (data: NeedAnalysisFormType) => void;
    submitRef: RefObject<HTMLButtonElement>;
    setFormData?: (data: NeedAnalysisFormType) => void;
}
const NeedAnalysisForm:FC<NeedAnalysisFormProps> = ({handleFormSubmit, submitRef, setFormData}) => {
 
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(needAnalysisFormSchema),
        defaultValues: {
            documentIdentificationMethod:"Video over Internet",
            clientInterviewMethod:"Email",
            estimatedSettlementDate: "",
            facilityAmount: ""
        }
    });

     /**
     ** Passes the updated fields value to the parent component
     */
     useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // Create a deep copy of the form data
            const updatedData = JSON.parse(JSON.stringify(value));

            // Dispatch the form values to the Redux store
            if(setFormData) {
                setFormData(updatedData);
            }
            
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit: SubmitHandler<NeedAnalysisFormType> = data => {
        handleFormSubmit(data);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SelectField
                        name="documentIdentificationMethod"
                        control={control}
                        label="Method of Document Identification"
                        placeholder="Method of Document Identification"
                        error={errors.documentIdentificationMethod?.message}
                        formDirection="row"
                        options={[
                            { label: "Face to Face", value: "Face to Face" },
                            { label: "Certified Persons", value: "Certified Persons" },
                            { label: "Branch", value: "Branch" },
                            { label: "Video over Internet", value: "Video over Internet" }
                        ]}
                    />
                <SelectField
                        name="clientInterviewMethod"
                        control={control}
                        label="Method of Client Interview"
                        placeholder="Method of Client Interview"
                        error={errors.clientInterviewMethod?.message}
                        formDirection="row"
                        options={[
                            { label: "Face to Face", value: "Face to Face" },
                            { label: "Video", value: "Video" },
                            { label: "Telephone", value: "Telephone" },
                            { label: "Email", value: "Email" }
                        ]}
                    />  
                    <InputField 
                        label="Date Credit Guide Was Provided (Within 90 days of assessment)" 
                        name="dateCreditGuide" 
                        control={control} 
                        error={errors.dateCreditGuide?.message ?? ""} 
                        formDirection="row"
                    />  
                    <InputField 
                        label="Estimated Settlement Date (Double-Click)" 
                        name="estimatedSettlementDate" 
                        control={control} 
                        error={errors.estimatedSettlementDate?.message ?? ""} 
                        formDirection="row"
                    /> 
                    <InputField 
                        label="Facility Amount (AU$)" 
                        name="facilityAmount" 
                        control={control} 
                        error={errors.facilityAmount?.message ?? ""} 
                        formDirection="row"
                    /> 
                    
                    <Button type="primary" style={{ display: "none" }} htmlType="submit" ref={submitRef}>
                        Form Submit
                    </Button>
            </form>

            
        </div>
    );
};

export default NeedAnalysisForm;
