import { Col, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { countries, getEmojiFlag, TCountryCode } from "countries-list";
import { InputField, SelectField } from "@/components/Form";
import { FormPropsType } from "../../types";

export interface CoutriesOptionType {
    value: string;
    label: JSX.Element | React.ReactNode;
}

const BasicDetailForm: FC<FormPropsType> = ({ control, errors }) => {
    const [countryOptions, setCountryOptions] = useState<CoutriesOptionType[]>([]);

    useEffect(() => {
        let autoCompleteOptions = [];
        for (let country in countries) {
            autoCompleteOptions.push({
                value: country,
                label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{getEmojiFlag(country as TCountryCode)}</span> &nbsp; {countries[country as TCountryCode]?.name}
                    </div>
                )
            });
        }
        setCountryOptions(autoCompleteOptions);
    }, []);

    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <InputField label="Full name" name="fullName" control={control} error={errors.fullName?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Email" name="email" control={control} error={errors.email?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Phone" name="phone" control={control} error={errors.phone?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Citizenship" name="citizenship" control={control} error={errors.citizenship?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField options={countryOptions} label="Current Country" name="residency" control={control} error={errors.residency?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                {/* <InputField label="Request Date" name="date" control={control} error={errors.date?.message ?? ""} /> */}
            </Col>
        </Row>
    );
};

export default BasicDetailForm;
