import { CSSProperties, FC } from "react";
import Title from "antd/es/typography/Title";
import styles from "../../BrokerNote.module.scss";

interface AsideNoteHeadingProps {
    title: string;
    style?: CSSProperties;
}

const AsideNoteheading: FC<AsideNoteHeadingProps> = ({ title, style }) => {
    return (
        <div className={styles.contentHeading} style={{ ...style }}>
            <Title level={5} style={{ margin: 0 }}>
                {title}
            </Title>
        </div>
    );
};

export default AsideNoteheading;
