import { Button, Col, Row, theme } from "antd";
import { TextAlignLeftFilled } from "@fluentui/react-icons";
import { LOGO } from "@/components/UI";
import { LoginButton } from "@/components/LoginButton";
import styles from "./MobileHeader.module.scss";
import { FC } from "react";
import { useTheme } from "@/contexts/themeContext";

const { useToken } = theme;

interface MobileHeaderProps {
    getMenuState: () => void;
}
const MobileHeader: FC<MobileHeaderProps> = ({ getMenuState }) => {
    const { token } = useToken();
    const { theme } = useTheme();

    const toggleMenu = () => {
        getMenuState();
    };

    return (
        <header
            className={styles.mobileHeader}
            style={{
                borderBottomColor: token.colorBorder,
                background: theme === "dark" ? "#010717" : "#fff"
            }}
        >
            <Row align="middle" gutter={10}>
                <Col xs={5}>
                    <Button onClick={toggleMenu} type="text" icon={<TextAlignLeftFilled fontSize={22} />} />
                </Col>
                <Col xs={14}>
                    <LOGO />
                </Col>
                <Col xs={5} style={{ textAlign: "right" }}>
                    <LoginButton />
                </Col>
            </Row>
        </header>
    );
};

export default MobileHeader;
