// src/store/slices/formSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommonFormDataTypes } from "@/pages/opportunity/types";

const initialState: CommonFormDataTypes = {
    dealName: "",
    dealType:"",
    brokerPrimaryContact: "",
    brokerSecondaryContact:"",
    websiteTrackingId: "",
    formId: "",
    fullName: "",
    email: "",
    phone: "",
    citizenship: "",
    residency: "",
    occupation: "",
    employment: "",
    employer: "",
    since: "",
    currency: "",
    fx: "",
    salary: "",
    deduction: 0,
    allowances: "0",
    commission: "0",
    bonusThis: "",
    bonusLast: "",
    anyLiabilities: "",
    //@ts-ignore
    date: "",
    pricing: "",
    interestRate: 0,
    valuation: "P",
    livingStatus: "",
    mortgage: "",
    rentalExpense: "",
    maritalStatus: "",
    kids: "",
    kidsAge: "",
    privateEducation: "",
    purpose: "",
    purposeEquity: "",
    lvr: 0,
    security: "",
    aipRentalyiel: 0,
    apiRent: 0,
    ccTotalLimit: 0,
    reduceLimit: "",
    ccNewLimit: 0,
    lender: "",
    existingCustomer: "",
    propertyValue: 0,
    loanAmount: 0,
    loanTerm: 0,
    product: "",
    ccLimit1: "",
    cardCurrency1: "",
    cardFXConversion1: 0,
    curentCCLimit1: "",
    hasCoApplicant: false,
    coApplicationStatus: false
};

const opportunityFormSlice = createSlice({
    name: "opportunityFormSlice",
    initialState,
    reducers: {
        setOpportunityFormData: (state, action: PayloadAction<Partial<CommonFormDataTypes>>) => {
            return { ...state, ...action.payload };
        }
    }
});

export const { setOpportunityFormData } = opportunityFormSlice.actions;

export default opportunityFormSlice.reducer;
