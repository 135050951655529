// src/store/apis/forexApi.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const forexApi = createApi({
  reducerPath: 'forexApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://odin-forex.vercel.app/api',
  }),
  endpoints: (builder) => ({
    getForexData: builder.query({
      query: ({ requestCurrency, requestDate }) => ({
        url: '/convert',
        params: {
          from: 'AUD',
          to: requestCurrency,
          date: requestDate,
          amount: 1,
        },
      }),
      transformResponse: (response: any) => response.result,
    }),
  }),
});

export const { useGetForexDataQuery } = forexApi;