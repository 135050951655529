import { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation,  useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Flex, Typography, Button, Drawer } from "antd";
import { Scrollbars } from "rc-scrollbars";

// Redux state
import { RootState } from "@/store";
import { useFetchPromptDataMutation } from "@/store/apis/chatGptApi";
import { useGetBrokerServiceHistoryAllQuery } from "@/store/apis/brokerNoteApi";
import { setSystemPrompt } from "@/store/slices/brokerNote/systemPromptSlice";
import { setComplianceFormData } from "@/store/slices/compliance/complianceFormSlice";

// Components
import BrokerPDF from "@/pages/opportunity/brokerNotes/components/BrokerPDF";
import { Container } from "@/components/Layout";
import BrokerNoteForm from "@/pages/opportunity/brokerNotes/components/BrokerNoteForm";
import AsideContainer from "@/components/Layout/AsideContainer";
import OpportunitySideContent from "@/pages/opportunity/components/OpportunitySideContent";

import { ContentNameType } from "@/pages/opportunity/brokerNotes/types";
import HistorySidebar from "../../components/HistorySidebar";
import SharedForm, { FormFieldDataType, SharedFormMethods } from "@/pages/opportunity/components/SharedForm";
import { BrokerContactType, CommonFormDataTypes, ContactType, DealInfoType } from "@/pages/opportunity/types";
import ComplianceNotePDF from "../../components/ComplianceNotePdf";
import { useGenerateComplianceNoteMutation } from "@/store/apis/complianceNoteApi";
import { ComplianceFieldTypes } from "../../types";
import { PreferredLoanFormType } from "../../components/ComplianceNotePdf/PreferredLoanForm";
import { LoanScenarioFormType } from "../../components/ComplianceNotePdf/LoanScenarioForm";
import { DealType, FormActionType } from "@/types";
import DealTab from "@/pages/opportunity/components/DealTab";
import { DealRequestType, useGetOpportunityDetailQuery, useUpdateDealMutation } from "@/store/apis/opportunityApi";
import { displayError, displaySuccess } from "@/utils/common.utils";
import { NeedAnalysisFormType } from "../../components/ComplianceNotePdf/NeedAnalysisForm";


const { Title } = Typography;

const HeaderRow = ({ children }: { children: ReactNode }) => {
    return (
        <Flex style={{ marginBottom: "2rem" }} gap={10} justify="space-between">
            {children}
        </Flex>
    );
};
const ComplianceNotes = () => {
    const { requestId } = useParams<{ requestId?: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");

    const dispatch = useDispatch();

    const complianceFormData = useSelector((state: RootState) => state.complianceForm);

    const reportTemplateRef = useRef<HTMLDivElement>(null);
    const [formFieldsData, setFormFieldsData] = useState<FormFieldDataType | null>(null);
    const [formActionType, setFormActionType] = useState<FormActionType | null>(null);
    const [showOpportunityModal, setShowOpportunityModal] = useState(false);

    const sharedFormRef = useRef<SharedFormMethods>(null);
    const submitRef = useRef<HTMLButtonElement>(null);
    const submitLoanPrioritiseRef = useRef<HTMLButtonElement>(null);
    const submitLoanScenarioRef = useRef<HTMLButtonElement>(null);
    const submitNeedAnalysisRef = useRef<HTMLButtonElement>(null);

    

    const [generateType, setGenerateType] = useState<'single' | 'all'>('single');
    const [activeId, setActiveId] = useState<ComplianceFieldTypes | null>(null);
    const [needAnalysisFormData, setNeedAnalysisFormData] = useState<NeedAnalysisFormType | null>(null);
    const [preferredFormData, setPreferredFormData] = useState<PreferredLoanFormType | null>(null);
    const [loanScenarioFormData, setLoanScenarioFormData] = useState<LoanScenarioFormType | null>(null);

    const [loanObjectiveData, setLoanObjectiveData] = useState("");
    const [loanRequirementsData, setLoanRequirementsData] = useState("");
    const [loanCircumstancesData, setLoanCircumstancesData] = useState("");
    const [loanFinancialAwarenessData, setLoanFinancialAwarenessData] = useState("");
    const [loanPrioritisedData, setLoanPrioritisedData] = useState("");
    const [lenderLoanData, setLenderLoanData] = useState("");
    const [loanStructureData, setLoanStructureData] = useState("");

    const [dealFieldData, setDealFieldData] = useState<DealInfoType | null>(null);
    const [brokerContactData, setBrokerContactData] = useState<BrokerContactType | null>(null);
    const [contactData, setContactData] = useState<ContactType | null>(null);
    const [secondaryContactData, setSecondaryContactData] = useState<ContactType | null>(null);


    const {
        data: opportunityData,
        error: opportunityDataError,
        isLoading: opportunityDataLoading
    } = useGetOpportunityDetailQuery(requestId!, { skip: !requestId, refetchOnMountOrArgChange: true });
    const [updateDeal, {isLoading: updateDealLoading, data: updateDealData, error: updateDealError, isSuccess: updateDealSuccess}] = useUpdateDealMutation();

    const [fetchLoanObjectives, {isLoading: loanObjectivesLoading, isError: loanObjectivesError }] = useGenerateComplianceNoteMutation();
    const [fetchLoanRequirements, {isLoading: loanRequirementsLoading, isError: loanRequirementsError }] = useGenerateComplianceNoteMutation();
    const [fetchLoanCircumstance, {isLoading: loanCircumstancesLoading, isError: loanCircumstancesError }] = useGenerateComplianceNoteMutation();
    const [fetchLoanFinancialAwareness, {isLoading: loanFinancialAwarenessLoading, isError: loanFinancialAwarenessError }] = useGenerateComplianceNoteMutation();
    const [fetchLoanPrioritised, {isLoading: loanPrioritisedLoading, isError: loanPrioritisedError }] = useGenerateComplianceNoteMutation();
    const [fetchLenderLoan, {isLoading: lenderLoanLoading, isError: lenderLoanError }] = useGenerateComplianceNoteMutation();
    const [fetchLoanStructure, {isLoading: loanStructureLoading, isError: loanStructureError }] = useGenerateComplianceNoteMutation();

    

    useEffect(() => {
        if (requestId) {
            setFormActionType("update");
          } else {
            setFormActionType("create");
          }
    },[location, requestId])

    useEffect(() => {
        if (source === "deals" && opportunityData && !opportunityDataLoading && requestId) {
            setBrokerContactData(null)
            setContactData(null)
            setSecondaryContactData(null)
            setDealFieldData({
                dealName: opportunityData?.name,
                dealType: opportunityData?.type
            });

            setBrokerContactData({
                primaryContact: opportunityData?.primary_processor,
                secondaryContact: opportunityData?.secondary_processor
            });

            if(opportunityData.primary_contact) {
                setContactData({
                    name: opportunityData?.primary_contact.name,
                    email: opportunityData?.primary_contact.email,
                    phone: opportunityData?.primary_contact.phone,
                    residency: opportunityData?.primary_contact.residency,
                });
            } 
            if(opportunityData.secondary_contact) {
                setSecondaryContactData({
                    name: opportunityData?.secondary_contact.name,
                    email: opportunityData?.secondary_contact.email,
                    phone: opportunityData?.secondary_contact.phone,
                    residency: opportunityData?.secondary_contact.residency,
                });
            }
            
            if( opportunityData?.json_data && Object.keys(opportunityData?.json_data).length > 2) {
                setFormFieldsData(opportunityData?.json_data );
            }

        } 
    }, [source, opportunityData, opportunityDataLoading, requestId]);


    const handleSetFormData = (data: CommonFormDataTypes) => {
        dispatch(setComplianceFormData(data))
    };

    const generateLoanObjective = () => {
        fetchLoanObjectives({type:"loan_objectives", data:{...complianceFormData, ...needAnalysisFormData, ...loanScenarioFormData} })
        .unwrap()
        .then((response) => {
            setLoanObjectiveData(response.choices[0].message.content)
        });
    }

    const generateLoanRequirement  = () => {
        fetchLoanRequirements({type:"loan_requirements", data:complianceFormData})
        .unwrap()
        .then((response) => {
            setLoanRequirementsData(response.choices[0].message.content)
        });
    }

    const generateLoanCircumstances  = () => {
        fetchLoanCircumstance({type:"loan_circumstances", data:complianceFormData})
        .unwrap()
        .then((response) => {
            setLoanCircumstancesData(response.choices[0].message.content)
        });
    }

    const generateLoanFinancialAwareness  = () => {
        fetchLoanFinancialAwareness({type:"loan_financial_awareness", data:complianceFormData})
        .unwrap()
        .then((response) => {
            setLoanFinancialAwarenessData(response.choices[0].message.content)
        });
    }

    const generateLoanPrioritised = (preferredLoanData: PreferredLoanFormType) => {
        fetchLoanPrioritised({type:"loan_prioritised", data:{...complianceFormData, ...preferredLoanData}})
        .unwrap()
        .then((response) => {
            setLoanPrioritisedData(response.choices[0].message.content)
        });
    }

    const generateLenderLoan  = () => {
        fetchLenderLoan({type:"lender_loan", data:complianceFormData})
        .unwrap()
        .then((response) => {
            setLenderLoanData(response.choices[0].message.content)
        });
    }

    const generateLoanStructure  = () => {
        console.log("generateLoanStructure")
        fetchLoanStructure({type:"loan_structure", data:{...complianceFormData, ...preferredFormData, ...loanScenarioFormData} })
        .unwrap()
        .then((response) => {
            setLoanStructureData(response.choices[0].message.content)
        });
    }

    const handleGenerateAIContent  = async (id: ComplianceFieldTypes) => {
        sharedFormRef.current?.getAllUpdatedValues();
        console.log("handleGenerateAIContent", id)
        setActiveId(id);
        let isValid = true;
        if(sharedFormRef.current) {
            isValid = await sharedFormRef.current.triggerValidation();
        }

        console.log('isValid', isValid)
        if(isValid) {
            switch (id) {
                case "loan_objectives":
                    generateLoanObjective()
                    break;
                case "loan_requirements":
                    generateLoanRequirement()
                    break;
                case "loan_circumstances":
                    generateLoanCircumstances()
                    break;
                case "loan_financial_awareness":
                    generateLoanFinancialAwareness()
                    break;
                case "loan_prioritised":
                    submitLoanPrioritiseForm()
                    break;
                case "lender_loan":
                    generateLenderLoan()
                    break;
                case "loan_structure":
                    submitLoanPrioritiseForm()
                    submitLoanScenarioForm()
                    break;
                default:
                    console.log("Invalid field type");
                    break;
            }
        }
        
        
    }

    const submitLoanPrioritiseForm = () => {
        if (submitLoanPrioritiseRef.current) {
            submitLoanPrioritiseRef.current.click();
        }
    }

    const submitLoanScenarioForm = () => {
    
        if (submitLoanScenarioRef.current) {
            submitLoanScenarioRef.current.click();
        }
    }

    const handleNeedAnalysisForm = (data:NeedAnalysisFormType) => {

    }
    const updateNeedAnalysisFormData = (data:NeedAnalysisFormType) => {
        setNeedAnalysisFormData((prevData) => ({...prevData,...data}));
    }

    const handlePreferredLoanForm = (data: PreferredLoanFormType) => {
        console.log("handlePreferredLoanForm", data);
        setPreferredFormData(data);
    }

    const handleLoanScenarioForm = (data: LoanScenarioFormType) => {
        console.log("handleLoanScenarioForm", data);
        setLoanScenarioFormData(data);
    }

    const updateLoanScenarioFormData = (data: LoanScenarioFormType) => {
        setLoanScenarioFormData((prevData) => ({...prevData, ...data}));
    }

    useEffect(() => {
        if(preferredFormData && activeId === "loan_prioritised") {
            generateLoanPrioritised(preferredFormData)
        }
        if(preferredFormData && loanScenarioFormData && activeId === "loan_structure") {
            generateLoanStructure()
        }
    },[preferredFormData, loanScenarioFormData])

    const haldeSelectedDeal = (selectedId: number, listType: 'self' | 'all') => {
        navigate(`/note-generation/compliance-notes/${selectedId}?source=deals`, { replace: true });
        setFormActionType('update')
        setShowOpportunityModal(false);
    };

    const handleAllValueFromForm = (data: CommonFormDataTypes) => {
        console.log('handleAllValueFromForm', data)
        dispatch(setComplianceFormData(data))
    }

    const handleSaveOpportunity = () => {

        if (submitRef.current) {
            submitRef.current.click();
        }        
    };

    const handleFormSubmit = ({formData}:{formData: CommonFormDataTypes}) => {

        console.log(formData)
    
        let payload:DealRequestType&{requestId?:string} = {
            name: formData?.dealName,
            type: formData?.dealType as DealType,
            website_tracking_id: formData?.websiteTrackingId ?? '',
            primary_processor: formData?.brokerPrimaryContact ?? '',
            secondary_processor: formData?.brokerSecondaryContact ?? '',
            
            json_data: formData,
            requestId: requestId
        }

        if(formData?.contactEmail) {
            payload.primary_contact = {
                email: formData?.contactEmail,
                name: formData?.contactFullName,
                phone: formData?.contactPhone,
                citizenship: formData?.contactCitizenship,
                residency: formData?.contactResidency
            }
        }
        if(formData?.secondaryContactEmail) {
            payload.secondary_contact = {
                email: formData?.secondaryContactEmail,
                name: formData?.secondaryContactFullName,
                phone: formData?.secondaryContactPhone,
                citizenship: formData?.secondaryContactCitizenship,
                residency: formData?.secondaryContactResidency
            }
        }
        
        updateDeal(payload)
            .unwrap()
            .then((response) => {
               displaySuccess("Successfully updated deal")
            })
            .catch(error => {
               displayError(error)
            });
    };

    return (
        <>
        <AsideContainer sideContent={<HistorySidebar />}>
            <Container fullWidth={true} align="left" style={{ padding: "0 2rem" }}>
                <Row>
                    <Col xs={24} md={24}>
                        {formActionType === 'update' && <Flex gap={24} justify="flex-end" style={{ marginBottom: "2rem" }}>
                            <Button type="primary" onClick={handleSaveOpportunity} loading={opportunityDataLoading}>Save</Button>
                            <Button type="primary">Publish to HubSpot</Button>
                        </Flex>}
                    </Col>
                </Row>
                <Row gutter={32}>
                    <Col xs={24} md={12}>
                        <HeaderRow>
                            <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
                                Opportunity Inputs
                            </Title>
                            <Flex gap={10}>
                                {/* <Button type="primary">New Opportunity</Button> */}
                                <Button type="primary"  onClick={() => setShowOpportunityModal(true)}> Open Opportunity</Button>
                            </Flex>
                        </HeaderRow>
                        <SharedForm
                                handleFormSubmit={handleFormSubmit}
                                submitRef={submitRef}
                                formFieldsData={formFieldsData}
                                initialValues={complianceFormData}
                                setFormData={handleSetFormData}

                                formActionType={formActionType}
                                dealData={dealFieldData}
                                brokerContact={brokerContactData}
                                ref={sharedFormRef}
                                contactData={contactData}
                                secondaryContactData={secondaryContactData}
                                getAllValueFromForm={handleAllValueFromForm}
                                componentName="Compliance"
                            />
                    </Col>
                    <Col xs={24} md={12}>
                        <HeaderRow>
                            <Title level={4} style={{ marginTop: 0, marginBottom: 0 }}>
                                Compliance Notes
                            </Title>
                            <Flex gap={10}>
                                <Button type="primary">Print</Button>
                                <Button type="primary">Generate All</Button>
                            </Flex>
                        </HeaderRow>
                        <Scrollbars style={{ height: "calc(100vh - 240px)" }}>
                            <div style={{ paddingLeft: "30px", paddingRight: "20px" }} ref={reportTemplateRef}>
                                <ComplianceNotePDF 
                                    allValues={complianceFormData} 
                                    generateAIContent={handleGenerateAIContent}
                                    
                                    handleNeedAnalysisForm={handleNeedAnalysisForm}
                                    submitNeedAnalysisRef={submitNeedAnalysisRef}
                                    setNeedAnalysisFormData={updateNeedAnalysisFormData}

                                    loanObjectivesLoading={loanObjectivesLoading}
                                    loanObjectiveData={loanObjectiveData}

                                    loanRequirementsData={loanRequirementsData}
                                    loanRequirementsLoading={loanRequirementsLoading}

                                    loanCircumstancesData={loanCircumstancesData}
                                    loanCircumstancesLoading={loanCircumstancesLoading}

                                    loanFinancialAwarenessData={loanFinancialAwarenessData}
                                    loanFinancialAwarenessLoading={loanFinancialAwarenessLoading}

                                    submitLoanPrioritiseRef={submitLoanPrioritiseRef}

                                    handlePreferredLoanForm={handlePreferredLoanForm}
                                    loanPrioritisedLoading={loanPrioritisedLoading}
                                    loanPrioritisedData={loanPrioritisedData}

                                    submitLoanScenarioRef={submitLoanScenarioRef}
                                    handleLoanScenarioForm={handleLoanScenarioForm}
                                    setLoanScenarioFormData={updateLoanScenarioFormData}

                                    lenderLoanData={lenderLoanData}
                                    lenderLoanLoading={lenderLoanLoading}

                                    loanStructureData={loanStructureData}
                                    loanStructureLoading={loanStructureLoading}
                                />
                            </div>
                        </Scrollbars>
                    </Col>
                </Row>
            </Container>
        </AsideContainer>
        <Drawer
            title="Deal List"
            placement="right"
            width={1020}
            onClose={() => setShowOpportunityModal(false)}
            open={showOpportunityModal}>
                {showOpportunityModal && <DealTab haldeSelectedDeal={haldeSelectedDeal} /> }
        </Drawer>
        </>
    );
};

export default ComplianceNotes;
