
import { ComplianceFieldTypes, ComplianceFormType, ComplianceLoanCircumstancesType, ComplianceLoanObjectiveType, ComplianceLoanPrioritisedType, ComplianceLoanRequirementsType, ComplianceLoanStructureType } from "../types";
import { generateLoanObjectiveUserContent, generateLoanRequirementUserContent, generateLoanCircumstancesUserContent, generateLoanPrioritisedUserContent, generateLoanStructureUserContent } from "../constant";


const mapLoanObjectiveData = (data: ComplianceFormType): ComplianceLoanObjectiveType => ({
    purpose: data.purpose ?? "",
    cashOutReason: data.cashOutInvolved ?? "",
    LVR: data.lvr ?? 0
});

const mapLoanRequirementData = (data: ComplianceFormType): ComplianceLoanRequirementsType => ({
    citizenship: data.contactCitizenship ?? "",
    residency: data.contactResidency ?? "",
    purpose: data.purpose ?? "",
});

const mapLoanCircumstancesData = (data: ComplianceFormType): ComplianceLoanCircumstancesType => ({
    nationality1: data.contactCitizenship ?? "",
    residency1: data.contactResidency ?? "",
    employment1: data.employment ?? "",
    occupation1: data.occupation ?? "",
    nationality2: data.secondaryContactCitizenship  ?? "",// *TODO: field validation not done for secondary contact
    residency2: data.secondaryContactResidency ?? "",
    employment2: data.employment_2 ?? "",
    occupation2: data.occupation_2 ?? "",
    salary1: data.calMonthSalary ?? '0', // * calculated base salary
    allowance1: data.calcAllowance ?? "0", 
    averageCommission1: data.calcCommission ?? "0", 
    bonusThisYear1: data.bonusThis ?? "0",
    salary2: data.calMonthSalary_2 ?? "",
    allowance2: data.calcAllowance2 ?? "0",
    averageCommission2:  data.calcCommission2 ?? "0",
    bonusThisYear2: data?.bonusThis_2 ?? "0",
    anyDebtOutstanding: data?.anyLiabilities ?? "",
    creditCardLimit: data.curentCCLimit1 ?? "0",
    maritial: data.maritalStatus ?? "",
    kidsNumber: data.kids ?? "",
    kidsAge: data.kidsAge ?? "",
    livingStatus: data.livingStatus ?? "",
    mortgageOwnerOccupied: "",
    rentalExpense: data.rentalExpense ?? "0",
    purpose: data.purpose ?? "",
    purposeEquity: data.purposeEquity ?? "",
});

const mapLoanPrioritisedData = (data: ComplianceFormType): ComplianceLoanPrioritisedType => ({
   rateType: data.rateType ?? "",
   repaymentType: data.repaymentType?? "",
   repaymentFrequency: data.repaymentFrequency?? "",
   offset: data.offset?? "",
});

const mapLoanStructureData = (data: ComplianceFormType): ComplianceLoanStructureType => ({
    rateType: data.rateType?? "",
    repaymentType: data.repaymentType?? "",
    repaymentFrequency: data.repaymentFrequency?? "",
    loanStructure: data.loanStructure?? ""
})

export const getComplianceUserContent = (data: ComplianceFormType, complianceField: ComplianceFieldTypes) => {
    console.log("complianceField", data)
    switch (complianceField) {
        case "loan_objectives":
            const loanObjectivesData = mapLoanObjectiveData(data);
            return generateLoanObjectiveUserContent(loanObjectivesData);
        case "loan_requirements":
            const loanRequirementsData = mapLoanRequirementData(data);
            return generateLoanRequirementUserContent(loanRequirementsData);
        case "loan_circumstances":
            const loanCircumstancesData = mapLoanCircumstancesData(data);
            return generateLoanCircumstancesUserContent(loanCircumstancesData);
        case "loan_financial_awareness": 
            return "";
        case "loan_prioritised":
            const loanPrioritisedData = mapLoanPrioritisedData(data);
            return generateLoanPrioritisedUserContent(loanPrioritisedData);
        case "lender_loan":
            return "";
        case "loan_structure":
            const loanStructureData = mapLoanStructureData(data);
            return generateLoanStructureUserContent(loanStructureData);            
        default:
                throw new Error(`Unknown content name: ${complianceField}`);
    }

}