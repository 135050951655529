import { useTheme } from "@/contexts/themeContext";
import LOGO_IMG from "@/assets/logo.png";
import LOGO_IMG_2 from "@/assets/logo_2.png";
import SMALL_LOGO from "@/assets/copilot.png";

import styles from "./Logo.module.scss";
import { CSSProperties, FC, useMemo } from "react";

interface LogoProps {
    style?: CSSProperties;
}

const LOGO: FC<LogoProps> = ({ style }) => {
    const { theme, sidebarCollapseState } = useTheme();

    const imgSrc = useMemo(() => {
        if (sidebarCollapseState) {
            return SMALL_LOGO;
        }
        return theme === "light" ? LOGO_IMG : LOGO_IMG_2;
    }, [sidebarCollapseState, theme]);

    return (
        <div className={styles["logo"]} style={style}>
            <img src={imgSrc} alt="Logo" />
        </div>
    );
};

export default LOGO;
