import { FC, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { InputField, SelectField } from "@/components/Form";
import { countries, getEmojiFlag, TCountryCode } from "countries-list";
import { FormPropsType } from "../../../types";
import { CountryOptionType } from "@/pages/opportunity/brokerNotes/types";



interface CoApplicantFormProps {
    contacts?: Record<string, any>;
    contactsLoading?: boolean;
}


const CoApplicantForm: FC<FormPropsType & CoApplicantFormProps> = ({ control, errors, contacts, contactsLoading  }) => {
    const [countryOptions, setCountryOptions] = useState<CountryOptionType[]>([]);

    useEffect(() => {
        let autoCompleteOptions = [];
        for (let country in countries) {
            autoCompleteOptions.push({
                value: country,
                label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{getEmojiFlag(country as TCountryCode)}</span> &nbsp; {countries[country as TCountryCode]?.name}
                    </div>
                )
            });
        }
        setCountryOptions(autoCompleteOptions);
    }, []);

    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <SelectField
                    name="secondaryContactEmail"
                    control={control}
                    label="Email"
                    placeholder="Email"
                    error={errors.secondaryContactEmail?.message}
                    options={contacts as any ?? []}
                    disabled={contactsLoading}
                    fieldNames={{label:"email", value:"email"}}
                    showSearch={true}
                />

               
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Full name" name="secondaryContactFullName" control={control} error={errors.secondaryContactFullName?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Phone" name="secondaryContactPhone" control={control} error={errors.secondaryContactPhone?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Citizenship" name="secondaryContactCitizenship" control={control} error={errors.secondaryContactCitizenship?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
             <SelectField options={countryOptions} label="Residency" name="secondaryContactResidency" control={control} error={errors.secondaryContactResidency?.message ?? ""} />
            </Col>
        </Row>
    );
};

export default CoApplicantForm;
