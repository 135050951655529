import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL, CHAT_GPT_API_KEY } from "@/config";
import { BrokerNoteFormData, ContentNameType, MitigantsType } from "@/pages/opportunity/brokerNotes/types";
import { SystemPromptType } from "@/pages/opportunity/brokerNotes/components/promptModal";
import { getUserContentData } from "@/pages/opportunity/brokerNotes/utils";
import { generateMitigantsUserContent } from "@/pages/opportunity/brokerNotes/constant/userContent";

// Define the base query with the necessary headers
const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: headers => {
        headers.set("Authorization", `Bearer ${CHAT_GPT_API_KEY}`);
        headers.set("Accept", "application/json");
        return headers;
    }
});

export const chatGptApi = createApi({
    reducerPath: "chatGptApi",
    baseQuery,
    endpoints: builder => ({
        fetchPromptData: builder.mutation<string, { type: ContentNameType; systemPrompt: SystemPromptType; data: BrokerNoteFormData | MitigantsType }>({
            query: ({ type, systemPrompt, data }) => {
                let userContent;
                if (type !== "mitigant") {
                    userContent = getUserContentData(data as BrokerNoteFormData, type);
                } else {
                    userContent = generateMitigantsUserContent(data as MitigantsType);
                }

                const postData = {
                    model: "gpt-3.5-turbo",
                    messages: [
                        {
                            role: "system",
                            content: systemPrompt[type]
                        },
                        {
                            role: "user",
                            content: userContent
                        }
                    ],
                    temperature: 0.7
                };

                return {
                    url: "/v1/chat/completions",
                    method: "POST",
                    body: postData
                };
            },
            transformResponse: (response: any) => {
                return response.choices[0].message.content.replace(/\n/g, "<br>");
            }
        })
    })
});

export const { useFetchPromptDataMutation } = chatGptApi;
