export const loanPurpose = [
    {
        label: "Investment Property Purchase",
        value: "Investment Property Purchase"
    },
    {
        label: "Investment Property Pre-approval",
        value: "Investment Property Pre-approval"
    },
    {
        label: "Investment Property Refinance",
        value: "Investment Property Refinance"
    },
    {
        label: "Investment Property Refinance + Equity Release",
        value: "Investment Property Refinance + Equity Release"
    },
    {
        label: "Investment Property Equity Cash-out",
        value: "Investment Property Equity Cash-out"
    },
    {
        label: "Investment Vacant Land",
        value: "Investment Vacant Land"
    },
    {
        label: "Investment House & Land Construction",
        value: "Investment House & Land Construction"
    },
    {
        label: "Owner-occupier Property Purchase",
        value: "Owner-occupier Property Purchase"
    },
    {
        label: "Owner-occupier Property Pre-approval",
        value: "Owner-occupier Property Pre-approval"
    },
    {
        label: "Owner-occupier Property Refinance",
        value: "Owner-occupier Property Refinance"
    },
    {
        label: "Owner-occupier Property Refinance + Equity Release",
        value: "Owner-occupier Property Refinance + Equity Release"
    },
    {
        label: "Owner-occupier Property Equity Cash-out",
        value: "Owner-occupier Property Equity Cash-out"
    },
    {
        label: "Owner-occupier Vacant Land",
        value: "Owner-occupier Vacant Land"
    },
    {
        label: "Owner-Occupier House & Land Construction",
        value: "Owner-Occupier House & Land Construction"
    }
];
