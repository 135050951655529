import { InputField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import { Col, Row } from "antd";
import { FC } from "react";

const LoanDetailForm: FC<FormPropsType> = ({ control, errors }) => {
    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <InputField label="Property Value" name="propertyValue" control={control} error={errors.propertyValue?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Loan Amount" name="loanAmount" control={control} error={errors.loanAmount?.message ?? ""} />
            </Col>

            <Col xs={24} md={12}>
                <InputField label="Loan Term" name="loanTerm" control={control} error={errors.loanTerm?.message ?? ""} />
            </Col>
            <Col xs={24} md={12}>
                <InputField label="Product" name="product" control={control} error={errors.product?.message ?? ""} />
            </Col>
        </Row>
    );
};

export default LoanDetailForm;
