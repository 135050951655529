import * as yup from "yup";

const brokerNoteSchema = yup.object().shape({
    processorContactDetails: yup.object().shape({
        processorName: yup.string(),
        processorEmail: yup.string().email("Enter a valid email address."),
        processorPhone: yup.string()
    }),
    brokerContactDetails: yup.object().shape({
        brokerName: yup.string(),
        brokerEmail: yup.string().email("Enter a valid email address."),
        brokerPhone: yup.string()
    }),
    websiteTrackingId: yup.string(),
    formId: yup.string(),
    fullName: yup.string().required("Name is required"),
    email: yup.string().required("Email is required").email("Enter a valid email address."),
    phone: yup.string().required("phone number is required."),
    citizenship: yup.string().required("citizenhip is required"),
    residency: yup.string().required("residency is required"),

    occupation: yup.string().required("occupation is required"),
    employment: yup.string().required("employment is required"),
    employer: yup.string(),
    since: yup.string().required("starting date is required"),
    currency: yup.string().required("currency is required"),
    fx: yup.string(),
    salary: yup.string(),
    deduction: yup.number(),
    allowances: yup.number(),
    commission: yup.number(),
    bonusThis: yup.string(),
    bonusLast: yup.string(),
    anyLiabilities: yup.string(),
    detailsOfDebt: yup.string(),
    date: yup.string(),

    pricing: yup.string().required("Pricing is required"),
    interestRate: yup.number().required("Interest Rate is required"),
    valuation: yup.string().required("Valuation is required"),
    livingStatus: yup.string().required("Living Status is required"),
    mortgage: yup.string(),
    rentalExpense: yup.string(),
    maritalStatus: yup.string().required("Marital Status is required"),
    kids: yup.string().when("maritalStatus", {
        is: "married",
        then: schema => schema.required("How many kids do you have?"),
        otherwise: schema => schema
    }),
    // kidsAge: yup.mixed().oneOf([yup.number(), yup.string()], "please enter a valid age"),
    kidsAge: yup.string(),
    privateEducation: yup.string().required("Please mention your education"),
    purpose: yup.string().required("Please mention your purpose"),
    purposeEquity: yup.string(),
    lvr: yup.number().required("LVR is required"),
    security: yup.string().required("Please mention your security"),
    aipRentalyiel: yup.number().required("AIP Rental Yiel is required"),
    apiRent: yup.number().required("Mention your montly rent"),
    ccTotalLimit: yup.number().required("Total Limit is required"),
    reduceLimit: yup.string(),

    ccNewLimit: yup.number(),
    lender: yup.string().required("Lender is required"),
    existingCustomer: yup.string().required("Please mention."),
    propertyValue: yup.number().required("property Value is required"),
    loanAmount: yup.number().required("Please mention your loan amount"),
    loanTerm: yup.number().required("Loan Term is required"),
    product: yup.string().required("Product is required"),

    //card details
    ccLimit1: yup.string(),
    cardCurrency1: yup.string(),
    cardFXConversion1: yup.number(),
    curentCCLimit1: yup.string(),

    //Co Applicant Details
    hasCoApplicant: yup.boolean().required("Co-applicant status is required"),
    fullName_2: yup.string(),
    firstName_2: yup.string(),
    lastName_2: yup.string(),
    phone_2: yup.string(),
    citizenship_2: yup.string(),
    countryResidence_2: yup.string(),
    occupation_2: yup.string(),
    employment_2: yup.string(),
    jobStartMonth_2: yup.string(),
    jobStartYear_2: yup.string(),
    currencySalary_2: yup.string(),
    since_2: yup.string(),
    fx_2: yup.string(),
    fxConversation_2: yup.number(),
    calMonthSalary_2: yup.string(),
    ccLimit_2: yup.string(),
    residency_2: yup.string(),
    ccLimit2: yup.string(),
    cardCurrency2: yup.string(),
    cardFXConversion2: yup.number(),
    curentCCLimit2: yup.string()
});

export default brokerNoteSchema;
