import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";

import { CustomNavigationClient } from "./utils/NavigationClient";

// routes
import AppRouter from "./routes/AppRouter";

type AppProps = {
    pca: IPublicClientApplication;
};

const App: React.FC<AppProps> = ({ pca }) => {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);
   
    return (
        <MsalProvider instance={pca}>
            <AppRouter />
        </MsalProvider>
    );
};

export default App;
