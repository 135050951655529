import {
    generateLoanPurposeUserContent,
    generateApplicationOverview,
    generateLivingConditionUserContent,
    generateEmploymentIncomeUserContent,
    generateCommitmentsUserContent,
    generateOtherDetailsUserContent
} from "../constant/userContent";
import {
    ApplicantOverviewType,
    BrokerNoteFormData,
    LoanPurposeDetailsType,
    LivingConditionsType,
    EmploymentIncomeType,
    CommitmentsType,
    OtherDetailsType,
    ContentNameType,
    MitigantsType
} from "../types";

const mapLoanPurposeDetails = (data: BrokerNoteFormData): LoanPurposeDetailsType => ({
    name: data.fullName,
    name2: data.fullName_2 ?? "",
    LVRs: (data.lvr ?? 0) / 100,
    security: data.security,
    purpose: data.purpose,
    lender: data.lender,
    existingCustomer: data.existingCustomer === "no" ? "FALSE" : "TRUE",
    cashOutReason: data.purposeEquity ?? "",
    pricing: data.pricing,
    interestRate: data.interestRate,
    valuation: data.valuation
});

const mapApplicantOverviewDetails = (data: BrokerNoteFormData): ApplicantOverviewType => ({
    name: data.fullName,
    nationality: data.citizenship,
    residency: data.residency,
    employment: data.employment,
    occupation: data.occupation,
    employer: data.employer ?? "",
    jobStarted: data.since,
    currencyOfIncome: data.currency,
    name2: data.fullName_2 ?? "",
    nationality2: data.citizenship_2 ?? "",
    residency2: data.residency_2 ?? "",
    employment2: data.employment_2 ?? "",
    occupation2: data.occupation_2 ?? "",
    employer2: data.employment_2 ?? "",
    jobStarted2: data.since_2 ?? "",
    currencyOfIncome2: data.currencySalary_2 ?? ""
});

const mapLivingConditionDetails = (data: BrokerNoteFormData): LivingConditionsType => ({
    name1: data.fullName,
    name2: data.fullName_2 ?? "",
    maritalStatus: data.maritalStatus,
    residency: data.residency,
    kidsNumber: data.kids ?? "",
    kidsAge: data.kidsAge ?? "",
    privateEducation: data.privateEducation,
    livingStatus: data.livingStatus,
    mortgageOwnerOccupied: data.mortgage ?? "",
    rentalExpense: data.rentalExpense,
    rentalExpenseAUD: ""
});

const mapEmploymentIncomeDetails = (data: BrokerNoteFormData): EmploymentIncomeType => ({
    date: data.date ?? "",
    fxRateAUDUSD: data.fx ?? "",
    name1: data.fullName,
    currency1: data.currency,
    salary1: data.salary ?? '',
    deductions1: data.deduction ?? 0,
    allowance1: data.allowances ?? 0,
    averageCommission1: data.commission ?? 0,
    bonusThisYear1: data.bonusThis ?? 0,
    bonusLastYear1: data.bonusLast ?? 0,

    name2: data.fullName_2 ?? "",
    currency2: data.currencySalary_2 ?? "",
    salary2: data.calMonthSalary_2 ?? 0,
    deductions2:  0,
    allowance2:  0,
    averageCommission2:  0,
    bonusThisYear2:  0,
    bonusLastYear2: 0,

    australianRentalYield: data.aipRentalyiel / 100,
    australianRentalIncome: data.apiRent,
    lender: data.lender
});

const mapCommitmentsDetails = (data: BrokerNoteFormData): CommitmentsType => ({
    name1: data.fullName,
    name2: data.fullName_2 ?? "",
    anyDebtOutstanding: data.anyLiabilities ?? "",
    detailOfDebt: data.detailsOfDebt ?? "",
    creditCardLimit: data.ccTotalLimit ?? "",
    reduceLimit: data.reduceLimit ?? "",
    newCreditCardLimit: data.ccNewLimit
});

const mapOthersDetails = (data: BrokerNoteFormData): OtherDetailsType => ({
    name1: data.fullName,
    number1: data.phone,
    name2: data.fullName_2 ?? "",
    number2: "processor phone number"
});



/*
 GPT Generator
 */

export const getUserContentData = (data: BrokerNoteFormData, contentName: ContentNameType) => {
    switch (contentName) {
        case "loanPurpose":
            const loanPurposeDetails = mapLoanPurposeDetails(data);
            return generateLoanPurposeUserContent(loanPurposeDetails);
        case "applicationOverview":
            const applicantOverviewDetails = mapApplicantOverviewDetails(data);
            return generateApplicationOverview(applicantOverviewDetails);
        case "livingCondition":
            const livingConditionDetails = mapLivingConditionDetails(data);
            return generateLivingConditionUserContent(livingConditionDetails);
        case "employmentIncome":
            const employmentIncomeDetails = mapEmploymentIncomeDetails(data);
            return generateEmploymentIncomeUserContent(employmentIncomeDetails);
        case "commitments":
            const commitmentsDetails = mapCommitmentsDetails(data);
            return generateCommitmentsUserContent(commitmentsDetails);
        case "others":
            const othersDetails = mapOthersDetails(data);
            return generateOtherDetailsUserContent(othersDetails);
        default:
            throw new Error(`Unknown content name: ${contentName}`);
    }
};
