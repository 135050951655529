import { Col, Row, Typography } from "antd";
import { NoteHeading } from "@/components/UI";
import { FC } from "react";
import { BrokerNoteFormData } from "../../types";

const { Text } = Typography;

interface ProcessorContactDetailProps {
    allValues: Partial<BrokerNoteFormData>;
}
const ProcessorContactDetail: FC<ProcessorContactDetailProps> = ({ allValues }) => {
    return (
        <div>
            <NoteHeading title="Processor Contact Details" />
            <Row gutter={8}>
                <Col xs={24} md={6}></Col>
                <Col xs={24} md={6}>
                    <Text strong>Name</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text strong>Email</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text strong>Phone</Text>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col xs={24} md={6}>
                    <Text type="secondary">Processor</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text type="secondary">{allValues.processorContactDetails?.processorName}</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text type="secondary">{allValues.processorContactDetails?.processorEmail}</Text>
                </Col>
                <Col xs={24} md={6}>
                    <Text type="secondary">{allValues.processorContactDetails?.processorPhone}</Text>
                </Col>
            </Row>
        </div>
    );
};

export default ProcessorContactDetail;
