import { Col, Row, Grid } from "antd";
import { Example } from "./Example";

import styles from "./Example.module.scss";

const DEFAULT_EXAMPLES: string[] = [
    "For Commonwealth Bank, what is the retirement rule, and if my applicant is 63 years old, how does it apply?",
    "National Australia Bank when does a valuation is required for LMI?",
    "Macquarie Bank's Construction Loans, my LVR is more than 87%, can my loan be approved?",
    "Can Australia New Zealand Bank refund the premium I paid for LMI if I repaid the loan early?"
];

const GPT4V_EXAMPLES: string[] = [
    "Compare the impact of interest rates and GDP in financial markets.",
    "What is the expected trend for the S&P 500 index over the next five years? Compare it to the past S&P 500 performance",
    "Can you identify any correlation between oil prices and stock market trends?"
];

const { useBreakpoint } = Grid;

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    const { md } = useBreakpoint();

    const examples = useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES;
    const displayedExamples = md ? examples : examples.slice(0, 2);
    return (
        <>
            {/* <ul className={styles.examplesNavList}>
                {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                    <li key={i}>
                        <Example text={question} value={question} onClick={onExampleClicked} />
                    </li>
                ))}
            </ul> */}
            <div className={styles.listQuestionListWrapper}>
                <Row gutter={16}>
                    {displayedExamples.map((question, i) => (
                        <Col xs={24} md={12} key={i} style={{ marginBottom: "1rem" }}>
                            <Example text={question} value={question} onClick={onExampleClicked} />
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};
