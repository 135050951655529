import { theme } from "antd";
import Title from "antd/es/typography/Title";
import { CSSProperties, FC } from "react";

const { useToken } = theme;

interface NoteHeadingProps {
    title: string;
    style?: CSSProperties;
}

const NoteHeading: FC<NoteHeadingProps> = ({ title, style }) => {
    const { token } = useToken();
    return (
        <div style={{ background: token.colorPrimary, padding: "4px 1rem", marginBottom: "5px", ...style }}>
            <Title level={5} style={{ margin: 0, color: "#fff" }}>
                {title}
            </Title>
        </div>
    );
};

export default NoteHeading;
