import { Navigate, Route, Routes } from "react-router-dom";

import BrokerNotes from "./BrokerNotes";
import ComplianceNotes from "./ComplianceNotes";
import TemplatesPage from "./TemplatesPage";

export const NoteGenerationRoutes = () => {
    return (
        <Routes>
            <Route path="broker-notes/:id?" element={<BrokerNotes />} />
            <Route path="compliance-notes/:requestId?" element={<ComplianceNotes />} />
            <Route path="templates" element={<TemplatesPage />} />
            <Route path="*" element={<Navigate to="." />} />
        </Routes>
    );
};
