import { InputField } from "@/components/Form";
import { FormPropsType } from "../../types";
import { Col, Row } from "antd";
import { FC } from "react";

const ProcessorForm: FC<FormPropsType> = ({ control, errors }) => {
    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                {/* <InputField
                    label="Processor name"
                    name="processorContactDetails.processorName"
                    control={control}
                    error={errors.processorContactDetails?.processorName?.message ?? ""}
                /> */}
            </Col>

            <Col xs={24} md={12}>
                {/* <InputField
                    label="Processor Email"
                    name="processorContactDetails.processorEmail"
                    control={control}
                    error={errors.processorContactDetails?.processorEmail?.message ?? ""}
                /> */}
            </Col>
            <Col xs={24} md={12}>
                {/* <InputField
                    label="Processor Phone"
                    name="processorContactDetails.processorPhone"
                    control={control}
                    error={errors.processorContactDetails?.processorPhone?.message ?? ""}
                /> */}
            </Col>
        </Row>
    );
};

export default ProcessorForm;
