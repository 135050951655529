import { DeleteRegular } from "@fluentui/react-icons";
import { Button } from "antd";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const ClearChatButton = ({ disabled, onClick }: Props) => {
    return (
        <Button type="text" icon={<DeleteRegular fontSize={16} />} disabled={disabled} onClick={onClick}>
            Clear chat
        </Button>
    );
};
