import { FC } from "react";
import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Flex, Tooltip, Modal } from "antd";

type ActionButtonType = 'select' | 'delete' | 'view'

interface ActionButtonsRendererProps {
    buttons: ActionButtonType [];
    selectTooltip?: string;
    onAction: (data: any ) => void;
    handleDelete?: (data: any) => void;
    data: any;
    // other props...
}

const ActionButtonsRenderer: FC<ActionButtonsRendererProps> = ({ onAction, handleDelete, data, selectTooltip = "Populate Fields", buttons }) => {

    const hasSelect = buttons.includes('select');
    const hasDelete = buttons.includes('delete');
    const hasView = buttons.includes('view');
    
    const processItem = () => {
        onAction(data);
    };
    const delteItem = () => {
        if(handleDelete){
            handleDelete(data)
        }
        
    };

    return (
        <Flex gap="small">
             <Tooltip title={selectTooltip}>
                <Button size="small" onClick={processItem} type="primary" shape="circle" icon={<FileTextOutlined />} />
            </Tooltip>
            {hasDelete && <Tooltip title="Delete Deal">
                <Button size="small" onClick={delteItem} type="primary" danger shape="circle" icon={<DeleteOutlined />} />
            </Tooltip>}
        </Flex>
    );
};

export default ActionButtonsRenderer;
