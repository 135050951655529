import { ChangeEvent, ChangeEventHandler, FC, useState } from "react";
import { Button, Flex, Modal, Input, message, Drawer } from "antd";
import ProfileMenu from "../ProfileMenu";
import { CopilotButton, ThemeToggle } from "@/components/UI";
import { usePostUserFeedbackMutation } from "@/store/apis/userApi";

import styles from "./Header.module.scss";

const { TextArea } = Input;

interface HeaderProps {
    openCopilot: () => void;
}
const Header: FC<HeaderProps> = ({ openCopilot }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [feedback, setFeedback] = useState("");

    const [postUserFeedback, { isLoading: postUserFeedbackLoading }] = usePostUserFeedbackMutation();

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setFeedback(e.target.value);
    };
    const handleSubmit = () => {
        postUserFeedback({ message: feedback })
            .unwrap()
            .then(() => {
                messageApi.open({
                    type: "success",
                    content: "Successfully saved opportunity."
                });
                setOpen(false);
            });
    };
    return (
        <>
            {contextHolder}
            <div className={styles.headerButton}>
                <CopilotButton openCopilot={openCopilot} />
            </div>
            <Flex justify="flex-end" align="center" gap={10} className={styles.header}>
                <Button type="primary" onClick={() => setOpen(true)}>
                    Feedback
                </Button>
                <ThemeToggle />
                <ProfileMenu />
            </Flex>
            <Modal
                title={<p>Give Feedback</p>}
                footer={
                    <Button type="primary" onClick={handleSubmit} loading={postUserFeedbackLoading}>
                        Submit
                    </Button>
                }
                open={open}
                onCancel={() => setOpen(false)}
            >
                <TextArea name="feedback" rows={4} placeholder="Feedback" onChange={handleChange} />
            </Modal>
        </>
    );
};

export default Header;
