import { ApplicantOverviewType, CommitmentsType, EmploymentIncomeType, LivingConditionsType, LoanPurposeDetailsType, MitigantsType, OtherDetailsType } from "../types";

export const generateLoanPurposeUserContent = ({
    name,
    name2,
    LVRs,
    security,
    purpose,
    lender,
    existingCustomer,
    cashOutReason,
    pricing,
    interestRate,
    valuation
}: LoanPurposeDetailsType): string => {
    return `<!--start-input-->
- Name: ${name}
- Name2: ${name2}
- LVRs: ${LVRs}
- Security: ${security}
- Purpose: ${purpose}
- Lender: ${lender}
- Existing Customer: ${existingCustomer ? 'TRUE' : 'FALSE'}
- Cash-out reason: ${cashOutReason}
- Pricing: ${pricing}
- Interest Rate: ${interestRate}%
- Valuation: ${valuation}
<!--end-input-->
<!--start-output-->`;
};

export const generateApplicationOverview = ({
    name,
    nationality,
    residency,
    employment,
    occupation,
    employer,
    jobStarted,
    currencyOfIncome,
    name2,
    nationality2,
    residency2,
    employment2,
    occupation2,
    employer2,
    jobStarted2,
    currencyOfIncome2
}: ApplicantOverviewType): string => {
    return `<!--start-input-->
- Name: ${name}
- Nationality: ${nationality}
- Residency: ${residency}
- Employment: ${employment}
- Occupation: ${occupation}
- Employer: ${employer}
- Job started: ${jobStarted}
- Currency of income: ${currencyOfIncome}
- Name2: ${name2}
- Nationality2: ${nationality2}
- Residency2: ${residency2}
- Employment2: ${employment2}
- Occupation2: ${occupation2}
- Employer2: ${employer2}
- Job started2: ${jobStarted2}
- Currency of income2: ${currencyOfIncome2}
<!--end-input-->
<!--start-output-->`;
};


export const generateLivingConditionUserContent = ({
    name1,
    name2,
    maritalStatus,
    residency,
    kidsNumber,
    kidsAge,
    privateEducation,
    livingStatus,
    mortgageOwnerOccupied,
    rentalExpense,
    rentalExpenseAUD,
}: LivingConditionsType): string => {
    return `<!--start-input-->
- Name1: ${name1}
- Name2: ${name2}
- Martial: ${maritalStatus}
- Residency: ${residency}
- Kids Number: ${kidsNumber}
- Kids Age: ${kidsAge}
- Private Education: ${privateEducation ? 'Yes' : 'No'}
- Living Status: ${livingStatus}
- Mortgage Owner occupied: ${mortgageOwnerOccupied}
- Rental expense: ${rentalExpense ?? ''}
- Rental expense AUD: ${rentalExpenseAUD ?? ''}
<!--end-input-->
<!--start-output-->`;
};


export const generateEmploymentIncomeUserContent = ({
    date,
    fxRateAUDUSD,
    name1,
    currency1,
    salary1,
    deductions1,
    allowance1,
    averageCommission1,
    bonusThisYear1,
    bonusLastYear1,
    name2,
    currency2,
    salary2,
    deductions2,
    allowance2,
    averageCommission2,
    bonusThisYear2,
    bonusLastYear2,
    australianRentalYield,
    australianRentalIncome,
    lender,
}: EmploymentIncomeType): string => {
    return `<!--start-input-->
- Date: ${date}
- FX1: AUD/USD = ${fxRateAUDUSD}
- Name: ${name1}
- Currency: ${currency1}
- Salary: ${salary1}
- Deductions: ${deductions1}
- Allowance: ${allowance1}
- Average Commission: ${averageCommission1}
- Bonus This year: ${bonusThisYear1}
- Bonus Last year: ${bonusLastYear1}
- Name2: ${name2}
- Currency2: ${currency2}
- Salary2: ${salary2}
- Deductions2: ${deductions2}
- Allowance2: ${allowance2}
- Average Commission2: ${averageCommission2}
- Bonus This year2: ${bonusThisYear2}
- Bonus Last year2: ${bonusLastYear2}
- Aust Rental yield: ${australianRentalYield}
- Aust Rental income: ${australianRentalIncome}
- Lender: ${lender}
<!--end-input-->
<!--start-output-->`;
};


export const generateCommitmentsUserContent = ({
    name1,
    name2,
    anyDebtOutstanding,
    detailOfDebt,
    creditCardLimit,
    reduceLimit,
    newCreditCardLimit,
}: CommitmentsType): string => {
    return `<!--start-input-->
- Name1: ${name1}
- Name2: ${name2}
- Any Debt outstanding: ${anyDebtOutstanding ? 'Yes' : 'No'}
- Detail of Debt: ${detailOfDebt}
- Credit card limit: ${creditCardLimit}
- Reduce Limit?: ${reduceLimit}
- CC New Limit: ${newCreditCardLimit ?? ''}
<!--end-input-->
<!--start-output-->`;
};


export const generateOtherDetailsUserContent = ({
    name1,
    number1,
    name2,
    number2,
}: OtherDetailsType): string => {
    return `<!--start-input-->
- Name1: ${name1}
- Number1: ${number1}
- Name2: ${name2}
- Number2: ${number2}
<!--end-input-->
<!--start-output-->`;
};


export const generateMitigantsUserContent = ({
    purpose,
    applicantOverview,
    livingConditions,
    employmentIncome,
    commitments,
    fundsAvailable,
}: MitigantsType): string => {
    return `<!--start-input-->
- Purpose: ${purpose}
- Applicant overview: ${applicantOverview}
- Living conditions: ${livingConditions}
- Employment / income: ${employmentIncome}
- Commitments: ${commitments}
- Funds available: ${fundsAvailable}
<!--end-input-->
<!--start-output-->`;
};

