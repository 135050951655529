import { Col, Row, Typography } from "antd";
import { NoteHeading } from "@/components/UI";
import { FC } from "react";
import { BrokerNoteFormData } from "../../types";
import { splitName } from "@/utils/common.utils";

const { Text } = Typography;

interface ApplicantDetailProps {
    allValues: Partial<BrokerNoteFormData>;
}
const ApplicantDetail: FC<ApplicantDetailProps> = ({ allValues }) => {
    const { firstName, lastName } = splitName(allValues?.fullName || "");
    const { firstName: coFirstName, lastName: coLastName } = splitName(allValues?.fullName_2 || "");
    return (
        <div style={{ marginBottom: "5px" }}>
            <NoteHeading title="Applicant" />
            <Row gutter={8}>
                <Col xs={24} md={3}></Col>
                <Col xs={24} md={3}>
                    <Text strong>First Name</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text strong>Last Name</Text>
                </Col>
                <Col xs={24} md={4}>
                    <Text strong>Occupation</Text>
                </Col>
                <Col xs={24} md={4}>
                    <Text strong>Employer</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text strong>Citizenship</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text strong>Residence</Text>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col xs={24} md={3}>
                    <Text type="secondary"> Applicants 1</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text type="secondary">{firstName}</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text type="secondary">{lastName}</Text>
                </Col>
                <Col xs={24} md={4}>
                    <Text type="secondary">{allValues.occupation}</Text>
                </Col>
                <Col xs={24} md={4}>
                    <Text type="secondary">{allValues.employer}</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text type="secondary">{allValues.citizenship}</Text>
                </Col>
                <Col xs={24} md={3}>
                    <Text type="secondary">{allValues.residency}</Text>
                </Col>
            </Row>
            {allValues?.hasCoApplicant && (
                <Row gutter={8} style={{ marginTop: "5px" }}>
                    <Col xs={24} md={3}>
                        <Text type="secondary"> Applicants 2</Text>
                    </Col>
                    <Col xs={24} md={3}>
                        <Text type="secondary">{coFirstName}</Text>
                    </Col>
                    <Col xs={24} md={3}>
                        <Text type="secondary">{coLastName}</Text>
                    </Col>
                    <Col xs={24} md={4}>
                        <Text type="secondary">{allValues?.occupation_2}</Text>
                    </Col>
                    <Col xs={24} md={4}>
                        <Text type="secondary">{""}</Text>
                    </Col>
                    <Col xs={24} md={3}>
                        <Text type="secondary">{allValues?.citizenship_2}</Text>
                    </Col>
                    <Col xs={24} md={3}>
                        <Text type="secondary">{allValues?.residency_2}</Text>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default ApplicantDetail;
