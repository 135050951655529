import { FC, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

//redux
import { RootState } from "@/store";
import { useSelector } from "react-redux";

interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
    const authState = useSelector((state: RootState) => state.auth);
    const location = useLocation();

    if (!authState.isAuthenticated) {
        return <Navigate to="/auth" state={{ from: location }} replace />;
    }

    return children;
};

export default ProtectedRoute;
