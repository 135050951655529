import { ReactNode } from "react";
import { Collapse } from "antd";

const CLabel = ({ title }: { title: string }) => {
    return <span style={{ fontWeight: "bold" }}>{title}</span>;
};

import { useGetBrokerServiceHistoryAllQuery } from "@/store/apis/brokerNoteApi";
import Scrollbars from "rc-scrollbars";

const UserInfo = ({ data }: { data: any }) => {
    return (
        <div>
            <p>{data.product}</p>
            <p>{data.email}</p>
        </div>
    );
};
const HistorySidebar = () => {
    const { data, isError, isLoading, isSuccess } = useGetBrokerServiceHistoryAllQuery();

    return (
        <Scrollbars style={{ height: "calc(100vh - 100px)" }}>
            <div style={{ marginBottom: "1rem" }}>
                <div>
                    {isSuccess && (
                        <ul>
                            {data.data.map((item: any) => {
                                return (
                                    <Collapse
                                        key={item.key}
                                        items={[{ key: item.key, label: item.json_data.fullName, children: <UserInfo data={item.json_data} /> }]}
                                    />
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </Scrollbars>
    );
};

export default HistorySidebar;
