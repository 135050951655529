import { FC } from "react";
import { theme, Col, Row } from "antd";

import { TableData } from "@/components/UI";
import { BrokerNoteFormData } from "../../types";
import AsideNoteheading from "./AsideNoteHeading";

const { useToken } = theme;

interface LoanDetailProps {
    allValues: Partial<BrokerNoteFormData>;
}

const LoanDetail: FC<LoanDetailProps> = ({ allValues }) => {
    const { token } = useToken();

    return (
        <Row gutter={10} style={{ marginBottom: "10px" }}>
            <Col xs={24} md={6}>
                <AsideNoteheading title="Loan Details" />
            </Col>
            <Col xs={24} md={18}>
                <div>
                    <div style={{ background: "#ededed" }}>
                        <Row>
                            <Col xs={8} md={8}>
                                <TableData title="APP1" type="title" />
                            </Col>
                            <Col xs={16} md={16}>
                                <TableData title="Purchase" type="title" />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col xs={8} md={8}>
                                <TableData strong={true} title="Finance Due:" />
                            </Col>
                            <Col xs={16} md={16}>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title="3 May 2024" />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <div className="td"></div>
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData title="21/06/2024" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={8}>
                                <TableData title="Lender:" />
                            </Col>
                            <Col xs={16} md={16}>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title={allValues.lender ?? ""} />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="Product:" />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData title={allValues.product ?? ""} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={8}>
                                <TableData strong={true} title="Loan Term (Years):" />
                            </Col>
                            <Col xs={16} md={16}>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title={allValues.loanTerm ?? ""} />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="Offset:" />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData title="yes" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={8} md={8}>
                                <TableData strong={true} title="Interest Rate:" />
                            </Col>
                            <Col xs={16} md={16}>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title={allValues.interestRate ?? ""} />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="Pricing:" />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData title={allValues.pricing ?? ""} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={8}>
                                <TableData strong={true} title="Loan Purpose:" />
                            </Col>
                            <Col xs={16} md={16}>
                                <TableData title={allValues.purpose ?? ""} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={8}>
                                <TableData strong={true} title="Address:" />
                            </Col>
                            <Col xs={16} md={16}>
                                <TableData title={allValues.security ?? ""} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={8}>
                                <TableData strong={true} title="Property Value:" />
                            </Col>
                            <Col xs={16} md={16}>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title={allValues.propertyValue ?? ""} />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="Valuation:" />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData title={allValues.valuation ?? ""} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={8}>
                                <TableData strong={true} title="Loan Amount:" />
                            </Col>
                            <Col xs={16} md={16}>
                                <Row>
                                    <Col xs={8} md={8}>
                                        <TableData title={allValues.loanAmount ?? ""} />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData strong={true} title="LVR:" />
                                    </Col>
                                    <Col xs={8} md={8}>
                                        <TableData title={allValues.lvr ?? ""} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default LoanDetail;
