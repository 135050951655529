import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { initializeIcons } from "@fluentui/react";

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";

// redux
import { store } from "@/store";

import AppProvider from "@/providers/AppProvider";
import App from "./app";

// styles
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "./index.scss";
import { getAccessToken } from "./utils/msalAuth.utils";

export const msalInstance = new PublicClientApplication(msalConfig);

initializeIcons();

msalInstance.initialize().then(() => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback(async (event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
            try {
                const token = await getAccessToken();
                console.log("token", token);
            } catch (err) {}
        }
    });

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    root.render(
        <React.StrictMode>
            <Router>
                <AppProvider>
                    <Provider store={store}>
                        <App pca={msalInstance} />
                    </Provider>
                </AppProvider>
            </Router>
        </React.StrictMode>
    );
});
