// src/store/apis/authApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
export type ApiResponse<T> = {
    success: boolean;
    statusCode: number;
    data: T;
};

export const brokerNoteApi = createApi({
    reducerPath: "brokerNoteApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token; // Access token from auth slice
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        postBrokerServiceHistory: builder.mutation<ApiResponse<any>, { website_tracking_id: string; json_data: any }>({
            query: body => ({
                url: "api/broker-service-history/",
                method: "POST",
                body
            }),
            transformResponse: (response: ApiResponse<any>): any => response
        }),
        postAppFeedback: builder.mutation<ApiResponse<any>, { feedback: string }>({
            query: body => ({
                url: "api/ai/feedback/",
                method: "POST",
                body
            }),
            transformResponse: (response: ApiResponse<any>): any => response
        }),
        getBrokerServiceHistoryAll: builder.query<ApiResponse<any>, void>({
            query: () => "api/broker-service-history/all/",
            transformResponse: (response: ApiResponse<any>): any => {
                return response;
            }
        })
    })
});
export const { usePostBrokerServiceHistoryMutation, useGetBrokerServiceHistoryAllQuery, usePostAppFeedbackMutation } = brokerNoteApi;
