import { FC } from "react";
import { Col, Row } from "antd";
import { InputField, SelectField, RadioGroupField } from "@/components/Form";
import { FormPropsType } from "../../types";



const OccupationForm: FC<FormPropsType> = ({ control, errors }) => {
    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                <InputField name="occupation" control={control} label="Occupation" error={errors.occupation?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField name="employment" control={control} label="Employment" error={errors.employment?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField name="employer" control={control} label="Employer" error={errors.employer?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField name="since" control={control} label="Start Date" error={errors.since?.message} />
            </Col>
            <Col xs={24} md={12}>
                <SelectField
                    name="currency"
                    control={control}
                    label="Currency"
                    placeholder="Currency"
                    error={errors.currency?.message}
                    options={[
                        { label: "AUD", value: "aud" },
                        { label: "USD", value: "usd" }
                    ]}
                />
            </Col>
            <Col xs={24} md={12}>
                <InputField variant="filled" name="fx" control={control} label="FX" error={errors.fx?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="salary" control={control} label="Salary" error={errors.salary?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="deduction" control={control} label="Deduction" error={errors.deduction?.message} />
            </Col>

            <Col xs={24} md={12}>
                <InputField prefix="$" name="allowances" control={control} label="Allowances" error={errors.allowances?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="commission" control={control} label="Commission" error={errors.commission?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="bonusThis" control={control} label="Bonus this year" error={errors.bonusThis?.message} />
            </Col>
            <Col xs={24} md={12}>
                <InputField prefix="$" name="bonusLast" control={control} label="Bonus last year" error={errors.bonusLast?.message} />
            </Col>
            <Col xs={24} md={12}>
                <RadioGroupField
                    name="anyLiabilities"
                    control={control}
                    label="Any Liabilities"
                    error={errors.anyLiabilities?.message}
                    options={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" }
                    ]}
                />
            </Col>
            <Col xs={24} md={12}>
                {/* <DatePickerField control={control} label="Date" name="date" error={errors.date?.message} /> */}
                <InputField name="date" control={control} label="Date" error={errors.date?.message} />
            </Col>
        </Row>
    );
};

export default OccupationForm;
