import { Typography } from "antd";
import Title from "antd/es/typography/Title";
import { FC } from "react";

const { Text } = Typography;
interface TableDataProps {
    level?: 1 | 2 | 3 | 4 | 5;
    title: string | number;
    type?: "title" | "text";
    strong?: boolean;
}
const TableData: FC<TableDataProps> = ({ title, level = 5, type = "text", strong = false }) => {
    return (
        <>
            <div className="td">
                {type === "text" ? (
                    <Text strong={strong}>{title}</Text>
                ) : (
                    <Title level={level} style={{ margin: 0 }}>
                        {title}
                    </Title>
                )}
            </div>
        </>
    );
};

export default TableData;
