import { FC } from "react";
import { Card, Col, Popover, Row, Spin } from "antd";

import styles from "../../BrokerNote.module.scss";
import AsideNoteheading from "./AsideNoteHeading";
import { ContentNameType } from "../../types";

interface AIBrokerNoteProps {
    id: ContentNameType;
    title: string;
    loading: boolean;
    aiData: string;
    handleEditMode: (id: ContentNameType) => void;
}
const AIBrokerNote: FC<AIBrokerNoteProps> = ({ title, loading, aiData, handleEditMode, id }) => {
    return (
        <Row gutter={10} style={{ marginBottom: "10px" }}>
            <Col xs={24} md={6}>
                <AsideNoteheading title={title} />
            </Col>
            <Col xs={24} md={18}>
                <Popover placement="left" title="Click to edit the content">
                    <Card className={styles.contentOutput} styles={{ body: { padding: 0 } }} onClick={() => handleEditMode(id)}>
                        {loading ? (
                            <div className="typingEffect">
                                <Spin size="small" /> Loading...
                            </div>
                        ) : (
                            <div className={styles.contentOutputText} dangerouslySetInnerHTML={{ __html: aiData }} />
                        )}
                    </Card>
                </Popover>
            </Col>
        </Row>
    );
};

export default AIBrokerNote;
