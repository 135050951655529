import { FC, useRef } from "react";
import { Button, Card, Col, Flex, message, Row, Spin } from "antd";

import AIContentHeading from "./AIContentHeading";
import { ComplianceFieldTypes } from "../types";

interface AIContentProps {
    id: ComplianceFieldTypes,
    title: string;
    paragraph?:string;
    isLoading: boolean;
    aiData: string;
    generateAIContent: (id: ComplianceFieldTypes) => void;
}
const AIContent: FC<AIContentProps> = ({ title, paragraph, isLoading, aiData, id, generateAIContent }) => {
    const aiNoteContentRef = useRef<HTMLDivElement>(null);
    const [messageApi, contextHolder] = message.useMessage();

    const copyToClipboard = () => {
        if (aiNoteContentRef.current) {
            const range = document.createRange();
            range.selectNodeContents(aiNoteContentRef.current);
            const selection = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
            document.execCommand('copy');
             messageApi.open({
                type: 'success',
                content: 'Text copied successfully',
            });
            // Clear the selection
            if (selection) {
                selection.removeAllRanges();
            }
        }
    };

    return (
        <div style={{ marginBottom: "1rem"}}>
            <AIContentHeading title={title} paragraph={paragraph} />
            <Card  styles={{ body: { padding: 0 } }}>
                {isLoading ? (
                    <div className="typingEffect">
                        <Spin size="small" /> Loading...
                    </div>
                ) : (
                    <div className="ai-note-content"  ref={aiNoteContentRef} dangerouslySetInnerHTML={{ __html: aiData }} />
                )}
            </Card>
            <Flex gap={22} style={{marginTop:"1rem"}} justify="flex-end">
                    <Button type="primary" onClick={() => generateAIContent(id)} loading={isLoading}>Generate</Button>
                    <Button type="primary" onClick={copyToClipboard} disabled={ (aiData && aiData !== "") ? false:true }>Copy</Button>
            </Flex>
            {contextHolder}
        </div>
    );
};

export default AIContent;
