import React from "react";

const lazyLoad = (importFunc: () => Promise<{ default: React.ComponentType<any> }>) => {
    const Component = React.lazy(importFunc);
    return (props: any) => (
        <React.Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </React.Suspense>
    );
};

export default lazyLoad;
