import { Outlet } from "react-router-dom";
import { useLogin } from "@/authConfig";
import { Drawer, Grid } from "antd";

import { PageLoader, ThemeToggle } from "@/components/UI";
import { useTheme } from "@/contexts/themeContext";
import { MobileHeader, MobileMenu, Sidebar } from "@/components/Layout";

import styles from "./Layout.module.scss";
import { useState } from "react";
import { Header } from "@/components/Layout";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

import Chat from "@/pages/chat/Chat";

const { useBreakpoint } = Grid;

const Layout = () => {
    const { isLoading } = useSelector((state: RootState) => state.common);
    const { md } = useBreakpoint();
    const { theme, sidebarCollapseState } = useTheme();
    const [menuState, setMenuState] = useState(false);
    const [openCopilotDialog, setOpenCopilotDialog] = useState(false);

    const getMenuState = () => {
        setMenuState(!menuState);
    };

    const handleOpenCopilot = () => {
        setOpenCopilotDialog(true);
    };
    return (
        <>
            <Header openCopilot={handleOpenCopilot} />
            <div className={styles["main-layout"]}>
                {md ? <Sidebar /> : <MobileHeader getMenuState={getMenuState} />}

                <div
                    className={`${styles["main-body-container"]} ${sidebarCollapseState ? styles.collapsedSidebar : ""}`}
                    style={{
                        backgroundColor: theme === "dark" ? "rgb(28, 29, 32)" : "rgb(255, 255, 255)"
                    }}
                >
                    <div className={styles["main-body"]}>
                        <Outlet />
                    </div>
                    {/* {md && <Footer />} */}
                </div>
                {/* <div className={styles["toggle-theme-button"]}>
                    < />
                </div> */}
            </div>
            <MobileMenu onClose={() => setMenuState(false)} open={menuState} />
            {/* <div className={styles.layout}>
                <header className={styles.header} role={"banner"}>
                    <div className={styles.headerContainer}>
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h3 className={styles.headerTitle}>Broker Copilot</h3>
                        </Link>
                        <nav>
                            <ul className={styles.headerNavList}>
                                <li>
                                    <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                        Chat
                                    </NavLink>
                                </li>
                                <li className={styles.headerNavLeftMargin}>
                                    <NavLink to="/qa" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                        Ask a question
                                    </NavLink>
                                </li>
                                <li className={styles.headerNavLeftMargin}>
                                    <a href="https://aka.ms/entgptsearch" target={"_blank"} title="Github repository link">
                                        <img
                                            src={github}
                                            alt="Github logo"
                                            aria-label="Link to github repository"
                                            width="20px"
                                            height="20px"
                                            className={styles.githubLogo}
                                        />
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <h4 className={styles.headerRightText}>Azure OpenAI + AI Search</h4>
                        {useLogin && <LoginButton />}
                    </div>
                </header>

                <Outlet />
            </div> */}
            <PageLoader isLoading={isLoading} />
            <Drawer title="Broker Copilot" open={openCopilotDialog} onClose={() => setOpenCopilotDialog(false)} size="large">
                <Chat />
            </Drawer>
        </>
    );
};

export default Layout;
