import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Row, Col, Select, Input } from "antd";

import { Stack, TextField, Dropdown, IDropdownOption, TooltipHost } from "@fluentui/react";
import { Button, Tooltip, Field } from "@fluentui/react-components";
import { Send28Filled } from "@fluentui/react-icons";
import { isLoggedIn, requireAccessControl } from "../../authConfig";

const { TextArea } = Input;
import styles from "./QuestionInput.module.scss";

interface Props {
    onSend: (question: string, dropdownSelections: string[]) => void;
    disabled: boolean;
    initQuestion?: string;
    placeholder?: string;
    clearOnSend?: boolean;
    inputEntity?: string;
}
interface QuestionData {
    question: string;
    dropdownSelections?: string[];
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, initQuestion, inputEntity }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [dropdownSelections, setDropdownSelections] = useState<string[]>([inputEntity || "", "", ""]);
    const [questionData, setQuestionData] = useState<QuestionData>({ question: "", dropdownSelections: [inputEntity || "", "", ""] });

    const mortgageLenders: IDropdownOption[] = [
        { key: "ANZ", text: "ANZ" },
        { key: "CBA", text: "Commonwealth Bank of Australia" },
        { key: "MACQUARIE", text: "Macquarie Bank" },
        { key: "BOQ", text: "Bank of Queensland" },
        { key: "NAB", text: "National Australia Bank" },
        { key: "ING", text: "ING" },
        { key: "HSBC", text: "HSBC" },
        { key: "UBANK", text: "Ubank" },
        { key: "BOS", text: "Bank of Sydney (BOS)" }
    ];
    const categories: IDropdownOption[] = [
        { key: "Processes", text: "Processes" },
        { key: "Factsheet", text: "Factsheet" },
        { key: "Credit", text: "Credit Policy" },
        { key: "Forms", text: "Forms" }
    ];
    const subcategories: IDropdownOption[] = [
        { key: 'Processes', text: 'Processes' },
        { key: 'Factsheet', text: 'Factsheet' },
        { key: 'Credit', text: 'Credit Policy' },
    ];

    // Function to get lender by key
    const getLenderByText = (key: string | undefined): string => {
        if (!key) return "";  // Return empty or default string if key is undefined
        const lender = mortgageLenders.find(lender => lender.key === key);
        return lender ? lender.text : "Select a Mortgage Lender";  // Default text if no lender found
    };
        
    useEffect(() => {
        initQuestion && setQuestion(initQuestion);
    }, [initQuestion]);

    const handleDropdownChange = (index: number) => (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        console.log("index", index);
        console.log("option", option);

        setQuestionData(prevState => {
            console.log("prevState", prevState);
            const newSelections = [...(prevState.dropdownSelections || ["", "", ""])];
            console.log("newSelections", newSelections);
            // newSelections[index] = option ? option.text : '';
            // setDropdownSelections(newSelections);
            // return { ...prevState, dropdownSelections: newSelections };
            // Set the new value for the dropdown that was changed
            newSelections[index] = option ? (option.key as string) : "";

            // Reset the values of the dropdowns that come after the one that was changed
            for (let i = index + 1; i < newSelections.length; i++) {
                newSelections[i] = "";
            }

            // Update the dropdown selections state
            setDropdownSelections(newSelections);

            // Return the new state for the question data
            return { ...prevState, dropdownSelections: newSelections };
        });
    };

    const handleSelectChange = (index: number) => (key: string) => {
        setQuestionData(prevState => {
            const newSelections = [...(prevState.dropdownSelections || ["", "", ""])];

            // Set the new value for the dropdown that was changed
            newSelections[index] = key ? key : "";

            // Reset the values of the dropdowns that come after the one that was changed
            for (let i = index + 1; i < newSelections.length; i++) {
                newSelections[i] = "";
            }

            // Update the dropdown selections state
            setDropdownSelections(newSelections);
            // Return the new state for the question data
            return { ...prevState, dropdownSelections: newSelections };
        });
    };

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }
        onSend(question, dropdownSelections);

        if (clearOnSend) {
            setQuestion("");
            setDropdownSelections(["", "", ""]);
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    // When you update the question text or dropdown selections, you'll update the `questionData` state
    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
        setQuestionData(prevState => ({ ...prevState, question: question, dropdownSelections: dropdownSelections }));
    };

    const onRenderOption = (option?: IDropdownOption): JSX.Element => {
        return (
            <TooltipHost content={option ? option.text : ""}>
                <span>{option ? option.text : "Select an option"}</span>
            </TooltipHost>
        );
    };

    const { instance } = useMsal();

    const disableRequiredAccessControl = requireAccessControl && !isLoggedIn(instance);
    const sendQuestionDisabled = disabled || !question.trim() || disableRequiredAccessControl;

    if (disableRequiredAccessControl) {
        placeholder = "Please login to continue...";
    }

    return (
        <Stack tokens={{ childrenGap: 8 }}>
            <Row gutter={16}>
                <Col xs={24} md={8}>
                    <Select
                        style={{ width: "100%" }}
                        onChange={handleSelectChange(0)}
                        fieldNames={{ label: "text", value: "key" }}
                        options={mortgageLenders}
                        placeholder={inputEntity === "" ? "Mortgage Lender" : getLenderByText(inputEntity)}
                    />
                    {/* <Dropdown
                        placeholder="Mortgage Lender"
                        options={mortgageLenders}
                        onChange={handleDropdownChange(0)}
                        selectedKey={dropdownSelections[0]}
                        onRenderOption={onRenderOption}
                    /> */}
                </Col>
                <Col xs={24} md={8}>
                    <Select
                        style={{ width: "100%" }}
                        onChange={handleSelectChange(1)}
                        fieldNames={{ label: "text", value: "key" }}
                        options={categories}
                        placeholder="Category"
                    />

                    {/* <Dropdown
                        placeholder="Category"
                        options={categories}
                        onChange={handleDropdownChange(1)}
                        selectedKey={dropdownSelections[1]}
                        onRenderOption={onRenderOption}
                    /> */}
                </Col>
                {/* <Col xs={24} md={8}>
                    <Select
                        style={{ width: "100%" }}
                        onChange={handleSelectChange(2)}
                        fieldNames={{ label: "text", value: "key" }}
                        options={subcategories}
                        placeholder="Sub-Category (Disabled - Clash with Category)"
                    />

                    // <Dropdown
                    //     placeholder="Sub-Category (Disabled - Clash with Category)"
                    //     options={subcategories}
                    //     onChange={handleDropdownChange(2)}
                    //     selectedKey={dropdownSelections[2]}
                    //     onRenderOption={onRenderOption}
                    // />
                </Col> */}
            </Row>

            <Stack horizontal style={{ minHeight: "80px" }} className={styles.questionInputContainer}>
                {/* <TextField
                    className={styles.questionInputTextArea}
                    disabled={disableRequiredAccessControl}
                    placeholder={placeholder}
                    multiline
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                /> */}
                <TextArea
                    showCount
                    disabled={disableRequiredAccessControl}
                    placeholder={placeholder}
                    value={question}
                    variant="borderless"
                    onChange={e => onQuestionChange(e, e.target.value)}
                    onKeyDown={onEnterPress}
                    style={{ paddingLeft: "0", paddingRight: "0" }}
                    autoSize={{ minRows: 4 }}
                    maxLength={1000}
                />
                <div className={styles.questionInputButtonsContainer}>
                    <Tooltip content="Ask question button" relationship="label">
                        <Button
                            size="large"
                            icon={<Send28Filled primaryFill="rgba(115, 118, 225, 1)" />}
                            disabled={sendQuestionDisabled}
                            onClick={sendQuestion}
                        />
                    </Tooltip>
                </div>
            </Stack>
        </Stack>
    );
};