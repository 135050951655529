import { renderToStaticMarkup } from "react-dom/server";
import React from 'react'; // Ensure React is in scope when using JSX
import { getCitationFilePath } from "../../api";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};

{/*
// Here the generated response should adhere to the citation to generate a static markup that redirect to citation using onCitationClicked.
export function parseAnswerToHtml(answer: string, isStreaming: boolean, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    const citations: string[] = [];
    // Trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.trim();

    // Omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }
    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }
            const path = getCitationFilePath(part);
            
            return renderToStaticMarkup(
                <a href="#" className="supContainer" title={part} onClick={(e) => {e.preventDefault(); onCitationClicked(path);}}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations
    };
}
*/}
export function parseAnswerToHtml(answer: string, isStreaming: boolean, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    const citations: string[] = [];
    let parsedAnswer = answer.trim();

    if (isStreaming) {
        let lastIndex = parsedAnswer.lastIndexOf('[');
        parsedAnswer = lastIndex > 0 ? parsedAnswer.substring(0, lastIndex) : parsedAnswer;
    }

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }
            const path = getCitationFilePath(part);
            // We generate HTML strings directly here instead of React components
            return `<a href="#" class="supContainer" title="${part}" data-citation-path="${part}"><sup>${citationIndex}</sup></a>`;
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations
    };
}