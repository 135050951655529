import { Button } from "antd";
import { FC } from "react";

import COPILOT from "@/assets/images/copilot-white.png";

interface CopilotButtonProps {
    openCopilot: () => void;
}

const CopilotButton: FC<CopilotButtonProps> = ({ openCopilot }) => {
    return (
        <div onClick={() => openCopilot()} className="gradient-bg copilot-btn">
            <span className="btn-icon">
                <img src={COPILOT} alt="" />
            </span>
            <span className="btn-title">Ask Copilot</span>
        </div>
    );
};

export default CopilotButton;
