import { FC } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import OpportunityList from './OpportunityList';
import AllOpportunityList from './AllOpportunityList';


interface DealTabInterface {
    haldeSelectedDeal: (selectedId: number, listType: 'all' | 'self') => void;
}
const DealTab:FC<DealTabInterface> = ({haldeSelectedDeal}) => {
    const handleSelfDealsSelectiion = (selectedId: number) => {
        haldeSelectedDeal(selectedId, 'self')
    }
    const handleAllDealsSelectiion = (selectedId: number) => {
        haldeSelectedDeal(selectedId, 'all')
    }
    const items: TabsProps['items'] = [
        {
          key: '1',
          label: 'Your Deals',
          children: <OpportunityList haldeSelectedDeal={handleSelfDealsSelectiion}/>,
        },
        {
          key: '2',
          label: 'All Deals',
          children: <AllOpportunityList haldeSelectedDeal={handleAllDealsSelectiion}/>,
        }
      ]; 
      const onChange = (key: string) => {
        console.log(key);
      };
    return (
        <><Tabs defaultActiveKey="1" items={items} onChange={onChange} /></>
    );
};

export default DealTab;