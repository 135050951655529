import { FC, RefObject, useEffect, useRef } from "react";
// react hook form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SelectField from "@/components/Form/SelectField";
import { RadioGroupField } from "@/components/Form";
import { Button } from "antd";
import { CASH_OUT_INVOLVED_OPTIONS, LOAN_STRUCTURE_OPTIONS } from "@/devFrontData/optionList";
import Title from "antd/es/typography/Title";
import { LENDER_OPTIONS } from "@/pages/opportunity/brokerNotes/constant/common";


const loanScenarioFormSchema = yup.object().shape({
    cashOutInvolved: yup.string(),
    loanStructure: yup.string().required(),
    loanScenarioLender1: yup.string().required(),
    loanScenarioLender2: yup.string().required(),
    loanScenarioLender3: yup.string().required()
});
export type LoanScenarioFormType = yup.InferType<typeof loanScenarioFormSchema>;

interface LoanScenarioFormProps {
    handleFormSubmit: (data: LoanScenarioFormType) => void;
    submitRef: RefObject<HTMLButtonElement>;
    setFormData?: (data: LoanScenarioFormType) => void;
}
const LoanScenarioForm:FC<LoanScenarioFormProps> = ({handleFormSubmit, submitRef, setFormData}) => {
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(loanScenarioFormSchema),
        defaultValues: {
            cashOutInvolved:"",
            loanStructure:"",
            loanScenarioLender1: "",
            loanScenarioLender2: "",
            loanScenarioLender3: ""
        }
    });

    /**
     ** Passes the updated fields value to the parent component
     */
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // Create a deep copy of the form data
            const updatedData = JSON.parse(JSON.stringify(value));

            // Dispatch the form values to the Redux store
            if(setFormData) {
                setFormData(updatedData);
            }
            
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const onSubmit: SubmitHandler<LoanScenarioFormType> = data => {
        handleFormSubmit(data);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SelectField
                        name="cashOutInvolved"
                        control={control}
                        label="Cash-Out Involved? (If Applicable)"
                        placeholder="Cash-Out Involved"
                        error={errors.cashOutInvolved?.message}
                        formDirection="row"
                        options={CASH_OUT_INVOLVED_OPTIONS}
                    />
                <SelectField
                        name="loanStructure"
                        control={control}
                        label="Loan Structure"
                        placeholder="Loan Structure"
                        error={errors.loanStructure?.message}
                        formDirection="row"
                        options={LOAN_STRUCTURE_OPTIONS}
                    />  

                <Title level={5}>Loan Scenario Comparison</Title>

                <SelectField
                        name="loanScenarioLender1"
                        control={control}
                        label="Lender 1"
                        placeholder="Lender 1"
                        error={errors.loanScenarioLender1?.message}
                        formDirection="row"
                        options={LENDER_OPTIONS}
                    />  

                <SelectField
                        name="loanScenarioLender2"
                        control={control}
                        label="Lender 2"
                        placeholder="Lender 2"
                        error={errors.loanScenarioLender2?.message}
                        formDirection="row"
                        options={LENDER_OPTIONS}
                    /> 

                <SelectField
                        name="loanScenarioLender3"
                        control={control}
                        label="Lender 3"
                        placeholder="Lender 3"
                        error={errors.loanScenarioLender3?.message}
                        formDirection="row"
                        options={LENDER_OPTIONS}
                    /> 
                    <Button type="primary" style={{ display: "none" }} htmlType="submit" ref={submitRef}>
                        Form Submit
                    </Button>
            </form>

            
        </div>
    );
};

export default LoanScenarioForm;
