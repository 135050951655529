// src/store/apis/authApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export type ApiResponse<T> = {
    success: boolean;
    statusCode: number;
    data: T;
};
interface VerifyTokenResponse {
    // Define the structure based on what your backend returns
    access_token: string;
    refresh_token: string;
    email: string;
}

interface VerifyTokenRequest {
    code: string;
}
interface GoogleAuthLinkResponse {
    google_signin_link: string;
}

interface LoginRequest {
    username: string;
    password: string;
}
export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BACKEND_URL // Ensure your .env files define VITE_BACKEND_URL
    }),
    endpoints: builder => ({
        getGoogleAuthLink: builder.query<string, void>({
            query: () => "api/auth/google-redirect-link",
            transformResponse: (response: ApiResponse<GoogleAuthLinkResponse>) => {
                // Safely extracting the google_signin_link from the response
                return response.data.google_signin_link;
            }
        }),
        verifyGoogleToken: builder.mutation<any, VerifyTokenRequest>({
            query: body => ({
                url: "api/auth/google-verify-token",
                method: "POST",
                body
            }),
            transformResponse: (response: any, meta, arg) => response.data
        }),
        login: builder.mutation<any, LoginRequest>({
            query: body => ({
                url: "api/auth/login",
                method: "POST",
                body
            }),
            transformResponse: (response:any) =>  response
        })
    })
});
export const { useGetGoogleAuthLinkQuery, useVerifyGoogleTokenMutation , useLoginMutation } = authApi;
