import { CSSProperties, FC } from "react";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";

interface AIContentHeadingProps {
    title: string;
    paragraph?: string;
    style?: CSSProperties;
}

const AIContentHeading: FC<AIContentHeadingProps> = ({ title, style , paragraph}) => {
    return (
        <div  style={{ ...style }}>
            <Title level={5} style={{ margin: 0 }}>
                {title}
            </Title>
            {paragraph && <Paragraph>{paragraph}</Paragraph>}
        </div>
    );
};

export default AIContentHeading;
