import { Switch, Grid } from "antd";
import { WeatherMoon16Regular, WeatherSunny16Regular } from "@fluentui/react-icons";
import { useTheme } from "@/contexts/themeContext";

const { useBreakpoint } = Grid;
const ThemeToggle = () => {
    const { theme, toggleTheme } = useTheme();
    const { md } = useBreakpoint();

    const handleChange = () => {
        toggleTheme();
    };
    return (
        <Switch
            size={md ? "default" : "small"}
            onChange={handleChange}
            checkedChildren={<WeatherSunny16Regular />}
            unCheckedChildren={<WeatherMoon16Regular />}
            checked={theme === "light"}
        />
    );
};

export default ThemeToggle;
