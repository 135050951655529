import { ConfigProvider, theme, Grid } from "antd";
import { FC, ReactNode } from "react";
import { useTheme, ThemeProvider } from "../contexts/themeContext";
import { ThemeProvider as FluentUiThemeProvider, PartialTheme } from "@fluentui/react";

const { useBreakpoint } = Grid;

interface ThemeConfigProviderProps {
    children: ReactNode;
}

const ThemeConfigProvider: FC<ThemeConfigProviderProps> = ({ children }) => {
    const screens = useBreakpoint();
    const { defaultAlgorithm, darkAlgorithm } = theme;
    const { theme: currentTheme } = useTheme();

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Poppins",
                    colorPrimary: "#05a2e4",
                    colorBorderSecondary: currentTheme === "dark" ? "#303030" : "#e1e1e1",
                    colorLink: "#05a2e4"
                },
                components: {
                    Card: {
                        // colorBorder: "@colorBorderSecondary"
                    },
                    Typography: {
                        fontSizeHeading1: screens.md ? 45 : 30,
                        fontSizeHeading2: screens.md ? 34 : 28,
                        lineHeightHeading1: 1.5,
                        lineHeightHeading2: 1.4,
                        fontWeightStrong: 500,
                        colorTextHeading: currentTheme === "dark" ? "#ffffff" : "#0e0e0e"
                    },
                    Menu: {
                        itemActiveBg: "transparent",
                        itemSelectedBg: "transparent"
                    }
                },
                algorithm: currentTheme === "dark" ? darkAlgorithm : defaultAlgorithm
            }}
        >
            {children}
        </ConfigProvider>
    );
};

const myTheme: PartialTheme = {
    palette: {
        themePrimary: "#05a2e4"
    }
};

const FluentThemeProvider: FC<ThemeConfigProviderProps> = ({ children }) => {
    return (
        <FluentUiThemeProvider applyTo="none" theme={myTheme}>
            {children}
        </FluentUiThemeProvider>
    );
};

interface ThemeProviderProps {
    children: ReactNode;
}

const AppProvider: FC<ThemeProviderProps> = ({ children }) => {
    return (
        <ThemeProvider>
            <ThemeConfigProvider>
                <FluentThemeProvider>{children}</FluentThemeProvider>
            </ThemeConfigProvider>
        </ThemeProvider>
    );
};

export default AppProvider;
