import { configureStore } from "@reduxjs/toolkit";
//slices
import brokerNoteFormReducer from "./slices/brokerNote/formSlice";
import brokerNoteSystemPrompt from "./slices/brokerNote/systemPromptSlice";
import auth from "./slices/auth/authSlice";
import opportunityForm from "./slices/opportunity/opportunityFormSlice";
import complianceForm from "./slices/compliance/complianceFormSlice";
import common from "./slices/common/commonSlice";
//apis
import { loanRequestApi } from "./apis/loanApi";
import { forexApi } from "./apis/forexApi";
import { chatGptApi } from "./apis/chatGptApi";
import { authApi } from "./apis/authApi";
import { brokerNoteApi } from "./apis/brokerNoteApi";
import { anzServiceApi } from "./apis/anzApi";
import { opportunityServiceApi } from "./apis/opportunityApi";
import { complianceNoteApi } from "./apis/complianceNoteApi";
import { userApi } from "./apis/userApi";

export const store = configureStore({
    reducer: {
        [loanRequestApi.reducerPath]: loanRequestApi.reducer,
        [forexApi.reducerPath]: forexApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [chatGptApi.reducerPath]: chatGptApi.reducer,
        [brokerNoteApi.reducerPath]: brokerNoteApi.reducer,
        [anzServiceApi.reducerPath]: anzServiceApi.reducer,
        [opportunityServiceApi.reducerPath]: opportunityServiceApi.reducer,
        [complianceNoteApi.reducerPath]: complianceNoteApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        brokerNoteForm: brokerNoteFormReducer,
        brokerNoteSystemPrompt: brokerNoteSystemPrompt,
        auth: auth,
        opportunityForm: opportunityForm,
        complianceForm: complianceForm,
        common: common
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            loanRequestApi.middleware,
            forexApi.middleware,
            chatGptApi.middleware,
            authApi.middleware,
            brokerNoteApi.middleware,
            anzServiceApi.middleware,
            opportunityServiceApi.middleware,
            complianceNoteApi.middleware,
            userApi.middleware
        )
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
