import { AutocompleteField, SelectField } from "@/components/Form";
import { FormPropsType } from "../../../types";
import { Col, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { useFetchAllUsersQuery } from "@/store/apis/userApi";
import { User } from "@/types";

const mapUserOptions = (users: User[]) => {
    return users.map(user => ({
        label: `${user.fullname} (${user.email})`,
        value: user.email.toString()
    }));
}
const ProcessorForm: FC<FormPropsType> = ({ control, errors }) => {
    const { data: brokers, isLoading: brokersLoading, isError: brokersFetchError } = useFetchAllUsersQuery({brokerRole: 'broker'});
    const { data: processors, isLoading: processorsLoading, isError: processorsFetchError } = useFetchAllUsersQuery({brokerRole: 'processor'});

    // const [filteredOptionsBroker, setFilteredOptionsBroker] = useState<any>([]);
    // const [filteredOptionsProcessor, setFilteredOptionsProcessor] = useState<any>([]);

    // useEffect(() => {
    //     if (brokers) {
    //         const initialOptions = mapUserOptions(brokers)
    //         setFilteredOptionsBroker(initialOptions);
    //     }
    //     if (processors) {
    //         const initialOptions = mapUserOptions(processors)
    //         setFilteredOptionsProcessor(initialOptions);
    //     }
    // }, [brokers, processors]);

    // const handleUserSearch = (searchText:string) => {
    //     if (!searchText) {
    //         setFilteredOptionsBroker([]);
    //     } else {
    //         const filteredUsers = brokers?.filter((broker) =>
    //             broker.fullname.toLowerCase().includes(searchText.toLowerCase())
    //         )
    //         const filteredUsersOptions = mapUserOptions(filteredUsers ?? []);
    //         setFilteredOptionsBroker(filteredUsersOptions);
    //     }
    // };

    // const handleProcessorSearch = (searchText:string) => {
    //     if (!searchText) {
    //         setFilteredOptionsProcessor([]);
    //     } else {
    //         const filteredUsers = processors?.filter((processor) =>
    //             processor.fullname.toLowerCase().includes(searchText.toLowerCase())
    //         )
    //         const filteredUsersOptions = mapUserOptions(filteredUsers ?? []);
    //         setFilteredOptionsProcessor(filteredUsersOptions);
    //     }
    // };

    // const handlePrimaryContactChange = (value:string) => {
    //     console.log('handlePrimaryContactChange', value)
    // }
    return (
        <Row gutter={12}>
            <Col xs={24} md={12}>
                {/* <AutocompleteField
                    label="Primary contact"
                    placeholder="Type primary contact name..."
                    control={control}
                    name="brokerPrimaryContact"
                    options={filteredOptionsBroker}
                    error={errors?.brokerPrimaryContact?.message ?? ""}
                    onSearch={handleUserSearch}
                    disabled={usersLoading || usersFetchError}
                    onChange={handlePrimaryContactChange}
                /> */}
                <SelectField
                    name="brokerPrimaryContact"
                    control={control}
                    label="Primary contact"
                    placeholder="Primary contact"
                    error={errors.brokerPrimaryContact?.message}
                    options={brokers ?? []}
                    disabled={brokersLoading}
                    fieldNames={{label:"email", value:"email"}}
                />
            </Col>

            <Col xs={24} md={12}>
                {/* <AutocompleteField
                    label="Secondary contact"
                    placeholder="Type secondary contact name..."
                    control={control}
                    name="brokerSecondaryContact"
                    options={filteredOptionsProcessor}
                    error={errors?.brokerSecondaryContact?.message ?? ""}
                    onSearch={handleProcessorSearch}
                    disabled={usersLoading || usersFetchError}
                /> */}

                <SelectField
                    name="brokerSecondaryContact"
                    control={control}
                    label="Secondary contact"
                    placeholder="Secondary contact"
                    error={errors.brokerSecondaryContact?.message}
                    options={processors ?? []}
                    disabled={processorsLoading}
                    fieldNames={{label:"email", value:"email"}}
                />
            </Col>
        </Row>
    );
};

export default ProcessorForm;
