import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";

// import { ChatAppResponse, getCitationFilePath } from "../../api";

// Feedback
import { useState, useMemo } from "react";
import { Stack, IconButton, Modal, TextField, PrimaryButton, DefaultButton } from "@fluentui/react";
import { ChatAppResponse, getCitationFilePath, appendFeedbackToSheet } from "../../api";
import { useMsal } from "@azure/msal-react";
// import { useLogin, getToken } from "../../authConfig";
import { useLogin, getToken, isLoggedIn } from "../../authConfig";

import { mergeStyleSets } from "@fluentui/react/lib/Styling";

const classNames = mergeStyleSets({
    modalContent: {
        padding: "20px",
        maxWidth: "500px"
    },
    textFields: {
        margin: "10px 0"
    },
    feedbackButtons: {
        margin: "10px 2px",
        selectors: {
            "&:first-child": { marginLeft: 0 },
            "&:last-child": { marginRight: 0 }
        },
        color: "white",
        backgroundColor: "blue"
    },
    selectedButton: {
        // backgroundColor: '#0078d4', // Replace with your theme color
        color: "white",
        backgroundColor: "darkblue"
    },
    submitButton: {
        marginTop: "20px"
    }
});

// For feedback
const client = useLogin ? useMsal().instance : undefined;

const appendFeedback = async (feedbackData: FeedbackData, question: string, answer: string, searchQuery: string, searchPrompt: string, answerPrompt: string) => {
    const token = client ? await getToken(client) : undefined;

    // Include the current datetime string in ISO format or any other format you prefer
    const currentDateTime = new Date().toISOString();
    // The following values should match the structure of your Google Sheet
    const values = {
        dateTime: currentDateTime,
        sender: feedbackData.sender,
        issue: feedbackData.issue,
        rating: feedbackData.rating,
        question: question,
        answer: answer,
        searchPrompt: searchPrompt,
        searchQuery: searchQuery,
        answerPrompt: answerPrompt
    };
    // console.log(values);
    const response = await appendFeedbackToSheet(values, token);
    // console.log(response);
    // .then(() => console.log('Feedback appended successfully'))
    // .catch((error) => console.error('Error appending feedback:', error));
};

interface FeedbackData {
    sender: string;
    issue: string;
    rating: "Good" | "Neutral" | "Bad";
}

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions = true
}: Props) => {
    // Feedback
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [feedbackData, setFeedbackData] = useState<FeedbackData>({ sender: "", issue: "", rating: "Good" });

    // Handle Feedback
    const handleFeedbackChange = (key: keyof FeedbackData, value: string) => {
        setFeedbackData({ ...feedbackData, [key]: value });
    };

    const followupQuestions = answer.context?.followup_questions;
    const messageContent = answer.message.content;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const handleCitationClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement;
        if (target.tagName === 'SUP' && target.parentNode instanceof HTMLAnchorElement) {
            console.log("handleCitationClick, parsedAnswer.citations", parsedAnswer.citations)
            event.preventDefault();
            const citationPart = target.parentNode.getAttribute('data-citation-path');
            if (citationPart) {
                const citationPath = getCitationFilePath(citationPart);
                onCitationClicked(citationPath);
            }
        }
    };


    const submitFeedback = () => {
        // Process feedback
        if (answer.context.thoughts.length > 0) {
            appendFeedback(
                feedbackData,                                               // User input
                JSON.stringify(answer.context.thoughts[0].props?.question), // Question
                answer.message.content,                                     // Answer
                JSON.stringify(answer.context.thoughts[1]),                 // Search Query
                JSON.stringify(answer.context.thoughts[0].description),     // Search Prompt
                JSON.stringify(answer.context.thoughts[3].description)      // Answer Prompt
            );    
        } else {
            console.log('No thoughts available.');
        }
        setIsFeedbackModalOpen(false);
    };

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.context.thoughts?.length}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.context.data_points}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Feedback" }}
                            title="Give Feedback"
                            ariaLabel="Give Feedback"
                            onClick={() => setIsFeedbackModalOpen(true)}
                        />
                        <Modal isOpen={isFeedbackModalOpen} onDismiss={() => setIsFeedbackModalOpen(false)} isBlocking={false}>
                            <div className={classNames.modalContent}>
                                <TextField
                                    className={classNames.textFields}
                                    label="Sender"
                                    value={feedbackData.sender}
                                    onChange={(e, val) => handleFeedbackChange("sender", val || "")}
                                />
                                <TextField
                                    className={classNames.textFields}
                                    label="Issue"
                                    multiline
                                    rows={3}
                                    value={feedbackData.issue}
                                    onChange={(e, val) => handleFeedbackChange("issue", val || "")}
                                    placeholder="Define the issue and include what is the right output/source/solution."
                                />
                                <div>
                                    {["Good", "Neutral", "Bad"].map(rating => (
                                        <PrimaryButton
                                            key={rating}
                                            text={rating}
                                            className={
                                                feedbackData.rating === rating
                                                    ? `${classNames.feedbackButtons} ${classNames.selectedButton}`
                                                    : classNames.feedbackButtons
                                            }
                                            onClick={() => handleFeedbackChange("rating", rating)}
                                        />
                                    ))}
                                </div>
                                <PrimaryButton className={classNames.submitButton} text="Submit" onClick={submitFeedback} />
                            </div>
                        </Modal>
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                {/* <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div> */}
                <div className="answerText" dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }} onClick={handleCitationClick}></div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};
